import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { deletePlantApi, getPlantsApi as getPlantsApi } from "../../services/apis/plant";
import Toast, { showSuccessToast } from "../../components/toastify/Toast";
import showAlert from "../../components/errorBoundries/Errors";
import { MdDelete } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { AiFillHome } from "react-icons/ai";
import { IoIosArrowForward, IoMdAdd, IoMdCloudDownload, IoMdEye } from "react-icons/io";
import SearchBar from "../../components/searchBar/SearchBar";
import Tab from "../marketing/faqs/Tabs";
import Table from "../../components/tables/Table";
import Pagination from "../../components/pagination/Pagination";
import ConfirmModal from "../../components/popups/confirmationModal/ConfirmModal";
import { generatePlantExcelApiUrl, generatePlantPdfApiUrl } from "../../constant/urls/apiUrls";


const Plant = () => {
  const navigate = useNavigate();
  const headers = ["Name", "Area", "City", "State", "District", "Online", "Offline", "Pincode", "No. Of Devices"];
  const keys = ["plant_name", "area", "city", "state", "district", "online_devices", "offline_devices", "pincode", "device_count"];
  const [modalIsOpen, setIsOpen] = useState(false);
  const [membersData, setMembersData] = useState({});
  const [faqsId, setFaqsId] = useState("");
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const fetchData = async (query) => {
    try {
      const respones = await getPlantsApi("?search=" + query);
      if (respones?.status === 200) {
        setMembersData(respones?.data);
      } else {
        let errors = "Opps! Something went wrong, please try again.";
        showAlert("Error", errors, "error");
      }
    } catch (error) {
      let errors =
        error?.response?.data?.detail ||
        "Opps! Something went wrong, please try again.";
      showAlert("Error", errors, "error");
    }
  };

  useEffect(() => {
    fetchData("");
  }, []);

  const openModal = (row) => {
    setIsOpen(true);
    setFaqsId(row?.id);
    setIsConfirmDelete(false);
  };

  const closeModal = () => {
    setIsOpen(false);
    setIsConfirmDelete(false);
  };

  useEffect(() => {
    if (isConfirmDelete === true) {
      const fetchData = async () => {
        try {
          const respones = await deletePlantApi(faqsId);
          if (respones?.status === 204) {
            try {
              const respones = await getPlantsApi();
              if (respones?.status === 200) {
                setMembersData(respones?.data);
              } else {
                let errors = "Opps! Something went wrong, please try again.";
                showAlert("Error", errors, "error");
              }
            } catch (error) {
              let errors =
                error?.response?.data?.detail ||
                "Opps! Something went wrong, please try again.";
              showAlert("Error", errors, "error");
            }
            setIsOpen(false);
            showSuccessToast("Plant deleted successfully!");
          } else {
            let errors = "Opps! Something went wrong, please try again.";
            showAlert("Error", errors, "error");
          }
        } catch (error) {
          let errors =
            error?.response?.data?.detail ||
            "Opps! Something went wrong, please try again.";
          showAlert("Error", errors, "error");
        }
      };
      fetchData();
    }
  }, [isConfirmDelete]);
  const handleEdit = (rowData) => {
    navigate(`/plant/edit`, { state: { rowData } });
  };

  const handleViewDetails = (rowData) => {
    navigate(`/plant/view`, { state: { rowData } });
  };

  const extraColumnRenderer = (row) => (
    <div className="flex space-x-2">
      <FiEdit
        size={23}
        onClick={() => handleEdit(row)}
        className="cursor-pointer text-primary"
      />
      <MdDelete
        size={25}
        onClick={() => openModal(row)}
        className="cursor-pointer text-red-600"
      />
      <IoMdEye
        size={25}
        onClick={() => handleViewDetails(row)}
        className="cursor-pointer text-[#65558F]"
      />
    </div>
  );
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleSearch = (query) => {
    fetchData(query);
  };


  const handleDownloadExcel = () => {
    window.open(generatePlantExcelApiUrl, "_blank");
  };

  const handleDownloadPdf = () => {
    window.open(generatePlantPdfApiUrl, "_blank");
  };

  return (
    <div className="">
      <Toast />
      {/* Top Section Begin */}
      <div className="flex gap-3 items-center font-medium text-gray-500">
        <AiFillHome />
        <p>Home</p>
        <IoIosArrowForward />
        <p>Plants</p>
      </div>
      {/* Top Section End */}
      {/* Add Faqs Begin */}
      <div className="flex justify-between pt-3">
        <div className="w-[30%]">
          <SearchBar placeholder="Search in Plants..." onSearch={handleSearch} />
        </div>
        <div className="flex justify-end">
          <div className="mr-4">
            <Link to="/plant/add">
              <button
                type="button"
                className="text-white bg-primary hover:bg-primary-dark font-sm rounded-lg text-md w-full px-3 py-2.5 text-center flex items-center gap-1"
              >
                <IoMdAdd style={{ color: "white" }} size={23} />
                Add Plant
              </button>
            </Link>
          </div>
          <div className="mr-4">
            <button
              type="button"
              className="text-black border border-gray-300 hover:bg-primary-dark font-sm rounded-lg text-md w-full px-3 py-2.5 text-center flex items-center gap-1 font-semibold"
              onClick={handleDownloadExcel}
            >
              <IoMdCloudDownload style={{ TextColor: "black"}} size={23} />
              Download Excel
            </button>
          </div>
          <div>
            <button
              type="button"
              className="text-black border border-gray-300 hover:bg-primary-dark font-sm rounded-lg text-md w-full px-3 py-2.5 text-center flex items-center gap-1 font-semibold"
              onClick={handleDownloadPdf}
            >
              <IoMdCloudDownload style={{ TextColor: "black" }} size={23} />
              Download PDF
            </button>
          </div>
        </div>
      </div>
      {/* Add Faqs End */}
      {/* Tabs Section Begin */}
      <div className="lg:pl-2">
        <Tab />
      </div>
      {/* Tabs Section End */}
      {/* Table Begin */}
      <div className="mt-2">
        <Table
          headers={headers}
          keys={keys}
          rows={membersData?.results}
          extraColumn="Actions"
          extraColumnRenderer={extraColumnRenderer}
        />
      </div>
      {/* Table End */}
      {/* Pagination Begin */}
      <div className="xl:pl-5 pl-0">
        <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil(membersData?.count / pageSize)}
          pageSize={pageSize}
          totalEntries={membersData?.count}
          onPageChange={handlePageChange}
          data={membersData}
          setData={setMembersData}
        />
      </div>
      {/* Pagination End */}
      <ConfirmModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Add Device Type Modal"
        onClose={closeModal}
        setIsConfirmDelete={setIsConfirmDelete}
      />
    </div>
  );
};

export default Plant;
