import { Formik, Form } from "formik";
import { AiFillHome } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import InputField from "../../components/inputFields/InputField";
import StyledHeadingText from "../../components/Fonts/StyledHeadingText";
import DeviceLogCard from "../../components/DeviceLogCard";
import { Button } from "rsuite";
import ExpandableImageComponent from "../../components/Device/ExpandableImageComponent";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import showAlert from "../../components/errorBoundries/Errors";
import { deviceLogReportSaveApi, getDeviceLogsDetailedApi } from "../../services/apis/device_logs";
import Toast, { showSuccessToast } from "../../components/toastify/Toast";

function DeviceLogReport() {
    const location = useLocation();
    const [plant, setPlant] = useState("");
    const [user, setUser] = useState("");
    const [serialNo, setSerialNo] = useState("");
    const [macId, setMacId] = useState("");
    const [address, setAddress] = useState("");
    const [deviceImage, setDeviceImage] = useState("");
    const [commodityImage, setCommodityImage] = useState("");
    const [logId, setLogId] = useState("");
    const [temp, setTemp] = useState("");
    const [hum, setHum] = useState("");
    const [temp_plate_1, setTemp_plate_1] = useState("");
    const [temp_plate_2, setTemp_plate_2] = useState("");
    const [temp_plate_3, setTemp_plate_3] = useState("");
    const [solar_supply, setSolar_supply] = useState("");
    const [r_phase, setR_phase] = useState("");
    const [y_phase, setY_phase] = useState("");
    const [b_phase, setB_phase] = useState("");
    const [battery, setBattery] = useState("");
    const [low_pressure, setLow_pressure] = useState("");
    const [high_pressure, setHigh_pressure] = useState("");
    const [valve_1, setValve_1] = useState("");
    const [valve_2, setValve_2] = useState("");
    const [valve_3, setValve_3] = useState("");
    const [water_level, setWater_level] = useState("");
    const [door_open, setDoor_open] = useState("");
    const [fan_1, setFan_1] = useState("");
    const [fan_2, setFan_2] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            const path = location.pathname.split("/");
            const parms = location.search.replace("?", "").split("&");
            const logId = path[path.length - 1];
            const from = parms[0].replace("from=", "").replace("T", " ");
            const to = parms[1].replace("to=", "").replace("T", " ");
            setFromDate(from);
            setToDate(to);
            setLogId(logId);

            if (logId) {
                try {
                    const respones = await getDeviceLogsDetailedApi({ deviceId: logId, endDate: to, startDate: from, });
                    if (respones?.status === 200) {
                        const data = respones?.data;
                        setPlant(data.plant);
                        setUser(data.user);
                        setSerialNo(data.serial_no);
                        setMacId(data.mac_id);
                        setAddress(data.address);
                        setCommodityImage(data.commodity_image || "");
                        setDeviceImage(data.device_image || "");
                        setTemp(data.temperature);
                        setHum(data.humidity);
                        setTemp_plate_1(data.plate_temperature_1);
                        setTemp_plate_2(data.plate_temperature_2);
                        setTemp_plate_3(data.plate_temperature_3);
                        setSolar_supply(data.solar_supply);
                        setR_phase(data.r_phase);
                        setY_phase(data.y_phase);
                        setB_phase(data.b_phase);
                        setBattery(data.battery_level);
                        setLow_pressure(data.low_pressure);
                        setHigh_pressure(data.high_pressure);
                        setValve_1(data.valve_1);
                        setValve_2(data.valve_2);
                        setValve_3(data.valve_3);
                        setWater_level(data.water_level);
                        setDoor_open(data.door_open);
                        setFan_1(data.fan_1);
                        setFan_2(data.fan_2);
                    } else {
                        let errors = "Opps! Something went wrong, please try again.";
                        showAlert("Error", errors, "error");
                    }
                } catch (error) {
                    let errors =
                        error?.response?.data?.detail ||
                        "Opps! Something went wrong, please try again.";
                    showAlert("Error", errors, "error");
                }
            }
        };
        fetchData();
    }, [location]);

    const handleDownload = (rowData) => {
        console.log("Download button clicked");
    };

    const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            const bodyData = {
                "id": logId,
                "humidity": values.humidity,
                "temperature": values.temperature,
                "plate_temperature_1": values.plate_temperature_1,
                "plate_temperature_2": values.plate_temperature_2,
                "plate_temperature_3": values.plate_temperature_3,
                "solar_supply": values.solar_supply,
                "r_phase": values.r_phase,
                "y_phase": values.y_phase,
                "b_phase": values.b_phase,
                "battery_level": values.battery_level,
                "low_pressure": values.low_pressure,
                "high_pressure": values.high_pressure,
                "valve_1": values.valve_1,
                "valve_2": values.valve_2,
                "valve_3": values.valve_3,
                "water_level": values.water_level,
                "door_open": values.door_open,
                "fan_1": values.fan_1,
                "fan_2": values.fan_2,
                "from_date": fromDate,
                "to_date": toDate,
            };

            const response = await deviceLogReportSaveApi(bodyData);

            if (response?.status === 200) {
                showSuccessToast("Report Saved successfully!");
            } else {
                let errors = "Opps! Something went wrong, please try again.";
                showAlert("Error", errors, "error");
            }
        } catch (error) {
            let errors =
                error?.response?.data?.error ||
                "Opps! Something went wrong, please try again.";
            showAlert("Error", errors, "error");
        }
        setSubmitting(false);
    };

    return (
        <div className="relative">
            <Toast />
            {/* Top Section Begin */}
            <div className="flex gap-3 items-center font-medium text-gray-500">
                <AiFillHome />
                <p>Home</p>
                <IoIosArrowForward />
                <p>Device Logs</p>
                <IoIosArrowForward />
                <p>Report</p>
            </div>
            {/* Top Section End */}
            {/* Form Begin */}
            <div className="my-4">
                <Formik
                    enableReinitialize
                    initialValues={{
                        plant: plant,
                        user: user,
                        serial: serialNo,
                        mac: macId,
                        address: address,
                    }}
                >
                    {({ isSubmitting, resetForm }) => (
                        <Form>
                            <div className="items-start w-full border-gray-300 p-4 rounded-lg bg-gray-100 border">
                                <div className="flex flex-row">
                                    <div className="w-full mb-1">
                                        <StyledHeadingText text="Device Details" size="text-sm" />
                                        <div className="grid gap-6 md:grid-cols-3">
                                            <InputField
                                                label="Plant"
                                                name="plant"
                                                type="text"
                                                placeholder="Enter Plant Name"
                                                required
                                                disable={true}
                                            />
                                            <InputField
                                                label="User"
                                                name="user"
                                                type="text"
                                                placeholder="Enter User Name"
                                                required
                                                disable={true}
                                            />
                                            <InputField
                                                label="Serial No."
                                                name="serial"
                                                type="text"
                                                placeholder="Serial No."
                                                required
                                                disable={true}
                                            />
                                            <InputField
                                                label="MAC ID"
                                                name="mac"
                                                type="text"
                                                placeholder="MAC"
                                                required
                                                disable={true}
                                            />
                                        </div>
                                        <InputField
                                            label="Address"
                                            name="address"
                                            type="TextField"
                                            placeholder="Address"
                                            required
                                            disable={true}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-row">
                                    {deviceImage !== "" ?
                                        <div className="mr-2">
                                            <label htmlFor="device-image" className="font-medium">
                                                Device Image
                                            </label>
                                            <ExpandableImageComponent imageUrl={deviceImage} height={"h-40"} width={"max-w-52"} />
                                        </div> : <></>}
                                    {commodityImage !== "" ?
                                        <div className="ml-2">
                                            <label htmlFor="commodity-image" className="font-medium">
                                                Commodity Image
                                            </label>
                                            <ExpandableImageComponent imageUrl={commodityImage} height={"h-40"} width={"max-w-52"} />
                                        </div> : <></>}
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            {/* Form End */}
            <StyledHeadingText text="Average Results" size="text-sm" />
            <Formik
                enableReinitialize
                initialValues={{
                    temp: temp,
                    hum: hum,
                    temp_plate_1: temp_plate_1,
                    temp_plate_2: temp_plate_2,
                    temp_plate_3: temp_plate_3,
                    solar_supply: solar_supply,
                    r_phase: r_phase,
                    y_phase: y_phase,
                    b_phase: b_phase,
                    battery: battery,
                    low_pressure: low_pressure,
                    high_pressure: high_pressure,
                    valve_1: valve_1,
                    valve_2: valve_2,
                    valve_3: valve_3,
                    water_level: water_level,
                    door_open: door_open,
                    fan_1: fan_1,
                    fan_2: fan_2,
                }}
                onSubmit={handleFormSubmit}
            >
                {({ isSubmitting, resetForm }) => (
                    <Form>
                        <>

                            <div className="grid gap-6 md:grid-cols-4 mt-4">
                                <DeviceLogCard
                                    icon={<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="32" height="32" rx="4" fill="#D77E6A" />
                                        <path d="M15 13C15 12.4477 15.4477 12 16 12C16.5523 12 17 12.4477 17 13V20.2676C17.5978 20.6134 18 21.2597 18 22C18 23.1046 17.1046 24 16 24C14.8954 24 14 23.1046 14 22C14 21.2597 14.4022 20.6134 15 20.2676V13Z" fill="white" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 19V9C12 8.78832 12.0257 8.57525 12.0605 8.36685C12.1183 8.02011 12.2357 7.5425 12.4806 7.05279C12.7274 6.55916 13.1133 6.03511 13.7109 5.6367C14.3148 5.23412 15.075 5 16 5C16.925 5 17.6852 5.23412 18.2891 5.6367C18.8867 6.03511 19.2726 6.55916 19.5194 7.05279C19.7643 7.5425 19.8817 8.02011 19.9395 8.36685C19.9742 8.57498 19.9996 8.78759 20 8.99899V19C20.2821 19.3762 20.5116 19.7926 20.6793 20.2383C21.0608 21.2514 21.1031 22.3609 20.8 23.4001C20.4969 24.4393 19.8647 25.3521 18.9984 26.0013C18.1321 26.6504 17.0786 27.0009 15.996 27C14.9135 26.9992 13.8605 26.647 12.9953 25.9965C12.13 25.346 11.4993 24.4322 11.1978 23.3925C10.8963 22.3528 10.9404 21.2434 11.3234 20.2309C11.491 19.788 11.7195 19.374 12 19ZM14 19V9.00611C14.0099 8.64417 14.1083 8.26946 14.2694 7.94721C14.3976 7.69084 14.5742 7.46489 14.8203 7.3008C15.0602 7.14088 15.425 7 16 7C16.575 7 16.9398 7.14088 17.1797 7.3008C17.4258 7.46489 17.6024 7.69084 17.7306 7.94721C17.8911 8.26822 17.9878 8.63969 17.9998 8.99962L18 19C18 19.4069 18.0977 19.7744 18.3582 20.0973C18.5596 20.3469 18.7213 20.6284 18.8358 20.9324C19.0669 21.5463 19.0926 22.2187 18.9089 22.8485C18.7252 23.4783 18.3421 24.0315 17.8171 24.4249C17.2921 24.8183 16.6536 25.0307 15.9976 25.0301C15.3416 25.0296 14.7034 24.8162 14.1791 24.422C13.6547 24.0277 13.2725 23.474 13.0898 22.8439C12.9071 22.2138 12.9338 21.5415 13.1659 20.9279C13.2808 20.6241 13.4431 20.3428 13.6448 20.0936C13.9081 19.7683 14 19.4093 14 19Z" fill="white" />
                                    </svg>}
                                    title={"Temperature"}
                                    name="temp"
                                />
                                <DeviceLogCard
                                    icon={<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="32" height="32" rx="4" fill="#1C64F2" />
                                        <path d="M21.0066 7.25608C22.8483 6.85737 25.1331 6.8773 28.2423 7.65268C28.7781 7.78629 29.1038 8.32791 28.9699 8.86241C28.836 9.39691 28.2931 9.7219 27.7573 9.58829C24.8666 8.86742 22.9015 8.88747 21.4308 9.20587C19.9555 9.52524 18.895 10.1587 17.7715 10.8436L17.6874 10.8949C16.6044 11.5557 15.4051 12.2873 13.8207 12.5507C12.1773 12.8239 10.236 12.5824 7.62883 11.5419C7.11607 11.3372 6.86667 10.7567 7.0718 10.2451C7.27692 9.73359 7.85889 9.48479 8.37165 9.68943C10.7643 10.6443 12.3229 10.777 13.492 10.5827C14.6789 10.3854 15.5837 9.83916 16.7286 9.14119C17.855 8.45445 19.1694 7.6538 21.0066 7.25608Z" fill="white" />
                                        <path d="M28.2423 11.6429C25.1331 10.8675 22.8483 10.8476 21.0066 11.2463C19.1694 11.644 17.855 12.4447 16.7286 13.1314C15.5837 13.8294 14.6789 14.3756 13.492 14.5729C12.3229 14.7672 10.7643 14.6345 8.37165 13.6797C7.85889 13.475 7.27692 13.7238 7.0718 14.2354C6.86667 14.7469 7.11607 15.3275 7.62883 15.5321C10.236 16.5726 12.1773 16.8141 13.8207 16.5409C15.4051 16.2775 16.6044 15.5459 17.6874 14.8852L17.7715 14.8339C18.895 14.1489 19.9555 13.5155 21.4308 13.1961C22.9015 12.8777 24.8666 12.8577 27.7573 13.5785C28.2931 13.7121 28.836 13.3871 28.9699 12.8526C29.1038 12.3181 28.7781 11.7765 28.2423 11.6429Z" fill="white" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M24.9999 14.0264C24.6527 14.0264 24.3634 14.2057 24.1615 14.477C24.0906 14.5728 23.9267 14.797 23.709 15.1108C23.4194 15.5281 23.0318 16.108 22.6425 16.7553C22.2551 17.3994 21.8554 18.1278 21.5496 18.8395C21.2568 19.5211 20.999 20.2612 21 21.0115C21.0007 21.2221 21.0259 21.4337 21.0605 21.641C21.1183 21.987 21.2357 22.4634 21.4805 22.9519C21.7273 23.4444 22.1132 23.9672 22.7108 24.3646C23.3147 24.7662 24.0749 24.9998 24.9999 24.9998C25.9249 24.9998 26.6851 24.7662 27.2889 24.3646C27.8865 23.9672 28.2725 23.4444 28.5193 22.952C28.7641 22.4634 28.8816 21.987 28.9394 21.6411C28.9741 21.4335 28.9996 21.2213 28.9999 21.0105C29.0002 20.2602 28.7431 19.5212 28.4502 18.8395C28.1445 18.1278 27.7448 17.3994 27.3574 16.7553C26.9681 16.108 26.5804 15.5281 26.2908 15.1108C26.0731 14.797 25.9091 14.5728 25.8383 14.477C25.6364 14.2057 25.347 14.0264 24.9999 14.0264ZM26.612 19.6255C26.3553 19.0279 26.005 18.3846 25.6424 17.7818C25.4219 17.4152 25.2008 17.07 24.9999 16.7672C24.799 17.07 24.5779 17.4152 24.3574 17.7818C23.9949 18.3846 23.6446 19.0279 23.3879 19.6255L23.3733 19.6593C23.1966 20.0702 22.9878 20.556 23.0002 21.0099C23.0122 21.3689 23.1089 21.7395 23.2694 22.0597C23.3975 22.3155 23.5741 22.5409 23.8202 22.7046C24.0601 22.8641 24.4249 23.0046 24.9999 23.0046C25.5749 23.0046 25.9397 22.8641 26.1796 22.7046C26.4257 22.5409 26.6023 22.3154 26.7305 22.0597C26.891 21.7395 26.9877 21.3689 26.9997 21.0098C27.0122 20.5561 26.8033 20.0703 26.6266 19.6595L26.612 19.6255Z" fill="white" />
                                        <path d="M20.1296 15.5312C20.8899 15.2851 21.4728 16.0764 21.1153 16.7896C20.952 17.1155 20.7683 17.3928 20.4031 17.5218C19.426 17.867 18.6166 18.3531 17.7715 18.8683L17.6874 18.9196C16.6044 19.5803 15.4051 20.3119 13.8207 20.5753C12.1773 20.8485 10.236 20.607 7.62883 19.5665C7.11607 19.3619 6.86668 18.7813 7.0718 18.2698C7.27693 17.7582 7.85889 17.5094 8.37166 17.7141C10.7643 18.6689 12.323 18.8016 13.492 18.6073C14.6789 18.41 15.5837 17.8638 16.7286 17.1658C17.8166 16.5025 18.9363 15.9175 20.1296 15.5312Z" fill="white" />
                                    </svg>}
                                    title={"Humidity"}
                                    name="hum"
                                />
                                <DeviceLogCard
                                    icon={<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="32" height="32" rx="4" fill="#04C600" />
                                        <path d="M15 13C15 12.4477 15.4477 12 16 12C16.5523 12 17 12.4477 17 13V20.2676C17.5978 20.6134 18 21.2597 18 22C18 23.1046 17.1046 24 16 24C14.8954 24 14 23.1046 14 22C14 21.2597 14.4022 20.6134 15 20.2676V13Z" fill="white" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 19V9C12 8.78832 12.0257 8.57525 12.0605 8.36685C12.1183 8.02011 12.2357 7.5425 12.4806 7.05279C12.7274 6.55916 13.1133 6.03511 13.7109 5.6367C14.3148 5.23412 15.075 5 16 5C16.925 5 17.6852 5.23412 18.2891 5.6367C18.8867 6.03511 19.2726 6.55916 19.5194 7.05279C19.7643 7.5425 19.8817 8.02011 19.9395 8.36685C19.9742 8.57498 19.9996 8.78759 20 8.99899V19C20.2821 19.3762 20.5116 19.7926 20.6793 20.2383C21.0608 21.2514 21.1031 22.3609 20.8 23.4001C20.4969 24.4393 19.8647 25.3521 18.9984 26.0013C18.1321 26.6504 17.0786 27.0009 15.996 27C14.9135 26.9992 13.8605 26.647 12.9953 25.9965C12.13 25.346 11.4993 24.4322 11.1978 23.3925C10.8963 22.3528 10.9404 21.2434 11.3234 20.2309C11.491 19.788 11.7195 19.374 12 19ZM14 19V9.00611C14.0099 8.64417 14.1083 8.26946 14.2694 7.94721C14.3976 7.69084 14.5742 7.46489 14.8203 7.3008C15.0602 7.14088 15.425 7 16 7C16.575 7 16.9398 7.14088 17.1797 7.3008C17.4258 7.46489 17.6024 7.69084 17.7306 7.94721C17.8911 8.26822 17.9878 8.63969 17.9998 8.99962L18 19C18 19.4069 18.0977 19.7744 18.3582 20.0973C18.5596 20.3469 18.7213 20.6284 18.8358 20.9324C19.0669 21.5463 19.0926 22.2187 18.9089 22.8485C18.7252 23.4783 18.3421 24.0315 17.8171 24.4249C17.2921 24.8183 16.6536 25.0307 15.9976 25.0301C15.3416 25.0296 14.7034 24.8162 14.1791 24.422C13.6547 24.0277 13.2725 23.474 13.0898 22.8439C12.9071 22.2138 12.9338 21.5415 13.1659 20.9279C13.2808 20.6241 13.4431 20.3428 13.6448 20.0936C13.9081 19.7683 14 19.4093 14 19Z" fill="white" />
                                    </svg>}
                                    title={"Plate 1 (Temp)"}
                                    name="temp_plate_1"
                                />
                                <DeviceLogCard
                                    icon={<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="32" height="32" rx="4" fill="#04C600" />
                                        <path d="M15 13C15 12.4477 15.4477 12 16 12C16.5523 12 17 12.4477 17 13V20.2676C17.5978 20.6134 18 21.2597 18 22C18 23.1046 17.1046 24 16 24C14.8954 24 14 23.1046 14 22C14 21.2597 14.4022 20.6134 15 20.2676V13Z" fill="white" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 19V9C12 8.78832 12.0257 8.57525 12.0605 8.36685C12.1183 8.02011 12.2357 7.5425 12.4806 7.05279C12.7274 6.55916 13.1133 6.03511 13.7109 5.6367C14.3148 5.23412 15.075 5 16 5C16.925 5 17.6852 5.23412 18.2891 5.6367C18.8867 6.03511 19.2726 6.55916 19.5194 7.05279C19.7643 7.5425 19.8817 8.02011 19.9395 8.36685C19.9742 8.57498 19.9996 8.78759 20 8.99899V19C20.2821 19.3762 20.5116 19.7926 20.6793 20.2383C21.0608 21.2514 21.1031 22.3609 20.8 23.4001C20.4969 24.4393 19.8647 25.3521 18.9984 26.0013C18.1321 26.6504 17.0786 27.0009 15.996 27C14.9135 26.9992 13.8605 26.647 12.9953 25.9965C12.13 25.346 11.4993 24.4322 11.1978 23.3925C10.8963 22.3528 10.9404 21.2434 11.3234 20.2309C11.491 19.788 11.7195 19.374 12 19ZM14 19V9.00611C14.0099 8.64417 14.1083 8.26946 14.2694 7.94721C14.3976 7.69084 14.5742 7.46489 14.8203 7.3008C15.0602 7.14088 15.425 7 16 7C16.575 7 16.9398 7.14088 17.1797 7.3008C17.4258 7.46489 17.6024 7.69084 17.7306 7.94721C17.8911 8.26822 17.9878 8.63969 17.9998 8.99962L18 19C18 19.4069 18.0977 19.7744 18.3582 20.0973C18.5596 20.3469 18.7213 20.6284 18.8358 20.9324C19.0669 21.5463 19.0926 22.2187 18.9089 22.8485C18.7252 23.4783 18.3421 24.0315 17.8171 24.4249C17.2921 24.8183 16.6536 25.0307 15.9976 25.0301C15.3416 25.0296 14.7034 24.8162 14.1791 24.422C13.6547 24.0277 13.2725 23.474 13.0898 22.8439C12.9071 22.2138 12.9338 21.5415 13.1659 20.9279C13.2808 20.6241 13.4431 20.3428 13.6448 20.0936C13.9081 19.7683 14 19.4093 14 19Z" fill="white" />
                                    </svg>}
                                    title={"Plate 2 (Temp)"}
                                    name="temp_plate_2"
                                />
                                <DeviceLogCard
                                    icon={<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="32" height="32" rx="4" fill="#AEAA43" />
                                        <path d="M15 13C15 12.4477 15.4477 12 16 12C16.5523 12 17 12.4477 17 13V20.2676C17.5978 20.6134 18 21.2597 18 22C18 23.1046 17.1046 24 16 24C14.8954 24 14 23.1046 14 22C14 21.2597 14.4022 20.6134 15 20.2676V13Z" fill="white" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 19V9C12 8.78832 12.0257 8.57525 12.0605 8.36685C12.1183 8.02011 12.2357 7.5425 12.4806 7.05279C12.7274 6.55916 13.1133 6.03511 13.7109 5.6367C14.3148 5.23412 15.075 5 16 5C16.925 5 17.6852 5.23412 18.2891 5.6367C18.8867 6.03511 19.2726 6.55916 19.5194 7.05279C19.7643 7.5425 19.8817 8.02011 19.9395 8.36685C19.9742 8.57498 19.9996 8.78759 20 8.99899V19C20.2821 19.3762 20.5116 19.7926 20.6793 20.2383C21.0608 21.2514 21.1031 22.3609 20.8 23.4001C20.4969 24.4393 19.8647 25.3521 18.9984 26.0013C18.1321 26.6504 17.0786 27.0009 15.996 27C14.9135 26.9992 13.8605 26.647 12.9953 25.9965C12.13 25.346 11.4993 24.4322 11.1978 23.3925C10.8963 22.3528 10.9404 21.2434 11.3234 20.2309C11.491 19.788 11.7195 19.374 12 19ZM14 19V9.00611C14.0099 8.64417 14.1083 8.26946 14.2694 7.94721C14.3976 7.69084 14.5742 7.46489 14.8203 7.3008C15.0602 7.14088 15.425 7 16 7C16.575 7 16.9398 7.14088 17.1797 7.3008C17.4258 7.46489 17.6024 7.69084 17.7306 7.94721C17.8911 8.26822 17.9878 8.63969 17.9998 8.99962L18 19C18 19.4069 18.0977 19.7744 18.3582 20.0973C18.5596 20.3469 18.7213 20.6284 18.8358 20.9324C19.0669 21.5463 19.0926 22.2187 18.9089 22.8485C18.7252 23.4783 18.3421 24.0315 17.8171 24.4249C17.2921 24.8183 16.6536 25.0307 15.9976 25.0301C15.3416 25.0296 14.7034 24.8162 14.1791 24.422C13.6547 24.0277 13.2725 23.474 13.0898 22.8439C12.9071 22.2138 12.9338 21.5415 13.1659 20.9279C13.2808 20.6241 13.4431 20.3428 13.6448 20.0936C13.9081 19.7683 14 19.4093 14 19Z" fill="white" />
                                    </svg>}
                                    title={"Plate 3 (Temp)"}
                                    name="temp_plate_3"
                                />
                                <DeviceLogCard
                                    icon={<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="32" height="32" rx="4" fill="#E67C00" />
                                        <g clip-path="url(#clip0_695_12161)">
                                            <path d="M16 22.1943C19.4208 22.1943 22.1938 19.4213 22.1938 16.0005C22.1938 12.5797 19.4208 9.80664 16 9.80664C12.5792 9.80664 9.80615 12.5797 9.80615 16.0005C9.80615 19.4213 12.5792 22.1943 16 22.1943Z" stroke="white" stroke-width="1.29231" stroke-miterlimit="10" />
                                            <path d="M16 5.38477V8.04323" stroke="white" stroke-width="1.29231" stroke-miterlimit="10" stroke-linecap="round" />
                                            <path d="M16 23.9658V26.6151" stroke="white" stroke-width="1.29231" stroke-miterlimit="10" stroke-linecap="round" />
                                            <path d="M26.6153 16H23.9661" stroke="white" stroke-width="1.29231" stroke-miterlimit="10" stroke-linecap="round" />
                                            <path d="M8.04298 16H5.38452" stroke="white" stroke-width="1.29231" stroke-miterlimit="10" stroke-linecap="round" />
                                            <path d="M23.5047 8.49512L21.6309 10.369" stroke="white" stroke-width="1.29231" stroke-miterlimit="10" stroke-linecap="round" />
                                            <path d="M10.3695 21.6309L8.49561 23.5047" stroke="white" stroke-width="1.29231" stroke-miterlimit="10" stroke-linecap="round" />
                                            <path d="M23.5047 23.5047L21.6309 21.6309" stroke="white" stroke-width="1.29231" stroke-miterlimit="10" stroke-linecap="round" />
                                            <path d="M10.3695 10.369L8.49561 8.49512" stroke="white" stroke-width="1.29231" stroke-miterlimit="10" stroke-linecap="round" />
                                            <path d="M16.0001 15.1143L15.3354 16.0004H16.0001V15.1143Z" stroke="white" stroke-width="1.84615" stroke-miterlimit="10" />
                                            <path d="M16 16.8862L16.6646 16H16V16.8862Z" stroke="white" stroke-width="1.84615" stroke-miterlimit="10" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_695_12161">
                                                <rect width="24" height="24" fill="white" transform="translate(4 4)" />
                                            </clipPath>
                                        </defs>
                                    </svg>}
                                    title={"Solar Supply"}
                                    name="solar_supply"
                                />
                                <DeviceLogCard
                                    icon={<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="32" height="32" rx="4" fill="#E20000" />
                                        <g clip-path="url(#clip0_695_12197)">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.373 14.6177H13.6748V11.8061C13.6748 11.4072 13.9782 11.0815 14.3499 11.0815C14.7216 11.0815 15.0251 11.4072 15.0251 11.8061V14.6177H17.0092V11.8061C17.0092 11.4072 17.3126 11.0815 17.6843 11.0815C18.056 11.0815 18.3594 11.4072 18.3594 11.8061V14.6177H19.6271C19.8623 14.6177 20.0545 14.8239 20.0545 15.0764V15.6082C20.0545 15.8607 19.8623 16.0669 19.6271 16.0669H12.373C12.1378 16.0669 11.9456 15.8607 11.9456 15.6082V15.0764C11.9456 14.8239 12.1378 14.6177 12.373 14.6177ZM14.235 16.4774H17.8333V17.0516H14.235V16.4774ZM11.0496 25.4711C11.4177 25.7139 11.5327 26.2323 11.3064 26.6274C11.0802 27.0224 10.5972 27.1457 10.2291 26.9029C8.62561 25.845 7.2915 24.3534 6.36677 22.5805C5.47617 20.8711 4.96802 18.9016 4.96802 16.8108C4.96802 13.5405 6.20338 10.5805 8.20006 8.43947C10.1967 6.29654 12.9547 4.9707 16.0001 4.9707C19.0471 4.9707 21.8087 6.28305 23.8072 8.41249C25.8003 10.5381 27.0321 13.4769 27.0321 16.7395C27.0321 20.9733 24.8289 23.9448 22.3564 25.2803C21.4424 25.7756 20.4836 26.0473 19.5768 26.0839C18.6288 26.1205 17.731 25.897 16.9876 25.394C15.6858 24.5133 14.8832 22.8368 15.1292 20.2834C13.4467 19.9962 12.7088 19.0443 12.7088 17.1827V16.0727H19.3632V17.0728C19.3632 18.9575 18.539 20.1119 16.7111 20.3007H16.7021C16.4849 22.2143 16.9804 23.4072 17.819 23.9738C18.2894 24.2917 18.8802 24.4324 19.5194 24.4073C20.2017 24.3804 20.9361 24.1684 21.6507 23.783C23.6672 22.6942 25.4627 20.2487 25.4627 16.7395C25.4627 13.9394 24.4087 11.4207 22.7029 9.6015C20.9918 7.77655 18.6216 6.65305 16.0001 6.65305C13.3857 6.65305 11.0209 7.79004 9.30793 9.62848C7.59495 11.4669 6.53556 14.0049 6.53556 16.8108C6.53556 18.6126 6.97009 20.3026 7.73321 21.7653C8.52326 23.2819 9.66884 24.5596 11.0496 25.4711Z" fill="white" stroke="white" stroke-width="0.5" stroke-miterlimit="2.6131" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_695_12197">
                                                <rect width="24" height="24" fill="white" transform="translate(4 4)" />
                                            </clipPath>
                                        </defs>
                                    </svg>}
                                    title={"R Phase"}
                                    name="r_phase"
                                />
                                <DeviceLogCard
                                    icon={<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="32" height="32" rx="4" fill="#E7D000" />
                                        <g clip-path="url(#clip0_695_12224)">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.3729 14.6177H13.6747V11.8061C13.6747 11.4072 13.9781 11.0815 14.3498 11.0815C14.7215 11.0815 15.0249 11.4072 15.0249 11.8061V14.6177H17.009V11.8061C17.009 11.4072 17.3125 11.0815 17.6842 11.0815C18.0559 11.0815 18.3593 11.4072 18.3593 11.8061V14.6177H19.627C19.8622 14.6177 20.0543 14.8239 20.0543 15.0764V15.6082C20.0543 15.8607 19.8622 16.0669 19.627 16.0669H12.3729C12.1376 16.0669 11.9455 15.8607 11.9455 15.6082V15.0764C11.9455 14.8239 12.1376 14.6177 12.3729 14.6177ZM14.2349 16.4774H17.8332V17.0516H14.2349V16.4774ZM11.0495 25.4711C11.4176 25.7139 11.5325 26.2323 11.3063 26.6274C11.08 27.0224 10.597 27.1457 10.2289 26.9029C8.62549 25.845 7.29137 24.3534 6.36665 22.5805C5.47604 20.8711 4.9679 18.9016 4.9679 16.8108C4.9679 13.5405 6.20325 10.5805 8.19994 8.43947C10.1966 6.29654 12.9546 4.9707 15.9999 4.9707C19.047 4.9707 21.8086 6.28305 23.8071 8.41249C25.8002 10.5381 27.032 13.4769 27.032 16.7395C27.032 20.9733 24.8288 23.9448 22.3563 25.2803C21.4423 25.7756 20.4835 26.0473 19.5767 26.0839C18.6287 26.1205 17.7309 25.897 16.9875 25.394C15.6857 24.5133 14.8831 22.8368 15.1291 20.2834C13.4466 19.9962 12.7086 19.0443 12.7086 17.1827V16.0727H19.363V17.0728C19.363 18.9575 18.5389 20.1119 16.711 20.3007H16.702C16.4847 22.2143 16.9803 23.4072 17.8188 23.9738C18.2893 24.2917 18.88 24.4324 19.5193 24.4073C20.2016 24.3804 20.936 24.1684 21.6506 23.783C23.667 22.6942 25.4626 20.2487 25.4626 16.7395C25.4626 13.9394 24.4086 11.4207 22.7028 9.6015C20.9916 7.77655 18.6215 6.65305 15.9999 6.65305C13.3856 6.65305 11.0208 7.79004 9.30781 9.62848C7.59483 11.4669 6.53544 14.0049 6.53544 16.8108C6.53544 18.6126 6.96997 20.3026 7.73309 21.7653C8.52314 23.2819 9.66872 24.5596 11.0495 25.4711Z" fill="white" stroke="white" stroke-width="0.5" stroke-miterlimit="2.6131" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_695_12224">
                                                <rect width="24" height="24" fill="white" transform="translate(4 4)" />
                                            </clipPath>
                                        </defs>
                                    </svg>}
                                    title={"Y Phase"}
                                    name="y_phase"
                                />
                                <DeviceLogCard
                                    icon={<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="32" height="32" rx="4" fill="#0094E7" />
                                        <g clip-path="url(#clip0_695_12571)">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.373 14.6177H13.6748V11.8061C13.6748 11.4072 13.9782 11.0815 14.3499 11.0815C14.7216 11.0815 15.0251 11.4072 15.0251 11.8061V14.6177H17.0092V11.8061C17.0092 11.4072 17.3126 11.0815 17.6843 11.0815C18.056 11.0815 18.3594 11.4072 18.3594 11.8061V14.6177H19.6271C19.8623 14.6177 20.0545 14.8239 20.0545 15.0764V15.6082C20.0545 15.8607 19.8623 16.0669 19.6271 16.0669H12.373C12.1378 16.0669 11.9456 15.8607 11.9456 15.6082V15.0764C11.9456 14.8239 12.1378 14.6177 12.373 14.6177ZM14.235 16.4774H17.8333V17.0516H14.235V16.4774ZM11.0496 25.4711C11.4177 25.7139 11.5327 26.2323 11.3064 26.6274C11.0802 27.0224 10.5972 27.1457 10.2291 26.9029C8.62561 25.845 7.2915 24.3534 6.36677 22.5805C5.47617 20.8711 4.96802 18.9016 4.96802 16.8108C4.96802 13.5405 6.20338 10.5805 8.20006 8.43947C10.1967 6.29654 12.9547 4.9707 16.0001 4.9707C19.0471 4.9707 21.8087 6.28305 23.8072 8.41249C25.8003 10.5381 27.0321 13.4769 27.0321 16.7395C27.0321 20.9733 24.8289 23.9448 22.3564 25.2803C21.4424 25.7756 20.4836 26.0473 19.5768 26.0839C18.6288 26.1205 17.731 25.897 16.9876 25.394C15.6858 24.5133 14.8832 22.8368 15.1292 20.2834C13.4467 19.9962 12.7088 19.0443 12.7088 17.1827V16.0727H19.3632V17.0728C19.3632 18.9575 18.539 20.1119 16.7111 20.3007H16.7021C16.4849 22.2143 16.9804 23.4072 17.819 23.9738C18.2894 24.2917 18.8802 24.4324 19.5194 24.4073C20.2017 24.3804 20.9361 24.1684 21.6507 23.783C23.6672 22.6942 25.4627 20.2487 25.4627 16.7395C25.4627 13.9394 24.4087 11.4207 22.7029 9.6015C20.9918 7.77655 18.6216 6.65305 16.0001 6.65305C13.3857 6.65305 11.0209 7.79004 9.30793 9.62848C7.59495 11.4669 6.53556 14.0049 6.53556 16.8108C6.53556 18.6126 6.97009 20.3026 7.73321 21.7653C8.52326 23.2819 9.66884 24.5596 11.0496 25.4711Z" fill="white" stroke="white" stroke-width="0.5" stroke-miterlimit="2.6131" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_695_12571">
                                                <rect width="24" height="24" fill="white" transform="translate(4 4)" />
                                            </clipPath>
                                        </defs>
                                    </svg>}
                                    title={"B Phase"}
                                    name="b_phase"
                                />
                                <DeviceLogCard
                                    icon={<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="32" height="32" rx="4" fill="#0FB501" />
                                        <g clip-path="url(#clip0_695_12583)">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.404 6.4H18.4V5.2C18.4 4.54 17.86 4 17.2 4H14.8C14.14 4 13.6 4.54 13.6 5.2V6.4H11.596C10.72 6.4 10 7.12 10 7.996V26.392C10 27.28 10.72 28 11.608 28H20.392C21.28 28 22 27.28 22 26.404V7.996C22 7.12 21.28 6.4 20.404 6.4ZM19.132 17.488L15.928 23.488C15.64 24.028 14.8 23.824 14.8 23.2V19H13.396C12.94 19 12.652 18.52 12.868 18.112L16.072 12.112C16.36 11.572 17.2 11.776 17.2 12.4V16.6H18.604C19.048 16.6 19.348 17.08 19.132 17.488Z" fill="white" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_695_12583">
                                                <rect width="12" height="24" fill="white" transform="translate(10 4)" />
                                            </clipPath>
                                        </defs>
                                    </svg>}
                                    title={"Battery Level"}
                                    name="battery"
                                />
                                <DeviceLogCard
                                    icon={<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="32" height="32" rx="4" fill="#04D2FF" />
                                        <g clip-path="url(#clip0_695_12661)">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.237 4.00001C18.3741 3.99997 20.445 4.74094 22.097 6.09666C23.749 7.45238 24.8798 9.33896 25.2968 11.435C25.7137 13.531 25.391 15.7067 24.3836 17.5914C23.3762 19.4762 21.7465 20.9533 19.7721 21.7711C17.7977 22.589 15.6009 22.6969 13.5558 22.0766C11.5108 21.4562 9.74407 20.146 8.55677 18.3691C7.36948 16.5922 6.83503 14.4585 7.04449 12.3318C7.25396 10.205 8.19438 8.21664 9.70551 6.7055C10.5624 5.84658 11.5805 5.16541 12.7013 4.70111C13.8222 4.23682 15.0238 3.99856 16.237 4.00001ZM21.5982 7.87579C20.3579 6.63539 18.7258 5.86344 16.9801 5.69147C15.2344 5.5195 13.4831 5.95815 12.0245 6.93268C10.566 7.90721 9.49047 9.35733 8.98124 11.0359C8.47201 12.7146 8.56058 14.5178 9.23185 16.1384C9.90312 17.7591 11.1156 19.0968 12.6626 19.9237C14.2096 20.7507 15.9955 21.0156 17.7159 20.6734C19.4364 20.3312 20.9849 19.403 22.0978 18.047C23.2106 16.691 23.8188 14.9912 23.8188 13.237C23.8202 12.2411 23.6247 11.2548 23.2436 10.3348C22.8625 9.41469 22.3033 8.57903 21.5982 7.87579Z" fill="white" />
                                            <path d="M10.0679 13.2373C10.0679 13.3349 10.1066 13.4285 10.1756 13.4974C10.2446 13.5664 10.3382 13.6052 10.4357 13.6052C10.5333 13.6052 10.6269 13.5664 10.6959 13.4974C10.7649 13.4285 10.8036 13.3349 10.8036 13.2373C10.8036 12.5238 10.9442 11.8173 11.2172 11.1581C11.4902 10.4989 11.8905 9.89998 12.395 9.39546C12.8995 8.89094 13.4984 8.49074 14.1576 8.21769C14.8168 7.94465 15.5233 7.80411 16.2368 7.80411C16.9503 7.80411 17.6568 7.94465 18.316 8.21769C18.9752 8.49074 19.5742 8.89094 20.0787 9.39546C20.5832 9.89998 20.9834 10.4989 21.2565 11.1581C21.5295 11.8173 21.67 12.5238 21.67 13.2373C21.67 13.3349 21.7088 13.4285 21.7778 13.4974C21.8468 13.5664 21.9404 13.6052 22.0379 13.6052C22.1355 13.6052 22.2291 13.5664 22.298 13.4974C22.367 13.4285 22.4058 13.3349 22.4058 13.2373C22.4058 12.4272 22.2462 11.625 21.9362 10.8766C21.6262 10.1281 21.1718 9.44805 20.5989 8.87521C20.0261 8.30237 19.346 7.84796 18.5976 7.53794C17.8491 7.22792 17.047 7.06836 16.2368 7.06836C15.4267 7.06836 14.6245 7.22792 13.8761 7.53794C13.1276 7.84796 12.4476 8.30237 11.8747 8.87521C11.3019 9.44805 10.8475 10.1281 10.5375 10.8766C10.2274 11.625 10.0679 12.4272 10.0679 13.2373Z" fill="white" />
                                            <path d="M16.237 15.0947C15.6826 15.0948 15.1509 15.315 14.7589 15.7071C14.3669 16.0991 14.1467 16.6308 14.1467 17.1852C14.1467 17.7396 14.367 18.2713 14.759 18.6633C15.1511 19.0553 15.6828 19.2756 16.2372 19.2756C16.7916 19.2756 17.3233 19.0553 17.7153 18.6633C18.1073 18.2713 18.3276 17.7396 18.3276 17.1852C18.3276 16.6308 18.1074 16.0991 17.7154 15.7071C17.3234 15.315 16.7917 15.0948 16.2373 15.0947H16.237Z" fill="white" />
                                            <path d="M15.8691 15.4633C15.8691 15.5608 15.9079 15.6544 15.9769 15.7234C16.0459 15.7924 16.1395 15.8311 16.237 15.8311C16.3346 15.8311 16.4282 15.7924 16.4971 15.7234C16.5661 15.6544 16.6049 15.5608 16.6049 15.4633V10.3366C16.6049 10.2391 16.5661 10.1455 16.4971 10.0765C16.4282 10.0075 16.3346 9.96875 16.237 9.96875C16.1395 9.96875 16.0459 10.0075 15.9769 10.0765C15.9079 10.1455 15.8691 10.2391 15.8691 10.3366V15.4633Z" fill="white" />
                                            <path d="M20.6344 21.8809V23.4855C20.6336 23.8307 20.4961 24.1615 20.252 24.4055C20.008 24.6495 19.6772 24.787 19.332 24.7877H13.1417C12.7965 24.787 12.4658 24.6495 12.2217 24.4055C11.9776 24.1615 11.8402 23.8307 11.8394 23.4855V21.8809C13.2019 22.5727 14.7085 22.9333 16.2367 22.9333C17.7649 22.9333 19.2715 22.5727 20.6341 21.8809H20.6344Z" fill="white" />
                                            <path d="M18.7655 25.248V26.698C18.7655 27.414 18.1786 28.0002 17.4633 28.0002H15.0107C14.2948 28.0002 13.7085 27.4133 13.7085 26.698V25.248H18.7657H18.7655Z" fill="white" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_695_12661">
                                                <rect width="18.474" height="24" fill="white" transform="translate(7 4)" />
                                            </clipPath>
                                        </defs>
                                    </svg>}
                                    title={"Low Pressure"}
                                    name="low_pressure"
                                />
                                <DeviceLogCard
                                    icon={<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="32" height="32" rx="4" fill="#D90027" />
                                        <g clip-path="url(#clip0_695_12631)">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.237 4.00001C18.3741 3.99997 20.445 4.74094 22.097 6.09666C23.749 7.45238 24.8798 9.33896 25.2968 11.435C25.7137 13.531 25.391 15.7067 24.3836 17.5914C23.3762 19.4762 21.7465 20.9533 19.7721 21.7711C17.7977 22.589 15.6009 22.6969 13.5558 22.0766C11.5108 21.4562 9.74407 20.146 8.55677 18.3691C7.36948 16.5922 6.83503 14.4585 7.04449 12.3318C7.25396 10.205 8.19438 8.21664 9.70551 6.7055C10.5624 5.84658 11.5805 5.16541 12.7013 4.70111C13.8222 4.23682 15.0238 3.99856 16.237 4.00001ZM21.5982 7.87579C20.3579 6.63539 18.7258 5.86344 16.9801 5.69147C15.2344 5.5195 13.4831 5.95815 12.0245 6.93268C10.566 7.90721 9.49047 9.35733 8.98124 11.0359C8.47201 12.7146 8.56058 14.5178 9.23185 16.1384C9.90312 17.7591 11.1156 19.0968 12.6626 19.9237C14.2096 20.7507 15.9955 21.0156 17.7159 20.6734C19.4364 20.3312 20.9849 19.403 22.0978 18.047C23.2106 16.691 23.8188 14.9912 23.8188 13.237C23.8202 12.2411 23.6247 11.2548 23.2436 10.3348C22.8625 9.41469 22.3033 8.57903 21.5982 7.87579Z" fill="white" />
                                            <path d="M10.068 13.2373C10.068 13.3349 10.1068 13.4285 10.1757 13.4974C10.2447 13.5664 10.3383 13.6052 10.4359 13.6052C10.5334 13.6052 10.627 13.5664 10.696 13.4974C10.765 13.4285 10.8037 13.3349 10.8037 13.2373C10.8037 12.5238 10.9443 11.8173 11.2173 11.1581C11.4904 10.4989 11.8906 9.89998 12.3951 9.39546C12.8996 8.89094 13.4986 8.49074 14.1578 8.21769C14.8169 7.94465 15.5235 7.80411 16.237 7.80411C16.9505 7.80411 17.657 7.94465 18.3162 8.21769C18.9753 8.49074 19.5743 8.89094 20.0788 9.39546C20.5833 9.89998 20.9835 10.4989 21.2566 11.1581C21.5296 11.8173 21.6702 12.5238 21.6702 13.2373C21.6702 13.3349 21.7089 13.4285 21.7779 13.4974C21.8469 13.5664 21.9405 13.6052 22.038 13.6052C22.1356 13.6052 22.2292 13.5664 22.2982 13.4974C22.3672 13.4285 22.4059 13.3349 22.4059 13.2373C22.4059 12.4272 22.2464 11.625 21.9363 10.8766C21.6263 10.1281 21.1719 9.44805 20.5991 8.87521C20.0262 8.30237 19.3462 7.84796 18.5977 7.53794C17.8493 7.22792 17.0471 7.06836 16.237 7.06836C15.4268 7.06836 14.6246 7.22792 13.8762 7.53794C13.1277 7.84796 12.4477 8.30237 11.8748 8.87521C11.302 9.44805 10.8476 10.1281 10.5376 10.8766C10.2276 11.625 10.068 12.4272 10.068 13.2373Z" fill="white" />
                                            <path d="M16.237 15.0947C15.6826 15.0948 15.1509 15.315 14.7589 15.7071C14.3669 16.0991 14.1467 16.6308 14.1467 17.1852C14.1467 17.7396 14.367 18.2713 14.759 18.6633C15.1511 19.0553 15.6828 19.2756 16.2372 19.2756C16.7916 19.2756 17.3233 19.0553 17.7153 18.6633C18.1073 18.2713 18.3276 17.7396 18.3276 17.1852C18.3276 16.6308 18.1074 16.0991 17.7154 15.7071C17.3234 15.315 16.7917 15.0948 16.2373 15.0947H16.237Z" fill="white" />
                                            <path d="M15.8691 15.4633C15.8691 15.5608 15.9079 15.6544 15.9769 15.7234C16.0459 15.7924 16.1395 15.8311 16.237 15.8311C16.3346 15.8311 16.4282 15.7924 16.4971 15.7234C16.5661 15.6544 16.6049 15.5608 16.6049 15.4633V10.3366C16.6049 10.2391 16.5661 10.1455 16.4971 10.0765C16.4282 10.0075 16.3346 9.96875 16.237 9.96875C16.1395 9.96875 16.0459 10.0075 15.9769 10.0765C15.9079 10.1455 15.8691 10.2391 15.8691 10.3366V15.4633Z" fill="white" />
                                            <path d="M20.6344 21.8809V23.4855C20.6336 23.8307 20.4961 24.1615 20.252 24.4055C20.008 24.6495 19.6772 24.787 19.332 24.7877H13.1417C12.7965 24.787 12.4658 24.6495 12.2217 24.4055C11.9776 24.1615 11.8402 23.8307 11.8394 23.4855V21.8809C13.2019 22.5727 14.7085 22.9333 16.2367 22.9333C17.7649 22.9333 19.2715 22.5727 20.6341 21.8809H20.6344Z" fill="white" />
                                            <path d="M18.7657 25.248V26.698C18.7657 27.414 18.1787 28.0002 17.4635 28.0002H15.0108C14.2949 28.0002 13.7086 27.4133 13.7086 26.698V25.248H18.7658H18.7657Z" fill="white" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_695_12631">
                                                <rect width="18.474" height="24" fill="white" transform="translate(7 4)" />
                                            </clipPath>
                                        </defs>
                                    </svg>}
                                    title={"High Pressure"}
                                    name="high_pressure"
                                />
                                <DeviceLogCard
                                    icon={<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="32" height="32" rx="4" fill="#00E778" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.3656 23.7539H18.4675V18.355C18.4675 18.2565 18.3832 18.1722 18.2848 18.1722C18.1723 18.1722 18.102 18.2565 18.102 18.355V23.7539H13.8981V18.355C13.8981 18.2565 13.8138 18.1722 13.7153 18.1722C13.6169 18.1722 13.5326 18.2565 13.5326 18.355V23.7539H11.6345C11.4236 23.7539 11.2549 23.5852 11.2549 23.3743V16.1335C11.2549 15.9226 11.4236 15.7539 11.6345 15.7539H20.3656C20.5765 15.7539 20.7312 15.9226 20.7312 16.1335V23.3743C20.7452 23.5852 20.5765 23.7539 20.3656 23.7539Z" fill="white" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M24.6539 22.0391H21.1108V17.4697H24.6539V22.0391Z" fill="white" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M27.6204 22.9808H25.399C25.2022 22.9808 25.0334 22.8121 25.0334 22.6012V16.907C25.0334 16.6961 25.2022 16.5273 25.399 16.5273H27.6204C27.8173 16.5273 27.986 16.6961 27.986 16.907V22.6012C27.986 22.8121 27.8173 22.9808 27.6204 22.9808Z" fill="white" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8894 22.0391H7.33228V17.4697H10.8894V22.0391Z" fill="white" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.58699 22.9808H4.37961C4.16872 22.9808 4 22.8121 4 22.6012V16.907C4 16.6961 4.16872 16.5273 4.37961 16.5273H6.58699C6.79789 16.5273 6.96661 16.6961 6.96661 16.907V22.6012C6.96661 22.8121 6.79789 22.9808 6.58699 22.9808Z" fill="white" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2231 15.3748V14.6156C13.2231 14.4187 13.3919 14.25 13.6028 14.25H18.3971C18.594 14.25 18.7627 14.4187 18.7627 14.6156V15.3748H13.2231Z" fill="white" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.095 9.66613V8.61165C14.095 8.41481 14.2637 8.24609 14.4746 8.24609H17.5256C17.7224 8.24609 17.8911 8.41481 17.8911 8.61165V9.31464L17.3147 9.66613H14.095Z" fill="white" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.095 13.87V13.0967H17.8911V13.87H14.095Z" fill="white" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.5519 12.7175H12.9561C12.7593 12.7175 12.5906 12.5629 12.5906 12.352V10.4117C12.5906 10.2149 12.7593 10.0462 12.9561 10.0462H17.4131L19.6205 8.72455C19.7329 8.65425 19.8595 8.65425 19.9719 8.71049H22.348C22.5589 8.71049 22.7277 8.87921 22.7277 9.0901V11.0303C22.7277 11.2412 22.5589 11.41 22.348 11.41H20.3937L18.7909 12.6472C18.7206 12.6894 18.6363 12.7175 18.5519 12.7175Z" fill="white" />
                                    </svg>}
                                    title={"Valve 1"}
                                    name="valve_1"
                                />
                                <DeviceLogCard
                                    icon={<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="32" height="32" rx="4" fill="#04CAA6" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.3656 23.7539H18.4675V18.355C18.4675 18.2565 18.3832 18.1722 18.2848 18.1722C18.1723 18.1722 18.102 18.2565 18.102 18.355V23.7539H13.8981V18.355C13.8981 18.2565 13.8138 18.1722 13.7153 18.1722C13.6169 18.1722 13.5326 18.2565 13.5326 18.355V23.7539H11.6345C11.4236 23.7539 11.2549 23.5852 11.2549 23.3743V16.1335C11.2549 15.9226 11.4236 15.7539 11.6345 15.7539H20.3656C20.5765 15.7539 20.7312 15.9226 20.7312 16.1335V23.3743C20.7452 23.5852 20.5765 23.7539 20.3656 23.7539Z" fill="white" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M24.6539 22.0391H21.1108V17.4697H24.6539V22.0391Z" fill="white" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M27.6204 22.9808H25.399C25.2022 22.9808 25.0334 22.8121 25.0334 22.6012V16.907C25.0334 16.6961 25.2022 16.5273 25.399 16.5273H27.6204C27.8173 16.5273 27.986 16.6961 27.986 16.907V22.6012C27.986 22.8121 27.8173 22.9808 27.6204 22.9808Z" fill="white" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8894 22.0391H7.33228V17.4697H10.8894V22.0391Z" fill="white" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.58699 22.9808H4.37961C4.16872 22.9808 4 22.8121 4 22.6012V16.907C4 16.6961 4.16872 16.5273 4.37961 16.5273H6.58699C6.79789 16.5273 6.96661 16.6961 6.96661 16.907V22.6012C6.96661 22.8121 6.79789 22.9808 6.58699 22.9808Z" fill="white" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2231 15.3748V14.6156C13.2231 14.4187 13.3919 14.25 13.6028 14.25H18.3971C18.594 14.25 18.7627 14.4187 18.7627 14.6156V15.3748H13.2231Z" fill="white" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.095 9.66613V8.61165C14.095 8.41481 14.2637 8.24609 14.4746 8.24609H17.5256C17.7224 8.24609 17.8911 8.41481 17.8911 8.61165V9.31464L17.3147 9.66613H14.095Z" fill="white" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.095 13.87V13.0967H17.8911V13.87H14.095Z" fill="white" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.5519 12.7175H12.9561C12.7593 12.7175 12.5906 12.5629 12.5906 12.352V10.4117C12.5906 10.2149 12.7593 10.0462 12.9561 10.0462H17.4131L19.6205 8.72455C19.7329 8.65425 19.8595 8.65425 19.9719 8.71049H22.348C22.5589 8.71049 22.7277 8.87921 22.7277 9.0901V11.0303C22.7277 11.2412 22.5589 11.41 22.348 11.41H20.3937L18.7909 12.6472C18.7206 12.6894 18.6363 12.7175 18.5519 12.7175Z" fill="white" />
                                    </svg>}
                                    title={"Valve 2"}
                                    name="valve_2"
                                />
                                <DeviceLogCard
                                    icon={<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="32" height="32" rx="4" fill="#93808F" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.3656 23.7539H18.4675V18.355C18.4675 18.2565 18.3832 18.1722 18.2848 18.1722C18.1723 18.1722 18.102 18.2565 18.102 18.355V23.7539H13.8981V18.355C13.8981 18.2565 13.8138 18.1722 13.7153 18.1722C13.6169 18.1722 13.5326 18.2565 13.5326 18.355V23.7539H11.6345C11.4236 23.7539 11.2549 23.5852 11.2549 23.3743V16.1335C11.2549 15.9226 11.4236 15.7539 11.6345 15.7539H20.3656C20.5765 15.7539 20.7312 15.9226 20.7312 16.1335V23.3743C20.7452 23.5852 20.5765 23.7539 20.3656 23.7539Z" fill="white" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M24.6539 22.0391H21.1108V17.4697H24.6539V22.0391Z" fill="white" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M27.6204 22.9808H25.399C25.2022 22.9808 25.0334 22.8121 25.0334 22.6012V16.907C25.0334 16.6961 25.2022 16.5273 25.399 16.5273H27.6204C27.8173 16.5273 27.986 16.6961 27.986 16.907V22.6012C27.986 22.8121 27.8173 22.9808 27.6204 22.9808Z" fill="white" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8894 22.0391H7.33228V17.4697H10.8894V22.0391Z" fill="white" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.58699 22.9808H4.37961C4.16872 22.9808 4 22.8121 4 22.6012V16.907C4 16.6961 4.16872 16.5273 4.37961 16.5273H6.58699C6.79789 16.5273 6.96661 16.6961 6.96661 16.907V22.6012C6.96661 22.8121 6.79789 22.9808 6.58699 22.9808Z" fill="white" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2231 15.3748V14.6156C13.2231 14.4187 13.3919 14.25 13.6028 14.25H18.3971C18.594 14.25 18.7627 14.4187 18.7627 14.6156V15.3748H13.2231Z" fill="white" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.095 9.66613V8.61165C14.095 8.41481 14.2637 8.24609 14.4746 8.24609H17.5256C17.7224 8.24609 17.8911 8.41481 17.8911 8.61165V9.31464L17.3147 9.66613H14.095Z" fill="white" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.095 13.87V13.0967H17.8911V13.87H14.095Z" fill="white" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.5519 12.7175H12.9561C12.7593 12.7175 12.5906 12.5629 12.5906 12.352V10.4117C12.5906 10.2149 12.7593 10.0462 12.9561 10.0462H17.4131L19.6205 8.72455C19.7329 8.65425 19.8595 8.65425 19.9719 8.71049H22.348C22.5589 8.71049 22.7277 8.87921 22.7277 9.0901V11.0303C22.7277 11.2412 22.5589 11.41 22.348 11.41H20.3937L18.7909 12.6472C18.7206 12.6894 18.6363 12.7175 18.5519 12.7175Z" fill="white" />
                                    </svg>}
                                    title={"Valve 3"}
                                    name="valve_3"
                                />
                                <DeviceLogCard
                                    icon={<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="32" height="32" rx="4" fill="#00D9CC" />
                                        <g clip-path="url(#clip0_695_12720)">
                                            <path d="M13.5919 4.41626C13.1783 4.0569 12.196 3.47929 12.0352 4.97531C11.4579 8.84452 7.3009 11.5486 7.3009 15.3634C7.3009 18.5791 9.90775 21.186 13.1235 21.186C16.3392 21.186 18.946 18.5791 18.946 15.3634C18.946 12.4458 17.7749 8.05002 13.5919 4.41626ZM10.8462 18.4516C10.756 18.5723 10.6179 18.6362 10.4783 18.6362C10.3837 18.6362 10.2884 18.607 10.2065 18.5464C10.1553 18.5084 8.94858 17.5975 8.68848 15.8756C8.42844 14.1542 9.59418 12.541 9.64395 12.4731C9.79376 12.2688 10.081 12.2245 10.2854 12.3745C10.4895 12.5243 10.5338 12.811 10.3844 13.0154C10.3693 13.0363 9.39376 14.3997 9.59609 15.7386C9.7977 17.0736 10.7448 17.8027 10.7543 17.8099C10.9559 17.9622 10.9975 18.2494 10.8462 18.4516Z" fill="white" />
                                            <path d="M10.7544 17.8095C10.7448 17.8023 9.79775 17.0732 9.59614 15.7382C9.39382 14.3993 10.3694 13.0358 10.3845 13.015C10.5339 12.8106 10.4896 12.5239 10.2855 12.3741C10.081 12.2241 9.79381 12.2684 9.64401 12.4727C9.59423 12.5405 8.4285 14.1538 8.68854 15.8752C8.94858 17.597 10.1553 18.508 10.2065 18.5459C10.2885 18.6066 10.3838 18.6358 10.4783 18.6358C10.618 18.6358 10.756 18.572 10.8462 18.4512C10.9974 18.249 10.9559 17.9618 10.7544 17.8095Z" fill="#F7F1E6" />
                                            <path d="M21.6438 18.43C21.4077 18.2248 20.8472 17.8952 20.7555 18.749C20.426 20.9571 18.0536 22.5003 18.0536 24.6773C18.0536 26.5124 19.5413 28.0001 21.3765 28.0001C23.2116 28.0001 24.6992 26.5124 24.6992 24.6773C24.6992 23.0123 24.0309 20.5037 21.6438 18.43ZM20.0769 26.4397C20.0254 26.5085 19.9467 26.545 19.8669 26.545C19.813 26.545 19.7586 26.5283 19.7118 26.4937C19.6825 26.4721 18.9939 25.9522 18.8455 24.9696C18.6972 23.9872 19.3624 23.0665 19.3907 23.0279C19.4763 22.9112 19.6402 22.886 19.7568 22.9716C19.8733 23.0571 19.8986 23.2207 19.8134 23.3373C19.8047 23.3493 19.248 24.1273 19.3635 24.8914C19.4786 25.6533 20.0189 26.0694 20.0245 26.0735C20.1395 26.1603 20.1632 26.3242 20.0769 26.4397Z" fill="white" />
                                            <path d="M20.0245 26.0736C20.0189 26.0695 19.4786 25.6533 19.3635 24.8915C19.248 24.1275 19.8047 23.3493 19.8134 23.3374C19.8986 23.2208 19.8733 23.0571 19.7568 22.9716C19.6402 22.8861 19.4763 22.9113 19.3907 23.0279C19.3624 23.0666 18.6972 23.9873 18.8455 24.9697C18.9938 25.9522 19.6825 26.4721 19.7118 26.4938C19.7585 26.5284 19.8129 26.5451 19.8669 26.5451C19.9467 26.5451 20.0253 26.5087 20.0769 26.4397C20.1631 26.3244 20.1394 26.1605 20.0245 26.0736Z" fill="#F7F1E6" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_695_12720">
                                                <rect width="24" height="24" fill="white" transform="translate(4 4)" />
                                            </clipPath>
                                        </defs>
                                    </svg>}
                                    title={"Water Level"}
                                    name="water_level"
                                />
                                <DeviceLogCard
                                    icon={<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="32" height="32" rx="4" fill="#0098D9" />
                                        <path d="M23.5825 5.99865L12.9175 4.8024C12.8123 4.79076 12.7059 4.80151 12.6051 4.83395C12.5044 4.86639 12.4116 4.91979 12.333 4.99063C12.2544 5.06147 12.1916 5.14815 12.1489 5.24498C12.1061 5.3418 12.0844 5.44657 12.085 5.5524V6.2499H8.5C8.3011 6.2499 8.11034 6.32892 7.96968 6.46958C7.82901 6.61023 7.75 6.80099 7.75 6.9999V24.9999C7.75 25.1988 7.82901 25.3896 7.96968 25.5302C8.11034 25.6709 8.3011 25.7499 8.5 25.7499H12.085V26.4587C12.085 26.6576 12.164 26.8483 12.3047 26.989C12.4453 27.1297 12.6361 27.2087 12.835 27.2087C12.91 27.2087 23.5 26.0162 23.5825 26.0087C23.7668 25.9883 23.937 25.9003 24.0603 25.7618C24.1835 25.6233 24.2511 25.4441 24.25 25.2587V6.74115C24.2493 6.55704 24.1809 6.37962 24.0578 6.24269C23.9347 6.10577 23.7655 6.01891 23.5825 5.99865ZM12.085 24.2499H9.25V7.74992H12.085V24.2499ZM16.75 16.9599C16.75 17.1588 16.671 17.3496 16.5303 17.4902C16.3897 17.6309 16.1989 17.7099 16 17.7099C15.8011 17.7099 15.6103 17.6309 15.4697 17.4902C15.329 17.3496 15.25 17.1588 15.25 16.9599V15.0399C15.25 14.841 15.329 14.6502 15.4697 14.5096C15.6103 14.3689 15.8011 14.2899 16 14.2899C16.1989 14.2899 16.3897 14.3689 16.5303 14.5096C16.671 14.6502 16.75 14.841 16.75 15.0399V16.9599Z" fill="white" />
                                    </svg>}
                                    title={"Door Open"}
                                    name="door_open"
                                />
                                <DeviceLogCard
                                    icon={<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="32" height="32" rx="4" fill="#3E8682" />
                                        <g clip-path="url(#clip0_695_12853)">
                                            <path d="M16 4C9.38331 4 4 9.38331 4 16C4 22.6167 9.38331 28 16 28C22.6167 28 28 22.6167 28 16C28 9.38331 22.6167 4 16 4ZM16 26.5C10.2102 26.5 5.5 21.7898 5.5 16C5.5 10.2102 10.2102 5.5 16 5.5C21.7898 5.5 26.5 10.2102 26.5 16C26.5 21.7898 21.7898 26.5 16 26.5Z" fill="white" />
                                            <path d="M20.5 11.5C18.9345 11.5 17.5564 12.3054 16.75 13.5216V7.75C16.75 7.33545 16.4146 7 16 7C13.5185 7 11.5 9.01855 11.5 11.5C11.5 13.0655 12.3054 14.4436 13.5216 15.25H7.75C7.33545 15.25 7 15.5854 7 16C7 18.4815 9.01855 20.5 11.5 20.5C13.0655 20.5 14.4436 19.6946 15.25 18.4784V24.25C15.25 24.6646 15.5854 25 16 25C18.4815 25 20.5 22.9815 20.5 20.5C20.5 18.9345 19.6946 17.5564 18.4784 16.75H24.25C24.6646 16.75 25 16.4146 25 16C25 13.5185 22.9815 11.5 20.5 11.5ZM11.5 19C10.1047 19 8.92846 18.0427 8.59522 16.75H14.4048C14.0715 18.0427 12.8953 19 11.5 19ZM13 11.5C13 10.1047 13.9573 8.92846 15.25 8.59522V14.4048C13.9573 14.0715 13 12.8953 13 11.5ZM16 16.75C15.5858 16.75 15.25 16.4142 15.25 16C15.25 15.5857 15.5858 15.25 16 15.25C16.4142 15.25 16.75 15.5857 16.75 16C16.75 16.4142 16.4142 16.75 16 16.75ZM19 20.5C19 21.8953 18.0427 23.0715 16.75 23.4048V17.5952C18.0427 17.9285 19 19.1047 19 20.5ZM17.5952 15.25C17.9285 13.9573 19.1047 13 20.5 13C21.8953 13 23.0715 13.9573 23.4048 15.25H17.5952Z" fill="white" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_695_12853">
                                                <rect width="24" height="24" fill="white" transform="translate(4 4)" />
                                            </clipPath>
                                        </defs>
                                    </svg>}
                                    title={"Fan 1"}
                                    name="fan_1"
                                />
                                <DeviceLogCard
                                    icon={<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="32" height="32" rx="4" fill="#D90000" />
                                        <g clip-path="url(#clip0_695_12876)">
                                            <path d="M16 4C9.38331 4 4 9.38331 4 16C4 22.6167 9.38331 28 16 28C22.6167 28 28 22.6167 28 16C28 9.38331 22.6167 4 16 4ZM16 26.5C10.2102 26.5 5.5 21.7898 5.5 16C5.5 10.2102 10.2102 5.5 16 5.5C21.7898 5.5 26.5 10.2102 26.5 16C26.5 21.7898 21.7898 26.5 16 26.5Z" fill="white" />
                                            <path d="M20.5 11.5C18.9345 11.5 17.5564 12.3054 16.75 13.5216V7.75C16.75 7.33545 16.4146 7 16 7C13.5185 7 11.5 9.01855 11.5 11.5C11.5 13.0655 12.3054 14.4436 13.5216 15.25H7.75C7.33545 15.25 7 15.5854 7 16C7 18.4815 9.01855 20.5 11.5 20.5C13.0655 20.5 14.4436 19.6946 15.25 18.4784V24.25C15.25 24.6646 15.5854 25 16 25C18.4815 25 20.5 22.9815 20.5 20.5C20.5 18.9345 19.6946 17.5564 18.4784 16.75H24.25C24.6646 16.75 25 16.4146 25 16C25 13.5185 22.9815 11.5 20.5 11.5ZM11.5 19C10.1047 19 8.92846 18.0427 8.59522 16.75H14.4048C14.0715 18.0427 12.8953 19 11.5 19ZM13 11.5C13 10.1047 13.9573 8.92846 15.25 8.59522V14.4048C13.9573 14.0715 13 12.8953 13 11.5ZM16 16.75C15.5858 16.75 15.25 16.4142 15.25 16C15.25 15.5857 15.5858 15.25 16 15.25C16.4142 15.25 16.75 15.5857 16.75 16C16.75 16.4142 16.4142 16.75 16 16.75ZM19 20.5C19 21.8953 18.0427 23.0715 16.75 23.4048V17.5952C18.0427 17.9285 19 19.1047 19 20.5ZM17.5952 15.25C17.9285 13.9573 19.1047 13 20.5 13C21.8953 13 23.0715 13.9573 23.4048 15.25H17.5952Z" fill="white" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_695_12876">
                                                <rect width="24" height="24" fill="white" transform="translate(4 4)" />
                                            </clipPath>
                                        </defs>
                                    </svg>}
                                    title={"Fan 2"}
                                    name="fan_2"
                                />
                            </div>
                            <div className="py-5 flex justify-end gap-6">
                                <div>
                                    <Button
                                        type="submit"
                                        className="text-white bg-primary hover:bg-primary-dark font-sm rounded-lg text-lg w-full px-8 py-1.5 text-center flex flex-row items-center"
                                        onSubmit={handleDownload}
                                    >
                                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" className=" mr-2">
                                            <path d="M10.5 13.333V3.33301V13.333Z" fill="white" />
                                            <path d="M3.83337 13.333V14.1663C3.83337 14.8294 4.09677 15.4653 4.56561 15.9341C5.03445 16.4029 5.67033 16.6663 6.33337 16.6663H14.6667C15.3297 16.6663 15.9656 16.4029 16.4345 15.9341C16.9033 15.4653 17.1667 14.8294 17.1667 14.1663V13.333M13.8334 9.99967L10.5 13.333M10.5 13.333L7.16671 9.99967M10.5 13.333V3.33301" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        Save Report
                                    </Button>
                                </div>
                            </div>
                        </>
                    </Form>
                )
                }
            </Formik >
        </div >
    );
}

export default DeviceLogReport;