import {
  otherDevicesCRUDApiUrl
} from "../../constant/urls/apiUrls";
import axios from "axios";
import { get_token } from "../../constant/urls/localStoarege";

export const getOtherDevicesApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.get(`${otherDevicesCRUDApiUrl.replace("http://", "https://")}${data || ""}`, config);

  return response;
};

export const addOtherDevicesApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.post(otherDevicesCRUDApiUrl, data, config);

  return response;
};

export const editOtherdevicesApi = async (data, id) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.patch(
    `${otherDevicesCRUDApiUrl}${id}/`,
    data,
    config
  );

  return response;
};

export const deleteOtherDevicesApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.delete(
    `${otherDevicesCRUDApiUrl}${data}/`,
    config
  );

  return response;
};
