import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { AiFillHome } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import InputField from "../../../../components/inputFields/InputField";
import Button from "../../../../components/buttons/Button";
import { editTutorialApi } from "../../../../services/apis/tutorials";
import Toast, { showSuccessToast } from "../../../../components/toastify/Toast";
import showAlert from "../../../../components/errorBoundries/Errors";
import { get_user_id } from "../../../../constant/urls/localStoarege";
import TextareaField from "../../../../components/inputFields/TextField";

const EditTutorial = () => {
  const location = useLocation();
  const userId = get_user_id();
  const navigate = useNavigate();
  const { rowData } = location.state;

  const initialValues = {
    title: rowData?.title || "",
    youtube_url: rowData?.youtube_url || "",
    text: rowData?.description || "",
  };

  const handleFormSubmit = async (values, { setSubmitting }) => {
    try {
      const payload = {
        title: values?.title,
        youtube_url: values?.youtube_url,
        description: values?.text,
        created_by: userId,
      };
      const id = rowData?.id;

      const response = await editTutorialApi(payload, id);

      if (response?.status === 200) {
        showSuccessToast("Tutorial edited successfully!");
        navigate("/tutorials");
      } else {
        let errors = "Oops! Something went wrong, please try again.";
        showAlert("Error", errors, "error");
      }
    } catch (error) {
      let errors =
        error?.response?.data?.error ||
        "Oops! Something went wrong, please try again.";
      showAlert("Error", errors, "error");
    } finally {
      setSubmitting(false);
    }
  };

  const handleDiscardChanges = (resetForm) => {
    navigate("/tutorials");
  };
  return (
    <div className="pl-5">
      <Toast />
      {/* Top Section Begin */}
      <div className="flex gap-3 items-center font-medium text-gray-500">
        <AiFillHome />
        <p>Home</p>
        <IoIosArrowForward />
        <p>Tutorials</p>
        <IoIosArrowForward />
        <p>Edit Tutorial</p>
      </div>
      {/* Top Section End */}
      {/* Form Begin */}
      <div className="mt-8">
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object({
            title: Yup.string().required("Please enter tutorial title"),
            youtube_url: Yup.string()
              .required("Please enter YouTube URL")
              .matches(
                /^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+/,
                "Invalid YouTube URL"
              ),
            // text: Yup.string().required("Please enter tutorial text"),
          })}
          onSubmit={handleFormSubmit}
        >
          {({ isSubmitting, resetForm }) => (
            <Form>
              <div className="border border-gray-300 mt-5 p-4 rounded-lg bg-gray-100">
                <div className="w-full mb-1">
                  <InputField
                    label="Title"
                    name="title"
                    type="text"
                    placeholder="Enter title"
                    false
                  />
                </div>
                <div className="w-full mb-1">
                  <InputField
                    label="Youtube URL"
                    name="youtube_url"
                    type="text"
                    placeholder="Enter youtube url"
                    required
                  />
                </div>
                <div className="w-full mb-1">
                  <TextareaField
                    label="Text"
                    name="text"
                    placeholder="Enter tutorial text"
                    false
                  />
                </div>
              </div>
              <div className="py-5 flex justify-end gap-6">
                <div>
                  <Button
                    type="button"
                    className="text-primary bg-white hover:bg-white-dark font-sm rounded-lg text-lg w-full px-8 py-1.5 text-center border border-primary"
                    onClick={() => handleDiscardChanges(resetForm)}
                    disabled={isSubmitting}
                  >
                    Discard Changes
                  </Button>
                </div>
                <div>
                  <Button
                    type="submit"
                    className="text-white bg-primary hover:bg-primary-dark font-sm rounded-lg text-lg w-full px-8 py-1.5 text-center"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {/* Form End */}
    </div>
  );
};

export default EditTutorial;
