import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { AiFillHome } from "react-icons/ai";
import { IoIosArrowForward, IoMdAdd } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import InputField from "../../../components/inputFields/InputField";
import Select from "../../../components/inputFields/SelectField";
import Button from "../../../components/buttons/Button";
import { get_user_id } from "../../../constant/urls/localStoarege";
import showAlert from "../../../components/errorBoundries/Errors";
import { showSuccessToast } from "../../../components/toastify/Toast";
import { addManualReportApi, getUserDropDownApi } from "../../../services/apis/manual_report";
import { getDeviceDropDownApi } from "../../../services/apis/commodity";

function AddManualReport() {
    const navigate = useNavigate();
    const userId = get_user_id();
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedFile, setSelectedFile] = useState("");
    const [userOptions, setUserOptions] = useState({});
    const [deviceOptions, setDeviceOptions] = useState({});
    const reportTypeOptions = [
        { value: "Installation Report", label: "Installation Report", },
        { value: "Survey Report", label: "Survey Report", },
        { value: "Site Visit Report", label: "Site Visit Report", },
        { value: "Monitoring Report", label: "Monitoring Report", },
    ];



    useEffect(() => {
        const fetchData = async () => {
            try {
                const respones = await getUserDropDownApi();
                if (respones?.status === 200) {
                    setUserOptions(respones?.data);
                } else {
                    let errors = "Opps! Something went wrong, please try again.";
                    showAlert("Error", errors, "error");
                }
            } catch (error) {
                let errors =
                    error?.response?.data?.detail ||
                    "Opps! Something went wrong, please try again.";
                showAlert("Error", errors, "error");
            }
            try {
                const respones = await getDeviceDropDownApi();
                if (respones?.status === 200) {
                    setDeviceOptions(respones?.data);
                } else {
                    let errors = "Opps! Something went wrong, please try again.";
                    showAlert("Error", errors, "error");
                }
            } catch (error) {
                let errors =
                    error?.response?.data?.detail ||
                    "Opps! Something went wrong, please try again.";
                showAlert("Error", errors, "error");
            }
        };
        fetchData();
    }, []);

    const transformUserData = () => {
        const options = [];
        if (userOptions && userOptions) {
            for (let i = 0; i < userOptions.length; i++) {
                const item = userOptions[i];
                options.push({
                    value: item.id,
                    label: item.name,
                });
            }
        }
        return options;
    };


    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleProfileClick = () => {
        document.getElementById("fileInput").click();
    };

    const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            const formData = new FormData();
            if (selectedFile) {
                formData.append("file", selectedFile);
            }
            formData.append("report_type", values.report_type);
            formData.append("surveyed_by", values.surveyed_by);
            formData.append("installation_time", values.installation_time.replace("T", " "));
            formData.append("device", values.device);
            formData.append("created_by", userId);

            const response = await addManualReportApi(formData);

            if (response?.status === 201) {
                navigate("/manual");
                showSuccessToast("Report added successfully!");
                if (typeof resetForm === "function") {
                    resetForm();
                }
            } else {
                let errors = "Opps! Something went wrong, please try again.";
                showAlert("Error", errors, "error");
            }
        } catch (error) {
            let errors =
                error?.response?.data?.error ||
                "Opps! Something went wrong, please try again.";
            showAlert("Error", errors, "error");
        }
        setSubmitting(false);
    };

    const handleDiscardChanges = (resetForm) => {
        resetForm();
        navigate("/plant");
    };
    
    const transformData = () => {
        const options = [];
        if (deviceOptions && deviceOptions) {
            for (let i = 0; i < deviceOptions.length; i++) {
                const item = deviceOptions[i];
                options.push({
                    value: item.id,
                    label: item.device_name,
                });
            }
        }
        return options;
    };

    return (
        <div className="relative">
            {/* Top Section Begin */}
            <div className="flex gap-3 items-center font-medium text-gray-500">
                <AiFillHome />
                <p>Home</p>
                <IoIosArrowForward />
                <p>Manual Report</p>
                <IoIosArrowForward />
                <p>Add New Report</p>
            </div>
            {/* Top Section End */}
            {/* Form Begin */}
            <div className="mt-8">
                <Formik
                    initialValues={{
                        report_type: "",
                        surveyed_by: "",
                        installation_time: "",
                    }}
                    validationSchema={Yup.object({
                        report_type: Yup.string().required("Select Report Type"),
                        surveyed_by: Yup.string().required("Select User"),
                        installation_time: Yup.string().required("Installation time is medatory"),
                    })}
                    onSubmit={handleFormSubmit}
                >
                    {({ isSubmitting, resetForm }) => (
                        <Form>
                            <div className="items-start w-full border-gray-300 p-4 rounded-lg bg-gray-100 border">
                                <div className="flex-1 ml-5">
                                    <div className="w-full mb-1">
                                        <div className="grid gap-6 md:grid-cols-3">
                                            <Select
                                                label="Report Type"
                                                name="report_type"
                                                options={reportTypeOptions}
                                                required={true}
                                            />
                                            <Select
                                                label="Surveyed By"
                                                name="surveyed_by"
                                                options={transformUserData()}
                                                required
                                                needSearch={true}
                                            />
                                            <InputField
                                                label="Installation Time"
                                                name="installation_time"
                                                type="datetime-local"
                                                placeholder="Select"
                                                required
                                            />
                                            <Select
                                                label="DEVICE"
                                                name="device"
                                                options={transformData()}
                                                false
                                                needSearch={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-1 ml-5">
                                    <label htmlFor="file" className="font-medium">
                                        Upload File
                                        <span className="text-red-600 font-bold">*</span>
                                    </label>
                                    <div className="w-full mt-2">
                                        <input
                                            type="file"
                                            id="fileInput"
                                            style={{ display: "none" }}
                                            onChange={handleImageChange}
                                        />
                                        <div
                                            onClick={handleProfileClick}
                                            className="flex items-center border border-gray-300 rounded-md bg-white cursor-pointer"
                                        >
                                            <label
                                                htmlFor="fileInput"
                                                className="py-2 px-4 bg-gray-800 text-white rounded-l-md"
                                            >
                                                Choose file
                                            </label>
                                            <div className="flex-1 px-4 py-2 text-gray-600">
                                                {selectedFile ? (
                                                    <span>{selectedFile.name}</span>
                                                ) : (
                                                    "No file chosen"
                                                )}
                                            </div>
                                        </div>
                                        <p className="text-gray-400 text-sm mt-1">Max file size 2MB</p>
                                    </div>
                                </div>
                            </div>
                            <div className="py-5 flex justify-end gap-6">
                                <div>
                                    <Button
                                        type="button"
                                        className="text-primary bg-white hover:bg-white-dark font-sm rounded-lg text-lg w-full px-8 py-1.5 text-center border border-primary"
                                        onClick={() => handleDiscardChanges(resetForm)}
                                        disabled={isSubmitting}
                                    >
                                        Discard Changes
                                    </Button>
                                </div>
                                <div>
                                    <Button
                                        type="submit"
                                        className="text-white bg-primary hover:bg-primary-dark font-sm rounded-lg text-lg w-full px-8 py-1.5 text-center"
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? "Submitting..." : "Submit"}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            {/* Form End */}
        </div>
    );
}

export default AddManualReport;