import React, { useEffect, useState } from "react";

const Tab = ({ tabsList = [], onClick, activeTab }) => {
  const handleTabClick = (tabId) => {
    if (onClick) {
      onClick(tabId);
    }
  };

  useEffect(() => {
  }, [tabsList]);

  if (tabsList.length > 0) {
    return (
      <div className="max-w-screen-lg">
        <div className="flex border-b border-gray-200 gap-1">
          {tabsList.map((tab) => (
            <button
              key={tab.id}
              onClick={() => handleTabClick(tab.id)}
              className={`${activeTab === tab.id
                ? "text-primary border-b-2 border-transparent border-primary"
                : "text-gray-500 hover:bg-gray-100"
                } py-2 px-4 inline-flex items-center border-b-2 border-transparent hover:border-primary hover:text-primary focus:outline-none font-medium`}
            >
              {tab.name}
            </button>
          ))}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default Tab;
