import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { AiFillHome } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import InputField from "../../../components/inputFields/InputField";
import Button from "../../../components/buttons/Button";
import { get_user_id } from "../../../constant/urls/localStoarege";
import Select from "../../../components/inputFields/SelectField";
import Toast, { showSuccessToast } from "../../../components/toastify/Toast";
import { useNavigate } from "react-router-dom";
import showAlert from "../../../components/errorBoundries/Errors";
import { addMembersApi } from "../../../services/apis/member";


function AddMember() {
    const navigate = useNavigate();
    const userId = get_user_id();
    const [error, setError] = useState();

    const options = [
        { value: "admin", label: "Admin" },
        { value: "rnd", label: "RnD" },
        { value: "production", label: "Production" },
        { value: "service", label: "Service" },
        { value: "marketing", label: "Marketing" },
        { value: "sales", label: "Sales" },
    ];
    const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
        if (values.password === values.rPassword) {
            try {
                const payload = {
                    name: values.name,
                    email: values.email,
                    phone: values.phone,
                    password: values.password,
                    role: values.role,
                    is_active: true,
                    is_dashboard_user: true,
                    created_by: userId,
                };

                const response = await addMembersApi(payload);

                if (response?.status === 201) {
                    showSuccessToast("Member created successfully!");
                    navigate("/members");
                    if (typeof resetForm === "function") {
                        resetForm();
                    }
                } else {
                    let errors = "Opps! Something went wrong, please try again.";
                    showAlert("Error", errors, "error");
                }
            } catch (error) {
                setError(
                    error?.response?.data?.error ||
                    "Opps! Something went wrong, please try again."
                );
                let errors =
                    error?.response?.data?.error ||
                    "Opps! Something went wrong, please try again.";
                showAlert("Error", errors, "error");
            }
            setSubmitting(false);

        } else {
            showAlert("Error", "Recheck Password", "error");
        }
    };

    const handleDiscardChanges = (resetForm) => {
        navigate("/members");
    };

    return (
        <div className="pl-5 ">
            {/* Top Section Begin */}
            <Toast />
            <div className="flex gap-3 items-center font-medium text-gray-500">
                <AiFillHome />
                <p>Home</p>
                <IoIosArrowForward />
                <p>Members</p>
                <IoIosArrowForward />
                <p>Add Member</p>
            </div>
            {/* Top Section End */}
            {/* Form Begin */}
            <div className="mt-8">
                <Formik
                    validationSchema={Yup.object({
                        name: Yup.string().required("Please enter Name"),
                        email: Yup.string().required("Please enter Email"),
                        password: Yup.string().required("Password Required"),
                        rPassword: Yup.string().required("Please Repeat Password"),
                        role: Yup.string().required("Please Select Role"),
                    })}
                    onSubmit={handleFormSubmit}
                    initialValues={{
                        name: '',
                        email: '',
                        phone: '',
                        password: '',
                        rPassword: '',
                        role: '',
                    }}
                >
                    {({ isSubmitting, resetForm }) => (
                        <Form>
                            <div className="border border-gray-300 mt-5 p-4 rounded-lg bg-gray-100">
                                <div className="w-full mb-1">
                                    <InputField
                                        label="Name"
                                        name="name"
                                        type="text"
                                        placeholder="Enter Name"
                                        required
                                    />
                                </div>
                                <div className="w-full mb-1">
                                    <div className="grid gap-6 mb-1 md:grid-cols-2">
                                        <InputField
                                            label="Email"
                                            name="email"
                                            type="email"
                                            placeholder="Enter your Email"
                                            required
                                        />
                                        <InputField
                                            label="Phone No."
                                            name="phone"
                                            type="number"
                                            placeholder="XXXXXXXXXX"
                                            false
                                        />
                                    </div>
                                </div>
                                <div className="w-full mb-1">
                                    <div className="grid gap-6 mb-1 md:grid-cols-2">
                                        <InputField
                                            label="Password"
                                            name="password"
                                            type="password"
                                            placeholder="**********"
                                            required
                                        />
                                        <InputField
                                            label="Repeat Password"
                                            name="rPassword"
                                            type="password"
                                            placeholder="**********"
                                            required
                                        />

                                    </div>
                                </div>
                                <div className="w-full mb-1 flex gap-3 items-center mt-6">
                                    <Select
                                        label="Role"
                                        name="role"
                                        options={options}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="py-5 flex justify-end gap-6">
                                <div>
                                    <Button
                                        type="button"
                                        className="text-primary bg-white hover:bg-white-dark font-sm rounded-lg text-lg w-full px-8 py-1.5 text-center border border-primary"
                                        onClick={() => handleDiscardChanges(resetForm)}
                                        disabled={isSubmitting}
                                    >
                                        Discard Changes
                                    </Button>
                                </div>
                                <div>
                                    <Button
                                        type="submit"
                                        className="text-white bg-primary hover:bg-primary-dark font-sm rounded-lg text-lg w-full px-8 py-1.5 text-center"
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? "Submitting..." : "Submit"}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            {/* Form End */}
        </div>
    );
}

export default AddMember;