import React from "react";

const Card = ({ title, count, icon: Icon, TextColor: TextColor, bgColor: BgColor }) => {
  return (
    <div className={`flex justify-between px-4 py-5 ${BgColor ? BgColor : "bg-white"} shadow rounded-lg`}>
      <div>
        <h3 className={`text-md font-semibold ${TextColor ? TextColor : "text-[#6B7280]"}`}>{title}</h3>
        <p className={`text-2xl font-bold ${TextColor}`}>{count}</p>
      </div>
      <div className="text-blue-500">{Icon}</div>
    </div>
  );
};

export default Card;
