import React, { useState } from "react";
import Modal from "react-modal";
import PDFViewer from "../PdfViewer";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
  },
};

Modal.setAppElement("#root");

const ViewPdfModal = ({ isOpen, onRequestClose, contentLabel, pdfUrl }) => {

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel={contentLabel}
    >
      <div className="max-h-96">
        <PDFViewer pdfUrl={pdfUrl} />
      </div>
    </Modal>
  );
};

export default ViewPdfModal;
