import { AiFillHome } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import Table from "../../components/tables/Table";
import Pagination from "../../components/pagination/Pagination";
import { useEffect, useState } from "react";
import { deleteUsersApi, getPerformanceReportApi } from "../../services/apis/performance_report";
import showAlert from "../../components/errorBoundries/Errors";
import ViewPdfModal from "../../components/Performance_Report/ViewPdfModal";
import { MdDelete } from "react-icons/md";
import Toast, { showSuccessToast } from "../../components/toastify/Toast";
import ConfirmModal from "../../components/popups/confirmationModal/ConfirmModal";

function PerformanceReport() {
    const headers = ["Device", "generated by", "Geberated On"];
    const keys = ["device", "generated_by", "created_at"];
    const [modalIsOpen, setIsOpen] = useState(false);
    const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState({});
    const [pdfToDisplay, setPdfToDisplay] = useState({});
    const [faqsId, setFaqsId] = useState("");
    const [isConfirmDelete, setIsConfirmDelete] = useState(false);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const fetchData = async () => {
        try {
            let respones = await getPerformanceReportApi();
            if (respones?.status === 200) {
                setData(respones?.data);
            } else {
                let errors = "Opps! Something went wrong, please try again.";
                showAlert("Error", errors, "error");
            }
        } catch (error) {
            let errors =
                error?.response?.data?.detail ||
                "Opps! Something went wrong, please try again.";
            showAlert("Error", errors, "error");
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleSearch = (query) => {
        console.log("Search query:", query);
    };

    const openModal = (row) => {
        setPdfToDisplay(row?.file);
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const handleDownload = (url) => {
        window.open(url, "_blank");
    };

    const extraColumnRenderer = (row) => (
        <div className="flex space-x-2">
            <div onClick={() => handleDownload(row?.file)}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 6C0 2.68629 2.68629 0 6 0H14C17.3137 0 20 2.68629 20 6V14C20 17.3137 17.3137 20 14 20H6C2.68629 20 0 17.3137 0 14V6Z" fill="#DEF7EC" />
                    <path d="M9.99967 12.333V5.33301V12.333Z" fill="#046C4E" />
                    <path d="M5.33301 12.333V12.9163C5.33301 13.3805 5.51738 13.8256 5.84557 14.1538C6.17376 14.482 6.61888 14.6663 7.08301 14.6663H12.9163C13.3805 14.6663 13.8256 14.482 14.1538 14.1538C14.482 13.8256 14.6663 13.3805 14.6663 12.9163V12.333M12.333 9.99967L9.99967 12.333M9.99967 12.333L7.66634 9.99967M9.99967 12.333V5.33301" stroke="#1A8044" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </div>
            <div onClick={() => openModal(row)} className="mx-10">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 6C0 2.68629 2.68629 0 6 0H14C17.3137 0 20 2.68629 20 6V14C20 17.3137 17.3137 20 14 20H6C2.68629 20 0 17.3137 0 14V6Z" fill="#E1EFFE" />
                    <path d="M9.99961 11.3996C10.3709 11.3996 10.727 11.2521 10.9896 10.9896C11.2521 10.727 11.3996 10.3709 11.3996 9.99961C11.3996 9.62831 11.2521 9.27221 10.9896 9.00966C10.727 8.74711 10.3709 8.59961 9.99961 8.59961C9.62831 8.59961 9.27221 8.74711 9.00966 9.00966C8.74711 9.27221 8.59961 9.62831 8.59961 9.99961C8.59961 10.3709 8.74711 10.727 9.00966 10.9896C9.27221 11.2521 9.62831 11.3996 9.99961 11.3996Z" fill="#1A56DB" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.32031 9.99961C4.21211 7.15971 6.86511 5.09961 9.99971 5.09961C13.1343 5.09961 15.7873 7.15971 16.6791 9.99961C15.7873 12.8395 13.1343 14.8996 9.99971 14.8996C6.86511 14.8996 4.21211 12.8395 3.32031 9.99961ZM12.7997 9.99961C12.7997 10.7422 12.5047 11.4544 11.9796 11.9795C11.4545 12.5046 10.7423 12.7996 9.99971 12.7996C9.25711 12.7996 8.54492 12.5046 8.01981 11.9795C7.49471 11.4544 7.19971 10.7422 7.19971 9.99961C7.19971 9.257 7.49471 8.54481 8.01981 8.01971C8.54492 7.49461 9.25711 7.19961 9.99971 7.19961C10.7423 7.19961 11.4545 7.49461 11.9796 8.01971C12.5047 8.54481 12.7997 9.257 12.7997 9.99961Z" fill="#1A56DB" />
                </svg>
            </div>
            <div>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 6C0 2.68629 2.68629 0 6 0H14C17.3137 0 20 2.68629 20 6V14C20 17.3137 17.3137 20 14 20H6C2.68629 20 0 17.3137 0 14V6Z" fill="#F3F4F6" />
                    <path d="M12.4134 9.97982C12.4134 9.69372 12.3034 9.42927 12.1118 9.25437L9.31631 6.69999C9.08258 6.48602 8.78097 6.44034 8.51001 6.58098C8.21551 6.73124 8.03269 7.06061 8.03269 7.43866V8.17011C6.19894 8.3396 4.75 10.198 4.75 12.4579V13.2566C4.75 13.6293 4.96841 13.9418 5.28151 14.0157C5.32804 14.026 5.37348 14.0314 5.41836 14.0314C5.66906 14.0314 5.89678 13.8673 6.00954 13.592C6.40859 12.6201 7.16781 11.9813 8.03269 11.8695V12.5216C8.03269 12.8996 8.21551 13.2284 8.51001 13.3799C8.78097 13.5193 9.08203 13.4748 9.31576 13.2614L12.1118 10.7065C12.3034 10.531 12.4134 10.2659 12.4134 9.97982Z" fill="#6B7280" />
                    <path d="M14.88 9.04581L11.86 6.07131C11.634 5.84833 11.2891 5.86996 11.0866 6.11699C10.8846 6.36401 10.9037 6.74446 11.1287 6.96564L14.1492 9.97922L11.0778 13.0373C10.8539 13.2602 10.837 13.6407 11.04 13.8865C11.1479 14.0175 11.2968 14.0837 11.4457 14.0837C11.5765 14.0837 11.7084 14.032 11.8129 13.928L14.8843 10.8699C15.1175 10.6379 15.2505 10.3056 15.25 9.95698C15.2489 9.60898 15.1142 9.27661 14.88 9.04581Z" fill="#6B7280" />
                </svg>
            </div>
            <MdDelete
                size={20}
                onClick={() => openConfirmationModal(row)}
                className="cursor-pointer text-red-600"
            />
        </div>
    );
    const pageSize = 10;

    const closeCofirmationModal = () => {
        setConfirmationModalIsOpen(false);
        setIsConfirmDelete(false);
    };

    const openConfirmationModal = (row) => {
        setConfirmationModalIsOpen(true);
        setFaqsId(row?.id);
    };

    useEffect(() => {
        if (isConfirmDelete === true) {
            const fetchData2 = async () => {
                try {
                    const respones = await deleteUsersApi(faqsId);
                    if (respones?.status === 204) {
                        fetchData("");
                        closeCofirmationModal();
                        showSuccessToast("Report deleted successfully!");
                    } else {
                        let errors = "Opps! Something went wrong, please try again.";
                        showAlert("Error", errors, "error");
                    }
                } catch (error) {
                    let errors =
                        error?.response?.data?.detail ||
                        "Opps! Something went wrong, please try again.";
                    showAlert("Error", errors, "error");
                }
            };
            fetchData2();
        }
    }, [isConfirmDelete]);

    return (<>
        <div className="flex gap-3 items-center font-medium text-gray-500">
            <Toast />
            <AiFillHome />
            <p>Home</p>
            <IoIosArrowForward />
            <p>Reports</p>
            <IoIosArrowForward />
            <p>Performance Report</p>
        </div>
        <div className="w-[30%] my-4">
            {/* <SearchBar placeholder="Search in Reports..." onSearch={handleSearch} /> */}
        </div>
        <Table
            headers={headers}
            keys={keys}
            rows={data?.results}
            extraColumn={"Actions"}
            extraColumnRenderer={extraColumnRenderer}
        />
        <div className="xl:pl-5 pl-0">
            <Pagination
                currentPage={currentPage}
                totalPages={Math.ceil(data?.count / pageSize)}
                pageSize={pageSize}
                totalEntries={data?.count}
                onPageChange={handlePageChange}
                data={data}
                setData={setData}
            />
        </div>
        <ViewPdfModal contentLabel={"PDF"} isOpen={modalIsOpen} onRequestClose={closeModal} pdfUrl={pdfToDisplay} />
        <ConfirmModal
            isOpen={confirmationModalIsOpen}
            onRequestClose={closeCofirmationModal}
            contentLabel="Add Device Type Modal"
            onClose={closeCofirmationModal}
            setIsConfirmDelete={setIsConfirmDelete}
        />
    </>);
}

export default PerformanceReport;