import {
  getFaqsApiUrl,
  addFaqsApiUrl,
  editFaqsApiUrl,
  deleteFaqsApiUrl,
  getFaqsTypeApiUrl,
} from "../../constant/urls/apiUrls";
import axios from "axios";
import { get_token } from "../../constant/urls/localStoarege";

export const getFaqsApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  // const response = await axios.get(`${getFaqsApiUrl}/faq_type=${data}`, config);
  const response = await axios.get(`${getFaqsApiUrl.replace("http://", "https://")}${data || ""}`, config);

  return response;
};

export const addFaqsTypeApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.post(getFaqsTypeApiUrl.replace("http://", "https://"), data, config);

  return response;
};

export const getFaqsTypeApi = async () => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.get(getFaqsTypeApiUrl.replace("http://", "https://"), config);

  return response;
};

export const addFaqsApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.post(addFaqsApiUrl, data, config);

  return response;
};

export const editFaqsApi = async (data, id) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.patch(`${editFaqsApiUrl}${id}/`, data, config);

  return response;
};

export const deleteFaqsApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.delete(`${deleteFaqsApiUrl}${data}/`, config);

  return response;
};
