import React, { useEffect } from "react";
import { FaTimes } from "react-icons/fa";

const ExpandableImageModal = ({ isOpen, onClose, imageUrl }) => {

    useEffect(() => {
        const handleEscape = (event) => {
            if (event.key === "Escape") {
                onClose();
            }
        };
        document.addEventListener("keydown", handleEscape);
        return () => {
            document.removeEventListener("keydown", handleEscape);
        };
    }, [onClose]);

    // Close the modal on clicking outside the image
    const handleClickOutside = (event) => {
        if (event.target.id === "modal-overlay") {
            onClose();
        }
    };

    if (!isOpen) return null;

    return (
        <div
            id="modal-overlay"
            className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50"
            onClick={handleClickOutside}
        >
            <div className="relative w-[80%] h-[80%] max-w-4xl">
                <button
                    onClick={onClose}
                    className="absolute top-4 right-4 bg-white p-2 rounded-full text-black text-2xl z-10"
                >
                    <FaTimes />
                </button>
                <img
                    src={imageUrl}
                    alt="Expanded"
                    className="w-full h-full object-contain max-h-[90vh] max-w-[90vw]"
                />
            </div>
        </div>
    );
};

export default ExpandableImageModal;
