import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AiFillHome } from "react-icons/ai";
import { IoIosArrowForward, IoMdCloudDownload } from "react-icons/io";
import SearchBar from "../../components/searchBar/SearchBar";
import showAlert from "../../components/errorBoundries/Errors";
import Toast from "../../components/toastify/Toast";
import Card from "../../components/cards/Card";
import Tab from "../marketing/faqs/Tabs";
import Table from "../../components/tables/Table";
import Pagination from "../../components/pagination/Pagination";
import { getServiceRequestApi } from "../../services/apis/service_request";
import { generateServiceRequestPdfApiUrl } from "../../constant/urls/apiUrls";


const ServiceRequest = () => {
  const navigate = useNavigate();
  const headers = [
    "Date (Issue Raised)",
    "Image",
    "User Name",
    "Title",
    "Ticket Status",
    "Description",
    // "Assigned To",
    "Feedback",
  ];

  const keys = [
    "created_at",
    "image",
    "user",
    "title",
    "status",
    "description",
    // "assigned_to",
    "feedback",
  ];
  const tabsList = [
    {
      id: "All", name: "All",
    },
    {
      id: "Opened", name: "Opened",
    },
    {
      id: "Pending", name: "Pending",
    },
    {
      id: "Closed", name: "Closed",
    },
  ];

  const [servicesData, setServicesData] = useState({});
  const [selectedTab, setSelectedTab] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [cardData, setCardsData] = useState([
    {
      title: "All Tickets",
      count: "--",
      icon: <svg width="40" height="40" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="48" height="48" rx="8" fill="#1C64F2" />
        <path d="M11.2002 17.6004C11.2002 16.7517 11.5373 15.9378 12.1375 15.3376C12.7376 14.7375 13.5515 14.4004 14.4002 14.4004H33.6002C34.4489 14.4004 35.2628 14.7375 35.8629 15.3376C36.4631 15.9378 36.8002 16.7517 36.8002 17.6004V20.8004C35.9515 20.8004 35.1376 21.1375 34.5375 21.7376C33.9373 22.3378 33.6002 23.1517 33.6002 24.0004C33.6002 24.8491 33.9373 25.663 34.5375 26.2631C35.1376 26.8632 35.9515 27.2004 36.8002 27.2004V30.4004C36.8002 31.2491 36.4631 32.063 35.8629 32.6631C35.2628 33.2632 34.4489 33.6004 33.6002 33.6004H14.4002C13.5515 33.6004 12.7376 33.2632 12.1375 32.6631C11.5373 32.063 11.2002 31.2491 11.2002 30.4004V27.2004C12.0489 27.2004 12.8628 26.8632 13.4629 26.2631C14.0631 25.663 14.4002 24.8491 14.4002 24.0004C14.4002 23.1517 14.0631 22.3378 13.4629 21.7376C12.8628 21.1375 12.0489 20.8004 11.2002 20.8004V17.6004Z" fill="white" />
      </svg>,
    },
    {
      title: "Open Tickets",
      count: "--",
      icon: <svg width="40" height="40" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="48" height="48" rx="8" fill="#CC0000" />
        <path d="M11.2 17.6004C11.2 16.7517 11.5371 15.9378 12.1372 15.3376C12.7373 14.7375 13.5513 14.4004 14.4 14.4004H33.5999C34.4486 14.4004 35.2626 14.7375 35.8627 15.3376C36.4628 15.9378 36.7999 16.7517 36.7999 17.6004V20.8004C35.9513 20.8004 35.1373 21.1375 34.5372 21.7376C33.9371 22.3378 33.5999 23.1517 33.5999 24.0004C33.5999 24.8491 33.9371 25.663 34.5372 26.2631C35.1373 26.8632 35.9513 27.2004 36.7999 27.2004V30.4004C36.7999 31.2491 36.4628 32.063 35.8627 32.6631C35.2626 33.2632 34.4486 33.6004 33.5999 33.6004H14.4C13.5513 33.6004 12.7373 33.2632 12.1372 32.6631C11.5371 32.063 11.2 31.2491 11.2 30.4004V27.2004C12.0486 27.2004 12.8626 26.8632 13.4627 26.2631C14.0628 25.663 14.4 24.8491 14.4 24.0004C14.4 23.1517 14.0628 22.3378 13.4627 21.7376C12.8626 21.1375 12.0486 20.8004 11.2 20.8004V17.6004Z" fill="white" />
      </svg>,
      TextColor: "text-[#CC0000]",
      BgColor: "bg-[#FFD8D8]",
    },
    {
      title: "Ongoing Tickets",
      count: "--",
      icon: <svg width="40" height="40" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="48" height="48" rx="8" fill="#723B13" />
        <path d="M11.2 17.6004C11.2 16.7517 11.5371 15.9378 12.1372 15.3376C12.7373 14.7375 13.5513 14.4004 14.4 14.4004H33.5999C34.4486 14.4004 35.2626 14.7375 35.8627 15.3376C36.4628 15.9378 36.7999 16.7517 36.7999 17.6004V20.8004C35.9513 20.8004 35.1373 21.1375 34.5372 21.7376C33.9371 22.3378 33.5999 23.1517 33.5999 24.0004C33.5999 24.8491 33.9371 25.663 34.5372 26.2631C35.1373 26.8632 35.9513 27.2004 36.7999 27.2004V30.4004C36.7999 31.2491 36.4628 32.063 35.8627 32.6631C35.2626 33.2632 34.4486 33.6004 33.5999 33.6004H14.4C13.5513 33.6004 12.7373 33.2632 12.1372 32.6631C11.5371 32.063 11.2 31.2491 11.2 30.4004V27.2004C12.0486 27.2004 12.8626 26.8632 13.4627 26.2631C14.0628 25.663 14.4 24.8491 14.4 24.0004C14.4 23.1517 14.0628 22.3378 13.4627 21.7376C12.8626 21.1375 12.0486 20.8004 11.2 20.8004V17.6004Z" fill="white" />
      </svg>,
      TextColor: "text-[#723B13]",
      BgColor: "bg-[#FDF6B2]",
    },
    {
      title: "Closed Tickets",
      count: "--",
      icon: <svg width="40" height="40" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="48" height="48" rx="8" fill="#1A8044" />
        <path d="M11.2 17.6004C11.2 16.7517 11.5371 15.9378 12.1372 15.3376C12.7373 14.7375 13.5513 14.4004 14.4 14.4004H33.5999C34.4486 14.4004 35.2626 14.7375 35.8627 15.3376C36.4628 15.9378 36.7999 16.7517 36.7999 17.6004V20.8004C35.9513 20.8004 35.1373 21.1375 34.5372 21.7376C33.9371 22.3378 33.5999 23.1517 33.5999 24.0004C33.5999 24.8491 33.9371 25.663 34.5372 26.2631C35.1373 26.8632 35.9513 27.2004 36.7999 27.2004V30.4004C36.7999 31.2491 36.4628 32.063 35.8627 32.6631C35.2626 33.2632 34.4486 33.6004 33.5999 33.6004H14.4C13.5513 33.6004 12.7373 33.2632 12.1372 32.6631C11.5371 32.063 11.2 31.2491 11.2 30.4004V27.2004C12.0486 27.2004 12.8626 26.8632 13.4627 26.2631C14.0628 25.663 14.4 24.8491 14.4 24.0004C14.4 23.1517 14.0628 22.3378 13.4627 21.7376C12.8626 21.1375 12.0486 20.8004 11.2 20.8004V17.6004Z" fill="white" />
      </svg>,
      TextColor: "text-[#1A8044]",
      BgColor: "bg-[#DAEDE2]",
    },
  ]);

  useEffect(() => {
    fetchData("All", "");
  }, []);

  const fetchData = async (tab, query) => {
    try {
      let respones = {};
      if (tab === "All") {
        respones = await getServiceRequestApi(`?search=${query ?? ""}`);
      } else {
        respones = await getServiceRequestApi(`?status=${tab}&search=${query ?? ""}`);
      }
      if (respones?.status === 200) {
        setServicesData(respones?.data);
        setCardsData(
          [
            {
              title: "All Tickets",
              count: respones?.data?.extra_data?.all_tickets || 0,
              icon: <svg width="40" height="40" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="48" height="48" rx="8" fill="#1C64F2" />
                <path d="M11.2002 17.6004C11.2002 16.7517 11.5373 15.9378 12.1375 15.3376C12.7376 14.7375 13.5515 14.4004 14.4002 14.4004H33.6002C34.4489 14.4004 35.2628 14.7375 35.8629 15.3376C36.4631 15.9378 36.8002 16.7517 36.8002 17.6004V20.8004C35.9515 20.8004 35.1376 21.1375 34.5375 21.7376C33.9373 22.3378 33.6002 23.1517 33.6002 24.0004C33.6002 24.8491 33.9373 25.663 34.5375 26.2631C35.1376 26.8632 35.9515 27.2004 36.8002 27.2004V30.4004C36.8002 31.2491 36.4631 32.063 35.8629 32.6631C35.2628 33.2632 34.4489 33.6004 33.6002 33.6004H14.4002C13.5515 33.6004 12.7376 33.2632 12.1375 32.6631C11.5373 32.063 11.2002 31.2491 11.2002 30.4004V27.2004C12.0489 27.2004 12.8628 26.8632 13.4629 26.2631C14.0631 25.663 14.4002 24.8491 14.4002 24.0004C14.4002 23.1517 14.0631 22.3378 13.4629 21.7376C12.8628 21.1375 12.0489 20.8004 11.2002 20.8004V17.6004Z" fill="white" />
              </svg>,
            },
            {
              title: "Open Tickets",
              count: respones?.data?.extra_data?.open_tickets || 0,
              icon: <svg width="40" height="40" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="48" height="48" rx="8" fill="#CC0000" />
                <path d="M11.2 17.6004C11.2 16.7517 11.5371 15.9378 12.1372 15.3376C12.7373 14.7375 13.5513 14.4004 14.4 14.4004H33.5999C34.4486 14.4004 35.2626 14.7375 35.8627 15.3376C36.4628 15.9378 36.7999 16.7517 36.7999 17.6004V20.8004C35.9513 20.8004 35.1373 21.1375 34.5372 21.7376C33.9371 22.3378 33.5999 23.1517 33.5999 24.0004C33.5999 24.8491 33.9371 25.663 34.5372 26.2631C35.1373 26.8632 35.9513 27.2004 36.7999 27.2004V30.4004C36.7999 31.2491 36.4628 32.063 35.8627 32.6631C35.2626 33.2632 34.4486 33.6004 33.5999 33.6004H14.4C13.5513 33.6004 12.7373 33.2632 12.1372 32.6631C11.5371 32.063 11.2 31.2491 11.2 30.4004V27.2004C12.0486 27.2004 12.8626 26.8632 13.4627 26.2631C14.0628 25.663 14.4 24.8491 14.4 24.0004C14.4 23.1517 14.0628 22.3378 13.4627 21.7376C12.8626 21.1375 12.0486 20.8004 11.2 20.8004V17.6004Z" fill="white" />
              </svg>,
              TextColor: "text-[#CC0000]",
              BgColor: "bg-[#FFD8D8]",
            },
            {
              title: "Ongoing Tickets",
              count: respones?.data?.extra_data?.pending_tickets || 0,
              icon: <svg width="40" height="40" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="48" height="48" rx="8" fill="#723B13" />
                <path d="M11.2 17.6004C11.2 16.7517 11.5371 15.9378 12.1372 15.3376C12.7373 14.7375 13.5513 14.4004 14.4 14.4004H33.5999C34.4486 14.4004 35.2626 14.7375 35.8627 15.3376C36.4628 15.9378 36.7999 16.7517 36.7999 17.6004V20.8004C35.9513 20.8004 35.1373 21.1375 34.5372 21.7376C33.9371 22.3378 33.5999 23.1517 33.5999 24.0004C33.5999 24.8491 33.9371 25.663 34.5372 26.2631C35.1373 26.8632 35.9513 27.2004 36.7999 27.2004V30.4004C36.7999 31.2491 36.4628 32.063 35.8627 32.6631C35.2626 33.2632 34.4486 33.6004 33.5999 33.6004H14.4C13.5513 33.6004 12.7373 33.2632 12.1372 32.6631C11.5371 32.063 11.2 31.2491 11.2 30.4004V27.2004C12.0486 27.2004 12.8626 26.8632 13.4627 26.2631C14.0628 25.663 14.4 24.8491 14.4 24.0004C14.4 23.1517 14.0628 22.3378 13.4627 21.7376C12.8626 21.1375 12.0486 20.8004 11.2 20.8004V17.6004Z" fill="white" />
              </svg>,
              TextColor: "text-[#723B13]",
              BgColor: "bg-[#FDF6B2]",
            },
            {
              title: "Closed Tickets",
              count: respones?.data?.extra_data?.closed_tickets || 0,
              icon: <svg width="40" height="40" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="48" height="48" rx="8" fill="#1A8044" />
                <path d="M11.2 17.6004C11.2 16.7517 11.5371 15.9378 12.1372 15.3376C12.7373 14.7375 13.5513 14.4004 14.4 14.4004H33.5999C34.4486 14.4004 35.2626 14.7375 35.8627 15.3376C36.4628 15.9378 36.7999 16.7517 36.7999 17.6004V20.8004C35.9513 20.8004 35.1373 21.1375 34.5372 21.7376C33.9371 22.3378 33.5999 23.1517 33.5999 24.0004C33.5999 24.8491 33.9371 25.663 34.5372 26.2631C35.1373 26.8632 35.9513 27.2004 36.7999 27.2004V30.4004C36.7999 31.2491 36.4628 32.063 35.8627 32.6631C35.2626 33.2632 34.4486 33.6004 33.5999 33.6004H14.4C13.5513 33.6004 12.7373 33.2632 12.1372 32.6631C11.5371 32.063 11.2 31.2491 11.2 30.4004V27.2004C12.0486 27.2004 12.8626 26.8632 13.4627 26.2631C14.0628 25.663 14.4 24.8491 14.4 24.0004C14.4 23.1517 14.0628 22.3378 13.4627 21.7376C12.8626 21.1375 12.0486 20.8004 11.2 20.8004V17.6004Z" fill="white" />
              </svg>,
              TextColor: "text-[#1A8044]",
              BgColor: "bg-[#DAEDE2]",
            },
          ]
        );
      } else {
        let errors = "Opps! Something went wrong, please try again.";
        showAlert("Error", errors, "error");
      }
    } catch (error) {
      let errors =
        error?.response?.data?.detail ||
        "Opps! Something went wrong, please try again.";
      showAlert("Error", errors, "error");
    }
  };
  const handleTabChange = (value) => {
    setSelectedTab(value);
    fetchData(value);
  };

  const handleViewDetails = (rowData) => {
    navigate(`/service-request/${rowData.id}`, { state: { rowData } });
  };

  const handleDownload = (rowData) => {
    window.open(generateServiceRequestPdfApiUrl, "_blank");
  };

  const extraColumnRenderer = (row) => (
    <button
      className="text-primary bg-[#DEF7EC] px-3 py-1 rounded-lg hover:primary hover:text-primary"
      onClick={() => handleViewDetails(row)}
    >
      View More{">>"}
    </button>
  );
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleSearch = (query) => {
    fetchData(selectedTab, query);
  };

  return (
    <div className="">
      <Toast />
      {/* Top Section Begin */}
      <div className="flex gap-3 items-center font-medium text-gray-500">
        <AiFillHome />
        <p>Home</p>
        <IoIosArrowForward />
        <p>Service Requests</p>
      </div>
      {/* Top Section End */}
      {/* Add Faqs Begin */}
      <div className="flex justify-between pt-3">
        <div className="w-[30%]">
          <SearchBar placeholder="Search in Service Requests..." onSearch={handleSearch} />
        </div>
        <div>
          <button
            type="button"
            className="text-black border border-gray-300 hover:bg-primary-dark font-sm rounded-lg text-md w-full px-3 py-2.5 text-center flex items-center gap-1 font-semibold"
            onClick={handleDownload}
          >
            <IoMdCloudDownload style={{ TextColor: "black" }} size={23} />
            Download
          </button>
        </div>
      </div>
      {/* Add Faqs End */}
      {/* Card Section Begin */}
      <div className="py-3">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
          {cardData.map((card, index) => (
            <Card title={card.title} count={card.count} icon={card.icon} bgColor={card.BgColor} TextColor={card.TextColor} />
          ))}
        </div>
      </div>
      {/* Card Section End */}
      {/* Tabs Section Begin */}
      <div className="lg:pl-2">
        <Tab tabsList={tabsList} onClick={handleTabChange} activeTab={selectedTab} />
      </div>
      {/* Tabs Section End */}
      {/* Table Begin */}
      <div className="mt-2">
        <Table
          headers={headers}
          keys={keys}
          rows={servicesData?.results}
          extraColumn="Details"
          extraColumnRenderer={extraColumnRenderer}
        />
      </div>
      {/* Table End */}
      {/* Pagination Begin */}
      <div className="xl:pl-5 pl-0">
        <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil(servicesData?.count / pageSize)}
          pageSize={pageSize}
          totalEntries={servicesData?.count}
          onPageChange={handlePageChange}
          data={servicesData}
          setData={setServicesData}
        />
      </div>
      {/* Pagination End */}
    </div>
  );
};

export default ServiceRequest;
