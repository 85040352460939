import { getFeedbackApiUrl } from "../../constant/urls/apiUrls";
import axios from "axios";
import { get_token } from "../../constant/urls/localStoarege";

export const getFeedbackApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.get(`${getFeedbackApiUrl.replace("http://", "https://")}${data || ""}`, config);

  return response;
};
