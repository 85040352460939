export const BASE_URL = "https://hardware.ecosaras.in";
const loginApiUrl = `${BASE_URL}/dashboard/dashboard-login/`;
const addTutorialApiUrl = `${BASE_URL}/support/tutorial/`;
const getTutorialApiUrl = `${BASE_URL}/support/tutorial/`;
const editTutorialApiUrl = `${BASE_URL}/support/tutorial/`;
const deleteTutorialApiUrl = `${BASE_URL}/support/tutorial/`;

const dashboardGetView = `${BASE_URL}/dashboard/dashboard-home`;
const homeDeviceLog = `${BASE_URL}/dashboard/dashboard-home-device-log`;
const homeDeviceLogGraph = `${BASE_URL}/dashboard/chart?device_id=`;

const addFaqsTypesApiUrl = `${BASE_URL}/support/faq/`;
const getFaqsTypeApiUrl = `${BASE_URL}/support/faq-type/`;
const addFaqsApiUrl = `${BASE_URL}/support/faq/`;
const getFaqsApiUrl = `${BASE_URL}/dashboard/faq-dashboard`;
const editFaqsApiUrl = `${BASE_URL}/support/faq/`;
const deleteFaqsApiUrl = `${BASE_URL}/support/faq/`;

const addNotificationApiUrl = `${BASE_URL}/support/notification/`;
const getNotificationApiUrl = `${BASE_URL}/support/notification/`;
const editNotificationApiUrl = `${BASE_URL}/support/notification/`;
const deleteNotificationApiUrl = `${BASE_URL}/support/notification/`;

const addCommunityApiUrl = `${BASE_URL}/data/community/`;
const getCommunityApiUrl = `${BASE_URL}/dashboard/community-dashboard`;
const editCommunityApiUrl = `${BASE_URL}/data/community/`;
const deleteCommunityApiUrl = `${BASE_URL}/data/community/`;

const getFeedbackApiUrl = `${BASE_URL}/support/feedback/`;

const getCommodityApiUrl = `${BASE_URL}/dashboard/commodity-dashboard`;
const commodityCRUDApiUrl = `${BASE_URL}/data/commodity/`;
const getDeviceDropDownApiUrl = `${BASE_URL}/dashboard/device-dropdown`;

const getUserDropDownApiUrl = `${BASE_URL}/dashboard/user-dropdown`;

const serviceRequestGetApiUrl = `${BASE_URL}/dashboard/service-request-dashboard`;
const serviceRequestAddCommentApiUrl = `${BASE_URL}/data/service-request-comment/`;


const memberCRUDUrl = `${BASE_URL}/data/custom-users/`;
const getMembersApiUrl = `${BASE_URL}/dashboard/members-dashboard`;

const userCRUDUrl = `${BASE_URL}/data/custom-users/`;
const userChangePasswordUrl = `${BASE_URL}/dashboard/change-password/`;
const getUsersApiUrl = `${BASE_URL}/dashboard/users-dashboard`;

const devicesCRUDUrl = `${BASE_URL}/data/device/`;
const getDevicessApiUrl = `${BASE_URL}/dashboard/device-dashboard`;
const plantDropDownApiUrl = `${BASE_URL}/dashboard/plant-dropdown`;

const plantsCRUDUrl = `${BASE_URL}/data/plant/`;
const getPlantsApiUrl = `${BASE_URL}/dashboard/plant-dashboard`;
const changeDeviceStatusUrl = `${BASE_URL}/dashboard/change-device-status/`;

const getEnquiriesApiUrl = `${BASE_URL}/dashboard/enquiry-dashboard`;

const firmwareCRUDApiUrl = `${BASE_URL}/data/firmware/`;
const getFirmwaresApiUrl = `${BASE_URL}/dashboard/firmware-dashboard`;

const otherDevicesCRUDApiUrl = `${BASE_URL}/data/other-device/`;

const manualCRUDApiUrl = `${BASE_URL}/data/manual-report/`;
const manualReportGetApiUrl = `${BASE_URL}/dashboard/manual-report?report_type=`;
const dashboardDeviceLogApiUrl = `${BASE_URL}/dashboard/device-log-dashboard`;
const deviceLogCardApiUrl = `${BASE_URL}/dashboard/device-log-cards`;
const generatedeviceLogExcelApiUrl = `${BASE_URL}/dashboard/generate-device-log-excel?device_id=`;
// Exaple: /dashboard/generate-device-log-excel?device_id=1ffa1976-ed89-49c0-93b0-e021bbf5e86e&from_date=2024-08-15 01:08&to_date=2024-08-16
const generatedeviceLogPdfApiUrl = `${BASE_URL}/dashboard/generate-device-log-pdf?device_id=`;
// Exaple: /dashboard/generate-device-log-pdf?device_id=e8dd14fb-a891-4fa8-9989-ed57975e29cb&from_date=2024-08-15 01:08&to_date=2024-08-16
const generatedeviceLogReportApiUrl = `${BASE_URL}/dashboard/generate-report-data/`;
const performanceReportApiUrl = `${BASE_URL}/dashboard/performance-report`;
const performanceCRUDApiUrl = `${BASE_URL}/data/performance-report/`;
const generatePlantPdfApiUrl = `${BASE_URL}/dashboard/generate-plant-pdf`;
const generatePlantExcelApiUrl = `${BASE_URL}/dashboard/generate-plant-excel`;
const generateDevicePdfApiUrl = `${BASE_URL}/dashboard/generate-device-pdf`;
const generateDeviceExcelApiUrl = `${BASE_URL}/dashboard/generate-device-excel`;
const generateServiceRequestPdfApiUrl = `${BASE_URL}/dashboard/generate-service-request-pdf`;

export {
  loginApiUrl,
  addTutorialApiUrl,
  getTutorialApiUrl,
  editTutorialApiUrl,
  deleteTutorialApiUrl,
  addFaqsTypesApiUrl,
  getFaqsTypeApiUrl,
  addFaqsApiUrl,
  getFaqsApiUrl,
  editFaqsApiUrl,
  deleteFaqsApiUrl,
  addNotificationApiUrl,
  getNotificationApiUrl,
  editNotificationApiUrl,
  deleteNotificationApiUrl,
  addCommunityApiUrl,
  getCommunityApiUrl,
  editCommunityApiUrl,
  deleteCommunityApiUrl,
  getFeedbackApiUrl,
  getCommodityApiUrl,
  commodityCRUDApiUrl,
  getDeviceDropDownApiUrl,
  serviceRequestGetApiUrl,
  memberCRUDUrl,
  getMembersApiUrl,
  userCRUDUrl,
  getUsersApiUrl,
  devicesCRUDUrl,
  getDevicessApiUrl,
  plantsCRUDUrl,
  getPlantsApiUrl,
  getUserDropDownApiUrl,
  plantDropDownApiUrl,
  getEnquiriesApiUrl,
  getFirmwaresApiUrl,
  firmwareCRUDApiUrl,
  otherDevicesCRUDApiUrl,
  dashboardGetView,
  homeDeviceLog,
  manualCRUDApiUrl,
  dashboardDeviceLogApiUrl,
  manualReportGetApiUrl,
  deviceLogCardApiUrl,
  generatedeviceLogExcelApiUrl,
  generatedeviceLogReportApiUrl,
  performanceReportApiUrl,
  serviceRequestAddCommentApiUrl,
  changeDeviceStatusUrl,
  generatePlantPdfApiUrl,
  generatePlantExcelApiUrl,
  generateDevicePdfApiUrl,
  generateDeviceExcelApiUrl,
  generatedeviceLogPdfApiUrl,
  generateServiceRequestPdfApiUrl,
  performanceCRUDApiUrl,
  homeDeviceLogGraph,
  userChangePasswordUrl,
};
