import React, { useEffect, useState } from "react";
import { AiFillHome } from "react-icons/ai";
import { IoIosArrowForward, IoMdAdd } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import SearchBar from "../../../components/searchBar/SearchBar";
import Card from "../../../components/cards/Card";
import Table from "../../../components/tables/Table";
import Pagination from "../../../components/pagination/Pagination";
import Tab from "../../marketing/faqs/Tabs";
import Toast, { showSuccessToast } from "../../../components/toastify/Toast";
import showAlert from "../../../components/errorBoundries/Errors";
import { deleteUsersApi, getUserersApi } from "../../../services/apis/user";
import { MdDelete } from "react-icons/md";
import ConfirmModal from "../../../components/popups/confirmationModal/ConfirmModal";
import { FiRefreshCcw } from "react-icons/fi";
import { BsFillPassFill, BsLock } from "react-icons/bs";


const Users = () => {
  const navigate = useNavigate();
  const headers = ["Date", "Name", "Image", "Email", "Username", "Phone", "Role"];
  const keys = ["created_at", "name", "image", "email", "username", "phone", "role"];
  const [handleEditModal, setHandleEditModal] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [membersData, setMembersData] = useState({});
  const [faqsId, setFaqsId] = useState("");
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const availableIcons = [
    <svg width="40" height="40" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="8" fill="#1C64F2" />
      <path d="M22.4001 17.6008C22.4001 18.8738 21.8944 20.0947 20.9942 20.9949C20.094 21.8951 18.8731 22.4008 17.6001 22.4008C16.3271 22.4008 15.1062 21.8951 14.206 20.9949C13.3058 20.0947 12.8001 18.8738 12.8001 17.6008C12.8001 16.3277 13.3058 15.1068 14.206 14.2067C15.1062 13.3065 16.3271 12.8008 17.6001 12.8008C18.8731 12.8008 20.094 13.3065 20.9942 14.2067C21.8944 15.1068 22.4001 16.3277 22.4001 17.6008ZM35.2001 17.6008C35.2001 18.2311 35.0759 18.8553 34.8347 19.4377C34.5935 20.02 34.2399 20.5492 33.7942 20.9949C33.3485 21.4406 32.8193 21.7942 32.237 22.0354C31.6546 22.2766 31.0304 22.4008 30.4001 22.4008C29.7698 22.4008 29.1456 22.2766 28.5632 22.0354C27.9809 21.7942 27.4517 21.4406 27.006 20.9949C26.5603 20.5492 26.2067 20.02 25.9655 19.4377C25.7243 18.8553 25.6001 18.2311 25.6001 17.6008C25.6001 16.3277 26.1058 15.1068 27.006 14.2067C27.9062 13.3065 29.1271 12.8008 30.4001 12.8008C31.6731 12.8008 32.894 13.3065 33.7942 14.2067C34.6944 15.1068 35.2001 16.3277 35.2001 17.6008ZM28.6881 35.2008C28.7617 34.6776 28.8001 34.1448 28.8001 33.6008C28.8038 31.087 27.9581 28.6456 26.4001 26.6728C27.6162 25.9706 28.9958 25.601 30.4 25.601C31.8043 25.601 33.1839 25.9706 34.4 26.6727C35.6162 27.3749 36.6261 28.3847 37.3282 29.6009C38.0304 30.817 38.4001 32.1965 38.4001 33.6008V35.2008H28.6881ZM17.6001 25.6008C19.7218 25.6008 21.7567 26.4436 23.257 27.9439C24.7572 29.4442 25.6001 31.4791 25.6001 33.6008V35.2008H9.6001V33.6008C9.6001 31.4791 10.443 29.4442 11.9432 27.9439C13.4435 26.4436 15.4784 25.6008 17.6001 25.6008Z" fill="white" />
    </svg>,
    <svg width="40" height="40" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="8" fill="#1A8044" />
      <path d="M22.4001 17.6008C22.4001 18.8738 21.8944 20.0947 20.9942 20.9949C20.094 21.8951 18.8731 22.4008 17.6001 22.4008C16.3271 22.4008 15.1062 21.8951 14.206 20.9949C13.3058 20.0947 12.8001 18.8738 12.8001 17.6008C12.8001 16.3277 13.3058 15.1068 14.206 14.2067C15.1062 13.3065 16.3271 12.8008 17.6001 12.8008C18.8731 12.8008 20.094 13.3065 20.9942 14.2067C21.8944 15.1068 22.4001 16.3277 22.4001 17.6008V17.6008ZM35.2001 17.6008C35.2001 18.2311 35.0759 18.8553 34.8347 19.4377C34.5935 20.02 34.2399 20.5492 33.7942 20.9949C33.3485 21.4406 32.8193 21.7942 32.237 22.0354C31.6546 22.2766 31.0304 22.4008 30.4001 22.4008C29.7698 22.4008 29.1456 22.2766 28.5632 22.0354C27.9809 21.7942 27.4517 21.4406 27.006 20.9949C26.5603 20.5492 26.2067 20.02 25.9655 19.4377C25.7243 18.8553 25.6001 18.2311 25.6001 17.6008C25.6001 16.3277 26.1058 15.1068 27.006 14.2067C27.9062 13.3065 29.1271 12.8008 30.4001 12.8008C31.6731 12.8008 32.894 13.3065 33.7942 14.2067C34.6944 15.1068 35.2001 16.3277 35.2001 17.6008V17.6008ZM28.6881 35.2008C28.7617 34.6776 28.8001 34.1448 28.8001 33.6008C28.8038 31.087 27.9581 28.6456 26.4001 26.6728C27.6162 25.9706 28.9958 25.601 30.4 25.601C31.8043 25.601 33.1839 25.9706 34.4 26.6727C35.6162 27.3749 36.6261 28.3847 37.3282 29.6009C38.0304 30.817 38.4001 32.1965 38.4001 33.6008V35.2008H28.6881ZM17.6001 25.6008C19.7218 25.6008 21.7567 26.4436 23.257 27.9439C24.7572 29.4442 25.6001 31.4791 25.6001 33.6008V35.2008H9.6001V33.6008C9.6001 31.4791 10.443 29.4442 11.9432 27.9439C13.4435 26.4436 15.4784 25.6008 17.6001 25.6008V25.6008Z" fill="white" />
    </svg>,
    <svg width="40" height="40" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="8" fill="#63C700" />
      <path d="M27.6 19.1996C27.6 20.1544 27.2207 21.0701 26.5455 21.7452C25.8704 22.4203 24.9547 22.7996 24 22.7996C23.0452 22.7996 22.1295 22.4203 21.4544 21.7452C20.7792 21.0701 20.4 20.1544 20.4 19.1996C20.4 18.2448 20.7792 17.3292 21.4544 16.654C22.1295 15.9789 23.0452 15.5996 24 15.5996C24.9547 15.5996 25.8704 15.9789 26.5455 16.654C27.2207 17.3292 27.6 18.2448 27.6 19.1996V19.1996ZM33.6 21.5996C33.6 22.2361 33.3471 22.8466 32.897 23.2967C32.4469 23.7468 31.8365 23.9996 31.2 23.9996C30.5634 23.9996 29.953 23.7468 29.5029 23.2967C29.0528 22.8466 28.8 22.2361 28.8 21.5996C28.8 20.9631 29.0528 20.3526 29.5029 19.9026C29.953 19.4525 30.5634 19.1996 31.2 19.1996C31.8365 19.1996 32.4469 19.4525 32.897 19.9026C33.3471 20.3526 33.6 20.9631 33.6 21.5996V21.5996ZM28.8 29.9996C28.8 28.7266 28.2942 27.5057 27.3941 26.6055C26.4939 25.7053 25.273 25.1996 24 25.1996C22.7269 25.1996 21.506 25.7053 20.6058 26.6055C19.7057 27.5057 19.2 28.7266 19.2 29.9996V33.5996H28.8V29.9996ZM19.2 21.5996C19.2 22.2361 18.9471 22.8466 18.497 23.2967C18.0469 23.7468 17.4365 23.9996 16.8 23.9996C16.1634 23.9996 15.553 23.7468 15.1029 23.2967C14.6528 22.8466 14.4 22.2361 14.4 21.5996C14.4 20.9631 14.6528 20.3526 15.1029 19.9026C15.553 19.4525 16.1634 19.1996 16.8 19.1996C17.4365 19.1996 18.0469 19.4525 18.497 19.9026C18.9471 20.3526 19.2 20.9631 19.2 21.5996V21.5996ZM31.2 33.5996V29.9996C31.2017 28.7795 30.8919 27.5792 30.3 26.5124C30.832 26.3763 31.3881 26.3634 31.9258 26.4749C32.4635 26.5863 32.9687 26.8191 33.4028 27.1555C33.8369 27.4919 34.1884 27.923 34.4305 28.4159C34.6726 28.9088 34.799 29.4504 34.8 29.9996V33.5996H31.2ZM17.7 26.5124C17.108 27.5793 16.7983 28.7796 16.8 29.9996V33.5996H13.2V29.9996C13.1997 29.4501 13.3253 28.9077 13.5671 28.4142C13.8089 27.9207 14.1604 27.4891 14.5948 27.1525C15.0292 26.8159 15.5349 26.5832 16.0731 26.4722C16.6114 26.3613 17.1679 26.375 17.7 26.5124V26.5124Z" fill="white" />
    </svg>,
    <svg width="40" height="40" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="8" fill="#C70048" />
      <g clip-path="url(#clip0_1032_6698)">
        <path d="M35.9999 35.9993V34.666C35.9999 33.6051 35.5785 32.5877 34.8283 31.8376C34.0782 31.0874 33.0608 30.666 31.9999 30.666H21.3333C20.2724 30.666 19.255 31.0874 18.5048 31.8376C17.7547 32.5877 17.3333 33.6051 17.3333 34.666V35.9993H35.9999Z" fill="white" />
        <path d="M26.6666 34.666C27.1779 34.666 29.9999 32.666 29.9999 30.666H23.3333C23.3333 32.666 26.1553 34.666 26.6666 34.666Z" fill="white" />
        <path d="M23.76 30.6927C23.76 32.59 29.5733 32.6674 29.5733 30.6927V28.1934H23.76V30.6927Z" fill="white" />
        <path d="M23.7546 29.3156C24.5653 30.2316 25.5706 30.4796 26.664 30.4796C27.7566 30.4796 28.762 30.2316 29.5733 29.3156V26.9883H23.7546V29.3156Z" fill="white" />
        <path d="M28.7679 14.1993C27.4846 13.784 24.8506 13.8927 24.0959 14.874C22.8472 14.898 23.0319 17.3473 20.6872 16.852C20.1952 17.5213 19.8479 18.3173 19.7379 19.1693C19.4392 21.486 19.8946 22.4187 20.1319 24.16C20.4006 26.1333 21.5099 26.7647 22.3966 27.0287C23.6726 28.7147 25.0292 28.6427 27.3072 28.6427C31.7552 28.6427 33.4879 25.6707 33.6752 20.616C33.7286 19.178 33.3599 17.9047 32.6192 16.85C30.0612 17.8433 30.4772 14.7527 28.7679 14.1993Z" fill="white" />
        <path d="M31.6981 20.8286C31.2674 20.2326 30.7168 19.7526 29.5088 19.5832C29.9621 19.7906 30.7581 21.0512 30.8148 21.4479C30.8714 21.8446 30.9281 22.1652 30.5694 21.7686C29.1328 20.1806 27.2061 20.2626 25.6561 19.2926C24.5734 18.6152 24.2434 17.8652 24.2434 17.8652C24.2434 17.8652 24.1114 18.8652 22.4694 19.8846C21.9934 20.1799 21.4254 20.8379 21.1108 21.8099C20.8841 22.5086 20.9548 23.1312 20.9548 24.1952C20.9548 27.3019 23.5154 29.9139 26.6734 29.9139C29.8314 29.9139 32.3921 27.2786 32.3921 24.1952C32.3908 22.2632 32.1888 21.5086 31.6981 20.8286Z" fill="white" />
        <path d="M27.3074 25.7844H26.0368C25.9524 25.7844 25.8715 25.7509 25.8119 25.6913C25.7523 25.6317 25.7188 25.5508 25.7188 25.4664C25.7188 25.3821 25.7523 25.3012 25.8119 25.2416C25.8715 25.1819 25.9524 25.1484 26.0368 25.1484H27.3074C27.3918 25.1484 27.4726 25.1819 27.5323 25.2416C27.5919 25.3012 27.6254 25.3821 27.6254 25.4664C27.6254 25.5508 27.5919 25.6317 27.5323 25.6913C27.4726 25.7509 27.3918 25.7844 27.3074 25.7844Z" fill="white" />
        <path d="M24.13 23.5603C23.9615 23.5603 23.7999 23.4934 23.6807 23.3742C23.5616 23.2551 23.4946 23.0935 23.4946 22.925V22.2896C23.4946 22.1211 23.5616 21.9595 23.6807 21.8404C23.7999 21.7212 23.9615 21.6543 24.13 21.6543C24.2985 21.6543 24.4601 21.7212 24.5792 21.8404C24.6984 21.9595 24.7653 22.1211 24.7653 22.2896V22.925C24.7653 23.0935 24.6984 23.2551 24.5792 23.3742C24.4601 23.4934 24.2985 23.5603 24.13 23.5603ZM29.214 23.5603C29.0455 23.5603 28.8839 23.4934 28.7647 23.3742C28.6456 23.2551 28.5786 23.0935 28.5786 22.925V22.2896C28.5786 22.1211 28.6456 21.9595 28.7647 21.8404C28.8839 21.7212 29.0455 21.6543 29.214 21.6543C29.3825 21.6543 29.5441 21.7212 29.6632 21.8404C29.7824 21.9595 29.8493 22.1211 29.8493 22.2896V22.925C29.8493 23.0935 29.7824 23.2551 29.6632 23.3742C29.5441 23.4934 29.3825 23.5603 29.214 23.5603Z" fill="white" />
        <path d="M26.756 28.4372C24.92 28.4372 24.356 27.9672 24.262 27.8719C24.2204 27.8324 24.187 27.7851 24.1637 27.7327C24.1404 27.6803 24.1276 27.6239 24.1261 27.5665C24.1231 27.4508 24.1662 27.3385 24.246 27.2546C24.3257 27.1706 24.4356 27.1217 24.5513 27.1187C24.6671 27.1157 24.7793 27.1588 24.8633 27.2386C24.898 27.2632 25.344 27.5632 26.756 27.5632C28.2226 27.5632 28.6466 27.2399 28.6506 27.2366C28.6909 27.1966 28.7388 27.1652 28.7914 27.144C28.844 27.1228 28.9004 27.1124 28.9571 27.1133C29.0138 27.1143 29.0698 27.1265 29.1217 27.1494C29.1736 27.1723 29.2204 27.2053 29.2593 27.2466C29.3401 27.3313 29.3844 27.4444 29.3827 27.5615C29.3809 27.6786 29.3333 27.7903 29.25 27.8726C29.1553 27.9672 28.592 28.4372 26.756 28.4372Z" fill="white" />
        <path d="M20.6667 30.666H22.6667V35.9993H20.6667V30.666ZM30.6667 30.666H32.6667V35.9993H30.6667V30.666Z" fill="white" />
        <path d="M20.75 35.334H32.6667V36.0007H20.75V35.334Z" fill="white" />
        <path d="M31.9999 17.8667C31.9999 18.7467 29.9386 19.3333 26.6666 19.3333C23.3939 19.3333 21.3333 18.7467 21.3333 17.8667C21.3333 15.502 23.9999 12 24.8886 12C25.7779 12 26.3706 13.1733 26.6666 13.1733C26.9626 13.1733 27.5553 12 28.4446 12C29.3333 12 31.9999 15.502 31.9999 17.8667Z" fill="white" />
        <path d="M35.9606 17.3324C35.9606 18.4371 33.6273 21.3324 26.6273 21.3324C19.6273 21.3324 17.2939 18.4371 17.2939 17.3324C17.2939 16.2278 21.8406 16.6658 26.6273 16.6658C31.4139 16.6658 35.9606 16.2278 35.9606 17.3324Z" fill="white" />
        <path d="M31.9999 17.866C31.9999 18.746 29.9386 19.3327 26.6666 19.3327C23.3939 19.3327 21.3333 18.746 21.3333 17.866C21.3333 17.4887 21.4013 17.0827 21.5193 16.666C23.1113 17.9993 30.2219 17.9993 31.8146 16.6687C31.9313 17.084 31.9999 17.4893 31.9999 17.866Z" fill="white" />
        <path d="M16.6667 33.1673C16.6667 32.9463 16.5789 32.7343 16.4226 32.5781C16.2663 32.4218 16.0543 32.334 15.8333 32.334C15.6123 32.334 15.4004 32.4218 15.2441 32.5781C15.0878 32.7343 15 32.9463 15 33.1673V36.0007H16.6667V33.1673Z" fill="white" />
        <path d="M18.6687 27.1675C18.6687 28.7342 17.3993 30.0028 15.8333 30.0028C14.2673 30.0028 12.998 28.7342 12.998 27.1675C12.998 27.1102 12 27.1115 12 27.1675C12 28.1842 12.4039 29.1592 13.1228 29.8781C13.8416 30.597 14.8167 31.0008 15.8333 31.0008C16.85 31.0008 17.825 30.597 18.5439 29.8781C19.2628 29.1592 19.6667 28.1842 19.6667 27.1675C19.6667 27.1115 18.6687 27.1102 18.6687 27.1675Z" fill="white" />
        <path d="M13 27.1667C13 27.2993 12.9473 27.4265 12.8536 27.5202C12.7598 27.614 12.6326 27.6667 12.5 27.6667C12.3674 27.6667 12.2402 27.614 12.1464 27.5202C12.0527 27.4265 12 27.2993 12 27.1667V21.5C12 21.3674 12.0527 21.2402 12.1464 21.1464C12.2402 21.0527 12.3674 21 12.5 21C12.6326 21 12.7598 21.0527 12.8536 21.1464C12.9473 21.2402 13 21.3674 13 21.5V27.1667ZM19.6667 27.1667C19.6667 27.2993 19.614 27.4265 19.5202 27.5202C19.4265 27.614 19.2993 27.6667 19.1667 27.6667C19.0341 27.6667 18.9069 27.614 18.8131 27.5202C18.7193 27.4265 18.6667 27.2993 18.6667 27.1667V21.5C18.6667 21.3674 18.7193 21.2402 18.8131 21.1464C18.9069 21.0527 19.0341 21 19.1667 21C19.2993 21 19.4265 21.0527 19.5202 21.1464C19.614 21.2402 19.6667 21.3674 19.6667 21.5V27.1667ZM16.3333 32.6667C16.3333 32.7993 16.2807 32.9265 16.1869 33.0202C16.0931 33.114 15.9659 33.1667 15.8333 33.1667C15.7007 33.1667 15.5735 33.114 15.4798 33.0202C15.386 32.9265 15.3333 32.7993 15.3333 32.6667V21.5C15.3333 21.3674 15.386 21.2402 15.4798 21.1464C15.5735 21.0527 15.7007 21 15.8333 21C15.9659 21 16.0931 21.0527 16.1869 21.1464C16.2807 21.2402 16.3333 21.3674 16.3333 21.5V32.6667Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_1032_6698">
          <rect width="24" height="24" fill="white" transform="translate(12 12)" />
        </clipPath>
      </defs>
    </svg>,
  ];
  const [cardData, setCardData] = useState([
    {
      icon: availableIcons[0],
      title: "Total Users",
      count: "--",
    },
    {
      icon: availableIcons[1],
      title: "Active Users",
      count: "--",
    },
    {
      icon: availableIcons[2],
      title: "Department Users",
      count: "--",
    },
    {
      icon: availableIcons[3],
      title: "Total Farmers",
      count: "--",
    },
  ]);
  const pageSize = 10;

  useEffect(() => {
    fetchData("");
  }, []);

  const fetchData = async (query) => {
    try {
      const respones = await getUserersApi("?search=" + query);
      if (respones?.status === 200) {
        setMembersData(respones?.data);
        const dynamicCardData = Object.keys(respones?.data.extra_data).map((key) => ({
          icon: availableIcons[Math.floor(Math.random() * 4)],
          title: key,
          count: respones?.data.extra_data[key] || 0,
        }));
        setCardData(dynamicCardData);
      } else {
        let errors = "Opps! Something went wrong, please try again.";
        showAlert("Error", errors, "error");
      }
    } catch (error) {
      let errors =
        error?.response?.data?.detail ||
        "Opps! Something went wrong, please try again.";
      showAlert("Error", errors, "error");
    }
  };

  const openModal = (row) => {
    setIsOpen(true);
    setFaqsId(row?.id);
    setIsConfirmDelete(false);
  };

  const closeModal = () => {
    setIsOpen(false);
    setIsConfirmDelete(false);
  };

  const openEditModal = (row) => {
    setHandleEditModal(true);
    setFaqsId(row?.id);
  };

  const closeEditModal = () => {
    setHandleEditModal(false);
  };

  useEffect(() => {
    if (isConfirmDelete === true) {
      const fetchData = async () => {
        try {
          const respones = await deleteUsersApi(faqsId);
          if (respones?.status === 204) {
            try {
              const respones = await getUserersApi();
              if (respones?.status === 200) {
                setMembersData(respones?.data);
              } else {
                let errors = "Opps! Something went wrong, please try again.";
                showAlert("Error", errors, "error");
              }
            } catch (error) {
              let errors =
                error?.response?.data?.detail ||
                "Opps! Something went wrong, please try again.";
              showAlert("Error", errors, "error");
            }
            setIsOpen(false);
            showSuccessToast("Member deleted successfully!");
          } else {
            let errors = "Opps! Something went wrong, please try again.";
            showAlert("Error", errors, "error");
          }
        } catch (error) {
          let errors =
            error?.response?.data?.detail ||
            "Opps! Something went wrong, please try again.";
          showAlert("Error", errors, "error");
        }
      };
      fetchData();
    }
  }, [isConfirmDelete]);

  const handleEdit = (rowData) => {
  navigate(`/users/edit`, { state: { rowData } });
  };

  const extraColumnRenderer = (row) => (
    <div className="flex space-x-2">
      <BsLock
        size={23}
        onClick={() => handleEdit(row)}
        className="cursor-pointer text-blue-400"
      />
      <MdDelete
        size={25}
        onClick={() => openModal(row)}
        className="cursor-pointer text-red-600"
      />
    </div>
  );
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleSearch = (query) => {
    fetchData(query);
  };

  return (
    <div className="">
      <Toast />
      {/* Top Section Begin */}
      <div className="flex gap-3 items-center font-medium text-gray-500">
        <AiFillHome />
        <p>Home</p>
        <IoIosArrowForward />
        <p>Users</p>
      </div>
      {/* Top Section End */}
      {/* Add Faqs Begin */}
      <div className="flex justify-between pt-3">
        <div className="w-[30%]">
          <SearchBar placeholder="Search in Users..." onSearch={handleSearch} />
        </div>
        <div>
          <Link to="/users/add">
            <button
              type="button"
              className="text-white bg-primary hover:bg-primary-dark font-sm rounded-lg text-md w-full px-3 py-2.5 text-center flex items-center gap-1"
            >
              <IoMdAdd style={{ color: "white" }} size={23} />
              Add User
            </button>
          </Link>
        </div>
      </div>
      {/* Add Faqs End */}
      {/* Card Section Begin */}
      <div className="py-3">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
          {cardData.map((card, index) => (
            <Card title={card.title} count={card.count} icon={card.icon} />
          ))}
        </div>
      </div>
      {/* Card Section End */}
      {/* Tabs Section Begin */}
      <div className="lg:pl-2">
        <Tab />
      </div>
      {/* Tabs Section End */}
      {/* Table Begin */}
      <div className="mt-2">
        <Table
          headers={headers}
          keys={keys}
          rows={membersData?.results}
          extraColumn="Actions"
          extraColumnRenderer={extraColumnRenderer}
        />
      </div>
      {/* Table End */}
      {/* Pagination Begin */}
      <div className="xl:pl-5 pl-0">
        <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil(membersData?.count / pageSize)}
          pageSize={pageSize}
          totalEntries={membersData?.count}
          onPageChange={handlePageChange}
          data={membersData}
          setData={setMembersData}
        />
      </div>
      {/* Pagination End */}
      <ConfirmModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Add Device Type Modal"
        onClose={closeModal}
        setIsConfirmDelete={setIsConfirmDelete}
      />
    </div>
  );
};

export default Users;
