import {
  firmwareCRUDApiUrl,
  getFirmwaresApiUrl,
} from "../../constant/urls/apiUrls";
import axios from "axios";
import { get_token } from "../../constant/urls/localStoarege";

export const getFirmwareApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.get(`${getFirmwaresApiUrl.replace("http://", "https://")}${data || ""}`, config);

  return response;
};

export const addFirmwareApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.post(firmwareCRUDApiUrl, data, config);

  return response;
};

export const editFirmwareApi = async (data, id) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.patch(
    `${firmwareCRUDApiUrl}${id}/`,
    data,
    config
  );

  return response;
};

export const deleteFirmwareApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.delete(
    `${firmwareCRUDApiUrl}${data}/`,
    config
  );

  return response;
};
