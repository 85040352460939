import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { AiFillHome } from "react-icons/ai";
import { IoIosArrowForward, IoMdAdd } from "react-icons/io";
import { PiUploadFill } from "react-icons/pi";
import { Link, useNavigate } from "react-router-dom";
import InputField from "../../../components/inputFields/InputField";
import TextareaField from "../../../components/inputFields/TextField";
import Select from "../../../components/inputFields/SelectField";
import Button from "../../../components/buttons/Button";
import { get_user_id } from "../../../constant/urls/localStoarege";
import showAlert from "../../../components/errorBoundries/Errors";
import { addPlantApi, getUserDropDownApi } from "../../../services/apis/plant";
import { showSuccessToast } from "../../../components/toastify/Toast";
import PDFViewer from "../../../components/PdfViewer";

function DetailedManualReport() {
    const navigate = useNavigate();
    const userId = get_user_id();
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedFile, setSelectedFile] = useState("");
    const [userOptions, setUserOptions] = useState({});
    const reportTypeOptions = [
        { value: "Installation Report", label: "Installation Report", },
        { value: "Survey Report", label: "Survey Report", },
        { value: "Site Visit Report", label: "Site Visit Report", },
        { value: "Monitoring Report", label: "Monitoring Report", },
    ];



    useEffect(() => {
        const fetchData = async () => {
            try {
                const respones = await getUserDropDownApi();
                if (respones?.status === 200) {
                    setUserOptions(respones?.data);
                } else {
                    let errors = "Opps! Something went wrong, please try again.";
                    showAlert("Error", errors, "error");
                }
            } catch (error) {
                let errors =
                    error?.response?.data?.detail ||
                    "Opps! Something went wrong, please try again.";
                showAlert("Error", errors, "error");
            }
        };
        fetchData();
    }, []);

    const transformUserData = () => {
        const options = [];
        if (userOptions && userOptions) {
            for (let i = 0; i < userOptions.length; i++) {
                const item = userOptions[i];
                options.push({
                    value: item.id,
                    label: item.name,
                });
            }
        }
        return options;
    };

    return (
        <div className="relative">
            {/* Top Section Begin */}
            <div className="flex gap-3 items-center font-medium text-gray-500">
                <AiFillHome />
                <p>Home</p>
                <IoIosArrowForward />
                <p>Manual Report</p>
                <IoIosArrowForward />
                <p>Add New Report</p>
            </div>
            {/* Top Section End */}
            {/* Form Begin */}
            <div className="mt-8">
                <Formik
                    initialValues={{
                        report_type: "",
                        surveyed_by: "",
                        installation_time: "",
                    }}
                    validationSchema={Yup.object({
                        report_type: Yup.string().required("Please Report Type"),
                        surveyed_by: Yup.string().required("Select User"),
                        installation_time: Yup.string().required("Installation time is medatory"),
                    })}
                >
                    {({ isSubmitting, resetForm }) => (
                        <Form>
                            <div className="items-start w-full border-gray-300 p-4 rounded-lg bg-gray-100 border">
                                <div className="flex-1 ml-5">
                                    <div className="w-full mb-1">
                                        <div className="grid gap-6 md:grid-cols-3">
                                            <Select
                                                label="Report Type"
                                                name="report_type"
                                                options={reportTypeOptions}
                                                disabled={true}
                                            />
                                            <Select
                                                label="Surveyed By"
                                                name="surveyed_by"
                                                options={transformUserData()}
                                                disabled={true}
                                            />
                                            <InputField
                                                label="Installation Time"
                                                name="installation_time"
                                                type="datetime-local"
                                                placeholder="Select"
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <label htmlFor="Pdf preview" className="font-medium">
                                    PDF Preview
                                </label>
                                <div className="w-1/2 mt-2">
                                    <PDFViewer pdfUrl={"https://conasems-ava-prod.s3.sa-east-1.amazonaws.com/aulas/ava/dummy-1641923583.pdf"} />
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            {/* Form End */}
        </div>
    );
}

export default DetailedManualReport;