import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { AiFillHome } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import { IoIosArrowForward, IoMdShare } from "react-icons/io";
import { PiUploadFill } from "react-icons/pi";
import { Link, useNavigate } from "react-router-dom";
import InputField from "../../components/inputFields/InputField";
import TextareaField from "../../components/inputFields/TextField";
import Select from "../../components/inputFields/SelectField";
import Button from "../../components/buttons/Button";
import { get_user_id } from "../../constant/urls/localStoarege";
import showAlert from "../../components/errorBoundries/Errors";
import { getPlantsDetailApi, getUserDropDownApi, toggleDeviceStatusApi } from "../../services/apis/plant";
import Table from "../../components/tables/Table";
import { showSuccessToast } from "../../components/toastify/Toast";

function ViewPlant() {
    const navigate = useNavigate();
    const userId = get_user_id();
    const location = useLocation();
    const [rowData, setRowData] = useState({});
    const [selectedImage, setSelectedImage] = useState(null);
    const [userOptions, setUserOptions] = useState({});
    const headers = ["Name", "Serial No", "Mac Id"];
    const keys = ["device_name", "serial_no", "mac_id"];
    const [devicesData, setDevicesData] = useState({});
    const [districtOptions, setDistrictOptions] = useState({});



    // const extraColumnRenderer = (row) => (
    //     <div className="flex justify-center text-primary bg-[#DEF7EC] px-3 py-1 rounded-lg hover:primary hover:text-primary">
    //         {row.is_active}
    //     </div>
    // );



    useEffect(() => {
        if (location.state.rowData?.image) {
            setSelectedImage(`${location.state.rowData?.image}`);
        }
        const fetchData = async () => {
            try {
                const respones = await getPlantsDetailApi(`?plant_id=${location.state.rowData?.id}`);
                if (respones?.status === 200) {
                    setRowData(respones?.data);
                    setDevicesData(respones?.data.devices);
                } else {
                    let errors = "Opps! Something went wrong, please try again.";
                    showAlert("Error", errors, "error");
                }
            } catch (error) {
                let errors =
                    error?.response?.data?.detail ||
                    "Opps! Something went wrong, please try again.";
                showAlert("Error", errors, "error");
            }
        };
        fetchData();
    }, [location.state.rowData]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const respones = await getUserDropDownApi();
            if (respones?.status === 200) {
                setUserOptions(respones?.data);
            } else {
                let errors = "Opps! Something went wrong, please try again.";
                showAlert("Error", errors, "error");
            }
        } catch (error) {
            let errors =
                error?.response?.data?.detail ||
                "Opps! Something went wrong, please try again.";
            showAlert("Error", errors, "error");
        }
    };

    const transformUserData = () => {
        const options = [];
        if (userOptions && userOptions) {
            for (let i = 0; i < userOptions.length; i++) {
                const item = userOptions[i];
                options.push({
                    value: item.id,
                    label: item.name,
                });
            }
        }
        return options;
    };

    const handleDiscardChanges = (resetForm) => {
        resetForm();
        navigate("/plant");
    };

    const handleSendEmail = (rowData) => {
        console.log("Download button clicked");
    };

    const toggleDeviceStatus = async (rowData) => {
        try {
            const payload = {
                device_id: rowData?.id,
            };
            const response = await toggleDeviceStatusApi(payload);
            if (response?.status === 201) {
                showSuccessToast("Device Status Updated Successfully");
                fetchData();
            } else {
                let errors = "Opps! Something went wrong, please try again.";
                showAlert("Error", errors, "error");
            }
        } catch (error) {
            let errors =
                error?.response?.data?.error ||
                "Opps! Something went wrong, please try again.";
            showAlert("Error", errors, "error");
        }
    };

    const extraColumnRenderer = (row) => (
        <div className="flex space-x-2">
            <button
                className="text-primary bg-[#DEF7EC] px-3 py-1 rounded-lg hover:primary hover:text-primary"
            >
                {row.status}
            </button>
            <button
                className={`${row.operation == "Enable"? "text-primary":"text-red-600"}  bg-[#DEF7EC] px-3 py-1 rounded-lg hover:primary hover:text-primary`}
                onClick={() => toggleDeviceStatus(row)}
            >
                {row.operation}
            </button>
        </div>
    );


    const transformData = () => {
        const options = [];
        if (districtOptions && districtOptions) {
            for (let i = 0; i < districtOptions.length; i++) {
                const item = districtOptions[i];
                options.push({
                    value: item.id,
                    label: item.device_name,
                });
            }
        }
        return options;
    };

    return (
        <div className="relative">
            {/* Top Section Begin */}
            <div className="flex gap-3 items-center font-medium text-gray-500">
                <AiFillHome />
                <p>Home</p>
                <IoIosArrowForward />
                <p>Plants</p>
                <IoIosArrowForward />
                <p>View Plant</p>
            </div>
            {/* Top Section End */}
            {/* Form Begin */}
            <div className="mt-8">
                <Formik
                    enableReinitialize
                    initialValues={{
                        name: rowData?.plant_name || "",
                        user: rowData?.user || "",
                        address: rowData?.address || "",
                        country: rowData?.country || "",
                        state: rowData?.state || "",
                        city: rowData?.city || "",
                        phone: rowData?.phone || "",
                        pincode: rowData?.pincode || "",
                        lat: rowData?.lat || "",
                        lon: rowData?.lon || "",
                        installationDate: rowData.installationDate || "",
                        email: rowData.plant_email || "",
                        district: rowData.district || "",
                        area: rowData.area || "",
                    }}
                >
                    {({ isSubmitting, resetForm }) => (
                        <Form>
                            <div className="items-start w-full border-gray-300 p-4 rounded-lg bg-gray-100 border">
                                <div className="flex flex-row">
                                    {
                                        selectedImage !== null ?
                                            <div className="w-[200px]">
                                                <div className="w-[200px] h-[200px] rounded-lg">
                                                    <img
                                                        src={selectedImage}
                                                        alt="Profile"
                                                        className="w-full h-full object-cover rounded-lg"
                                                    />
                                                </div>
                                            </div> : <></>
                                    }
                                    <div className="flex-1 ml-5">
                                        <div className="w-full mb-1">
                                            <div className="grid gap-6 md:grid-cols-3">
                                                <InputField
                                                    label="Name"
                                                    name="name"
                                                    type="text"
                                                    placeholder="Enter Plant Name"
                                                    required
                                                    disable
                                                />
                                                <InputField
                                                    label="Email"
                                                    name="email"
                                                    type="email"
                                                    placeholder="xyz@example.com"
                                                    disable
                                                />
                                                <div className="w-fill flex flex-row items-center">
                                                    <Select
                                                        label="User"
                                                        name="user"
                                                        options={transformUserData()}
                                                        required
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-full mb-1">
                                            <div className="grid gap-6 md:grid-cols-2">
                                                <TextareaField
                                                    label="Address"
                                                    name="address"
                                                    type="text"
                                                    placeholder="Enter your Addess"
                                                    false
                                                    disable
                                                />
                                                <TextareaField
                                                    label="Area"
                                                    name="area"
                                                    type="text"
                                                    placeholder="Enter Area Here"
                                                    false
                                                    disable
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full mb-1">
                                            <div className="grid gap-6 md:grid-cols-3">
                                                <InputField
                                                    label="Coutry"
                                                    name="country"
                                                    type="name"
                                                    placeholder="India"
                                                    false
                                                    disable
                                                />
                                                <InputField
                                                    label="State"
                                                    name="state"
                                                    type="name"
                                                    placeholder="Maharastra"
                                                    false
                                                    disable
                                                />
                                                <InputField
                                                    label="District"
                                                    name="district"
                                                    type="name"
                                                    placeholder="Pune"
                                                    false
                                                    disable
                                                />
                                                {/* <Select
                                                    label="District"
                                                    name="district"
                                                    options={transformData()}
                                                    false
                                                /> */}
                                                <InputField
                                                    label="City"
                                                    name="city"
                                                    type="name"
                                                    placeholder="Pune"
                                                    false
                                                    disable
                                                />
                                                <InputField
                                                    label="Date Of Installation"
                                                    name="installationDate"
                                                    type="date"
                                                    placeholder="Enter Date"
                                                    required
                                                    disable
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full mb-1">
                                            <div className="grid gap-6 md:grid-cols-2">
                                                <InputField
                                                    label="Phone No."
                                                    name="phone"
                                                    type="name"
                                                    placeholder="XXXXXXXXXX"
                                                    false
                                                    disable
                                                />
                                                <InputField
                                                    label="Pincode"
                                                    name="pincode"
                                                    type="number"
                                                    placeholder="XXXXXX"
                                                    false
                                                    disable
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full mb-1">
                                            <div className="grid gap-6 md:grid-cols-2">
                                                <InputField
                                                    label="Latitude"
                                                    name="lat"
                                                    type="text"
                                                    placeholder="12.23"
                                                    required
                                                    disable
                                                />
                                                <InputField
                                                    label="Longitude"
                                                    name="lon"
                                                    type="text"
                                                    placeholder="23.32"
                                                    required
                                                    disable
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
                <div className="flex justify-between my-2">
                    <label>Connected Devices</label>
                    <div>
                        <button
                            type="button"
                            className="text-white bg-primary hover:bg-primary-dark font-sm rounded-lg text-md w-full px-3 py-2.5 text-center flex items-center gap-1"
                            onClick={handleSendEmail}
                        >
                            <IoMdShare style={{ TextColor: "black" }} size={20} />
                            Email
                        </button>
                    </div>

                </div>
                <Table
                    headers={headers}
                    keys={keys}
                    rows={devicesData}
                    extraColumn="Status | Operation"
                    extraColumnRenderer={extraColumnRenderer}
                />
            </div>
            {/* Form End */}
        </div >
    );
}

export default ViewPlant;