import React from "react";
import { useField } from "formik";


const DeviceLogCard = ({ title, icon, ...props }) => {
    const [field, meta] = useField(props);

    return (
        <div className="justify-between p-4 bg-gray-100 rounded-lg border border-gray-300">
            <div className="flex flex-row justify-start space-x-2 mb-2">
                <p className="text-gray-600 font-medium flex-1">{title}</p>
                {icon}
            </div>
            <input
                className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-10/12 p-2.5"
                type="number"
                {...field}
                {...props}
            />
        </div>
    );
};

export default DeviceLogCard;
