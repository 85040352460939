import axios from "axios";
import { dashboardDeviceLogApiUrl, deviceLogCardApiUrl, generatedeviceLogReportApiUrl, homeDeviceLog } from "../../constant/urls/apiUrls";
import { get_token } from "../../constant/urls/localStoarege";


export const getDeviceLogsApi = async ({ deviceId, startDate, endDate }) => {

    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${get_token()}`,
        },
    };

    const response = await axios.get(`${homeDeviceLog.replace("http://", "https://")}?device_id=${deviceId}&from_date=${startDate ?? ""}&to_date=${endDate ?? ""}`, config);

    return response;
};


export const getDeviceLogsHomeApi = async ({ deviceId, startDate, endDate }) => {

    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${get_token()}`,
        },
    };
    const response = await axios.get(`${dashboardDeviceLogApiUrl.replace("http://", "https://")}?page_size=100&device_id=${deviceId}&from_date=${startDate ?? ""}&to_date=${endDate ?? ""}`, config);

    return response;
};


export const getDeviceLogsDetailedApi = async ({ deviceId, startDate, endDate }) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${get_token()}`,
        },
    };

    const response = await axios.get(`${dashboardDeviceLogApiUrl.replace("http://", "https://")}/report/${deviceId}?from_date=${startDate}&to_date=${endDate}`, config);

    return response;
};


export const getDeviceLogsCardsApi = async () => {

    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${get_token()}`,
        },
    };

    const response = await axios.get(deviceLogCardApiUrl, config);

    return response;
};


export const deviceLogReportSaveApi = async (data) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${get_token()}`,
        },
    };

    const response = await axios.post(generatedeviceLogReportApiUrl, data, config);

    return response;
};