import {
  getDeviceDropDownApiUrl,
  getUsersApiUrl,
  userChangePasswordUrl,
  userCRUDUrl,
} from "../../constant/urls/apiUrls";
import axios from "axios";
import { get_token } from "../../constant/urls/localStoarege";

export const getUserersApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.get(`${getUsersApiUrl.replace("http://", "https://")}${data || ""}`, config);

  return response;
};

export const getUsersTypeApi = async () => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.get(userCRUDUrl, config);

  return response;
};

export const addUsersApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.post(userCRUDUrl, data, config);

  return response;
};

export const editUsersApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };
  
  const response = await axios.post(userChangePasswordUrl, data, config);

  return response;
};

export const deleteUsersApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.delete(`${userCRUDUrl}${data}/`, config);

  return response;
};

export const getDeviceDropDownApi = async () => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.get(getDeviceDropDownApiUrl.replace("http://", "https://"), config);

  return response;
};

