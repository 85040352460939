import React, { useState, useEffect, useCallback } from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts";

// Function to format dates
function formatDate(dateTimeStr) {
    const dateTime = new Date(dateTimeStr);
    const year = dateTime.getFullYear();
    const month = String(dateTime.getMonth() + 1).padStart(2, '0');
    const day = String(dateTime.getDate()).padStart(2, '0');
    const hours = String(dateTime.getHours()).padStart(2, '0');
    const minutes = String(dateTime.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}`;
}

// Custom tooltip component
const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        const formattedDate = formatDate(label); // Format the date using your function
        return (
            <div className="bg-white p-3 border rounded shadow">
                <p className="font-semibold text-gray-700">{formattedDate}</p>
                {payload.map((entry, index) => (
                    <p key={index} style={{ color: entry.color }}>
                        {`${entry.name}: ${entry.value} ${getUnit(entry.name)}`}
                    </p>
                ))}
            </div>
        );
    }
    return null;
};

// Function to get units based on the data key
const getUnit = (key) => {
    const units = {
        temperature: "°C",
        humidity: "%",
        plate_temperature_1: "°C",
        plate_temperature_2: "°C",
        plate_temperature_3: "°C",
        solar_supply: "W",
        r_phase: "V",
        y_phase: "V",
        b_phase: "V",
        battery_level: "%",
        low_pressure: "Pa",
        high_pressure: "Pa",
        water_level: "cm",
    };
    return units[key] || ""; // Default to an empty string if no unit is found
};

const ZOOM_FACTOR = 1.1; // Zoom scale factor

const DynamicMultiLineChart = ({ data = [] }) => {
    const [activeLines, setActiveLines] = useState({
        temperature: true,
        humidity: true,
        plate1Temp: false,
        plate2Temp: false,
        plate3Temp: false,
        solarSupply: true,
        rPhase: false,
        yPhase: false,
        bPhase: false,
        batteryLevel: false,
        lowPressure: false,
        highPressure: false,
        waterLevel: false,
    });

    const [zoomDomain, setZoomDomain] = useState([0, data.length - 1]);
    const [panStart, setPanStart] = useState(null);
    const [isPanning, setIsPanning] = useState(false);
    const [allowScroll, setAllowScroll] = useState(false);

    const toggleLineVisibility = (key) => {
        setActiveLines((prevState) => ({
            ...prevState,
            [key]: !prevState[key],
        }));
    };

    // Reverse the data before rendering
    const reversedData = [...data].reverse(); // Create a copy and reverse it

    // Calculate the zoom based on mouse position
    const handleZoom = (e) => {
        // Prevent default page scrolling behavior during zoom
        e.preventDefault();

        if (reversedData.length === 0) return;

        const boundingRect = e.currentTarget.getBoundingClientRect();
        const mouseX = e.clientX - boundingRect.left; // Get mouse X relative to chart
        const mousePositionRatio = mouseX / boundingRect.width; // Mouse X ratio over width of chart

        const range = zoomDomain[1] - zoomDomain[0];
        let newRange = e.deltaY > 0 ? range * ZOOM_FACTOR : range / ZOOM_FACTOR;
        if (newRange > reversedData.length) newRange = reversedData.length;

        const midPoint = zoomDomain[0] + range * mousePositionRatio; // Adjust based on mouse position

        const newStart = Math.max(0, Math.floor(midPoint - (newRange * mousePositionRatio)));
        const newEnd = Math.min(reversedData.length - 1, Math.ceil(midPoint + (newRange * (1 - mousePositionRatio))));

        setZoomDomain([newStart, newEnd]);
    };

    // Panning Logic
    const handleMouseDown = (e) => {
        setIsPanning(true);
        setPanStart(e.clientX);
    };

    const handleMouseUp = () => {
        setIsPanning(false);
    };

    const handleMouseMove = (e) => {
        if (!isPanning || reversedData.length === 0) return;

        const deltaX = e.clientX - panStart;
        const panSpeed = (zoomDomain[1] - zoomDomain[0]) * 0.005; // Pan speed factor

        if (deltaX !== 0) {
            const newStart = Math.max(0, zoomDomain[0] - deltaX * panSpeed);
            const newEnd = Math.min(reversedData.length - 1, zoomDomain[1] - deltaX * panSpeed);

            setZoomDomain([newStart, newEnd]);
            setPanStart(e.clientX);
        }
    };

    const handleMouseEnter = () => {
        setAllowScroll(true);
    };

    const handleMouseLeave = () => {
        setAllowScroll(false);
    };


    const zoomedData = reversedData.slice(zoomDomain[0], zoomDomain[1] + 1);

    return (
        <RemoveScroll enabled={allowScroll}>
            <div
                onWheel={handleZoom} // Regular scroll to zoom
                onMouseDown={handleMouseDown} // Start panning
                onMouseUp={handleMouseUp} // Stop panning
                onMouseMove={handleMouseMove} // Handle panning movement
                onMouseEnter={handleMouseEnter} // Mouse enters the chart area
                onMouseLeave={handleMouseLeave} // Mouse leaves the chart area
                style={{ userSelect: "none", touchAction: "none", overflow: "hidden" }} // Disable touch selection and browser pinch-zoom
            >
                <ResponsiveContainer width="100%" height={500}>
                    <LineChart
                        data={zoomedData} // Use zoomed data
                        margin={{ right: 30, left: 1, bottom: 70 }} // Increase left margin for space
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey="created_at"
                            tickFormatter={formatDate} // Format dates on X-axis
                            tick={{ angle: -45, textAnchor: 'end' }} // Rotate X-axis labels by 12 degrees
                            height={50} // Adjust height to accommodate rotated labels
                            interval={2}
                        />
                        <YAxis yAxisId="left" />  {/* This Y-Axis corresponds to the left lines */}
                        <YAxis
                            yAxisId="left-solar"
                            orientation="left"
                            offset={30}
                        />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend
                            onClick={(e) => toggleLineVisibility(e.dataKey)} // Toggle line visibility on legend click
                            wrapperStyle={{
                                cursor: "pointer",
                            }}
                            verticalAlign='top'
                            align='center'
                        />

                        {/* Ensure each Line has a corresponding yAxisId */}
                        {activeLines.temperature && (
                            <Line
                                type="monotone"
                                dataKey="temperature"
                                stroke="#ff6384"
                                dot={false}
                                yAxisId="left"  // Corresponds to Y-Axis with id "left"
                            />
                        )}
                        {activeLines.humidity && (
                            <Line
                                type="monotone"
                                dataKey="humidity"
                                stroke="#36a2eb"
                                dot={false}
                                yAxisId="left"  // Corresponds to Y-Axis with id "left"
                            />
                        )}
                        {activeLines.plate1Temp && (
                            <Line
                                type="monotone"
                                dataKey="plate_temperature_1"
                                stroke="#ff9f40"
                                dot={false}
                                yAxisId="left"  // Corresponds to Y-Axis with id "left"
                            />
                        )}
                        {activeLines.plate2Temp && (
                            <Line
                                type="monotone"
                                dataKey="plate_temperature_2"
                                stroke="#9966ff"
                                dot={false}
                                yAxisId="left"  // Corresponds to Y-Axis with id "left"
                            />
                        )}
                        {activeLines.plate3Temp && (
                            <Line
                                type="monotone"
                                dataKey="plate_temperature_3"
                                stroke="#4bc0c0"
                                dot={false}
                                yAxisId="left"  // Corresponds to Y-Axis with id "left"
                            />
                        )}
                        {activeLines.solarSupply && (
                            <Line
                                yAxisId="left-solar"  // This corresponds to the left-solar Y-Axis
                                type="monotone"
                                dataKey="solar_supply"
                                stroke="#ffcd56"
                                dot={false}
                            />
                        )}
                        {activeLines.rPhase && (
                            <Line
                                type="monotone"
                                dataKey="r_phase"
                                stroke="#ff6384"
                                dot={false}
                                yAxisId="left"  // Corresponds to Y-Axis with id "left"
                            />
                        )}
                        {activeLines.yPhase && (
                            <Line
                                type="monotone"
                                dataKey="y_phase"
                                stroke="#36a2eb"
                                dot={false}
                                yAxisId="left"  // Corresponds to Y-Axis with id "left"
                            />
                        )}
                        {activeLines.bPhase && (
                            <Line
                                type="monotone"
                                dataKey="b_phase"
                                stroke="#ff9f40"
                                dot={false}
                                yAxisId="left"  // Corresponds to Y-Axis with id "left"
                            />
                        )}
                        {activeLines.batteryLevel && (
                            <Line
                                type="monotone"
                                dataKey="battery_level"
                                stroke="#9966ff"
                                dot={false}
                                yAxisId="left"  // Corresponds to Y-Axis with id "left"
                            />
                        )}
                        {activeLines.lowPressure && (
                            <Line
                                type="monotone"
                                dataKey="low_pressure"
                                stroke="#4bc0c0"
                                dot={false}
                                yAxisId="left"  // Corresponds to Y-Axis with id "left"
                            />
                        )}
                        {activeLines.highPressure && (
                            <Line
                                type="monotone"
                                dataKey="high_pressure"
                                stroke="#ffcd56"
                                dot={false}
                                yAxisId="left"  // Corresponds to Y-Axis with id "left"
                            />
                        )}
                        {activeLines.waterLevel && (
                            <Line
                                type="monotone"
                                dataKey="water_level"
                                stroke="#009688"
                                dot={false}
                                yAxisId="left"  // Corresponds to Y-Axis with id "left"
                            />
                        )}
                    </LineChart>
                </ResponsiveContainer>

                <div className="flex flex-wrap mt-4">
                    {Object.keys(activeLines).map((key) => (
                        <button
                            key={key}
                            className={`m-2 px-3 py-1 text-white rounded-3xl text-sm ${activeLines[key] ? "bg-primary" : "bg-gray-400"
                                }`}
                            onClick={() => toggleLineVisibility(key)}
                        >
                            {key.replace(/([A-Z])/g, " $1")}
                        </button>
                    ))}
                </div>
            </div>
        </ RemoveScroll>
    );
};

export default DynamicMultiLineChart;
