import React, { useEffect, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { MdCircleNotifications } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { AiFillHome } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import SearchBar from "../../../components/searchBar/SearchBar";
import Card from "../../../components/cards/Card";
import Table from "../../../components/tables/Table";
import Pagination from "../../../components/pagination/Pagination";
import { Link, useNavigate } from "react-router-dom";
import ConfirmModal from "../../../components/popups/confirmationModal/ConfirmModal";
import {
  deleteNotificationApi,
  getNotificationApi,
} from "../../../services/apis/notification";
import showAlert from "../../../components/errorBoundries/Errors";
import Toast, { showSuccessToast } from "../../../components/toastify/Toast";

const Notification = () => {
  const navigate = useNavigate();
  const headers = ["Date Posted", "Time", "Title", "Description"];
  const keys = ["date", "time", "title", "description"];
  const [modalIsOpen, setIsOpen] = useState(false);
  const [notificatonData, setNotificationData] = useState({});
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);
  const [notificationId, setNotificationId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const totalEntries = 100;
  const pageSize = 10;

  const fetchData = async (data) => {
    try {
      const respones = await getNotificationApi(`?search=${data}`);
      if (respones?.status === 200) {
        setNotificationData(respones?.data);
      } else {
        let errors = "Opps! Something went wrong, please try again.";
        showAlert("Error", errors, "error");
      }
    } catch (error) {
      let errors =
        error?.response?.data?.detail ||
        "Opps! Something went wrong, please try again.";
      showAlert("Error", errors, "error");
    }
  };

  useEffect(() => {
    fetchData("");
  }, []);

  const openModal = (row) => {
    setNotificationId(row?.id);
    setIsConfirmDelete(false);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setIsConfirmDelete(false);
  };

  const handleEdit = (rowData) => {
    navigate(`/notification/edit-notification`, { state: { rowData } });
  };

  useEffect(() => {
    if (isConfirmDelete === true) {
      const fetchData = async () => {
        try {
          const respones = await deleteNotificationApi(notificationId);
          if (respones?.status === 204) {
            try {
              const respones = await getNotificationApi();
              if (respones?.status === 200) {
                setNotificationData(respones?.data);
              } else {
                let errors = "Opps! Something went wrong, please try again.";
                showAlert("Error", errors, "error");
              }
            } catch (error) {
              let errors =
                error?.response?.data?.detail ||
                "Opps! Something went wrong, please try again.";
              showAlert("Error", errors, "error");
            }
            setIsOpen(false);
            showSuccessToast("Notification deleted successfully!");
          } else {
            let errors = "Opps! Something went wrong, please try again.";
            showAlert("Error", errors, "error");
          }
        } catch (error) {
          let errors =
            error?.response?.data?.detail ||
            "Opps! Something went wrong, please try again.";
          showAlert("Error", errors, "error");
        }
      };
      fetchData();
    }
  }, [isConfirmDelete]);

  const extraColumnRenderer = (row) => (
    <div className="flex space-x-2">
      <FiEdit
        size={23}
        onClick={() => handleEdit(row)}
        className="cursor-pointer text-primary"
      />
      <MdDelete
        size={25}
        onClick={() => openModal(row)}
        className="cursor-pointer text-red-600"
      />
    </div>
  );
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleSearch = (query) => {
    fetchData(query);
  };
  return (
    <div className="">
      <Toast />
      {/* Top Section Begin */}
      <div className="flex gap-3 items-center font-medium text-gray-500">
        <AiFillHome />
        <p>Home</p>
        <IoIosArrowForward />
        <p>Notifications & Alerts</p>
      </div>
      {/* Top Section End */}
      {/* Add Notification Begin */}
      <div className="flex justify-between pt-3">
        <div className="w-[30%]">
          <SearchBar
            placeholder="Search in Notification..."
            onSearch={handleSearch}
          />
        </div>
        <div>
          <Link to="/notification/add-notification">
            <button
              type="button"
              className="text-white bg-primary hover:bg-primary-dark font-sm rounded-lg text-md w-full px-3 py-2.5 text-center flex items-center gap-1"
            >
              <IoMdAdd style={{ color: "white" }} size={23} />
              Add Notification
            </button>
          </Link>
        </div>
      </div>
      {/* Add Notification End */}
      {/* Card Section Begin */}
      <div className="w-[280px] py-3">
        <Card
          title="Notification"
          count={notificatonData?.count}
          icon={<MdCircleNotifications size={40} />}
        />
      </div>
      {/* Card Section End */}
      {/* Table Begin */}
      <div className="mt-2">
        <Table
          headers={headers}
          keys={keys}
          rows={notificatonData?.results}
          extraColumn="Actions"
          extraColumnRenderer={extraColumnRenderer}
        />
      </div>
      {/* Table End */}
      {/* Pagination Begin */}
      <div className="xl:pl-5 pl-0">
        <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil(notificatonData?.count / pageSize)}
          pageSize={pageSize}
          totalEntries={notificatonData?.count}
          onPageChange={handlePageChange}
          data={notificatonData}
          setData={setNotificationData}
        />
      </div>
      {/* Pagination End */}
      <ConfirmModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Add FAQ Type Modal"
        onClose={closeModal}
        setIsConfirmDelete={setIsConfirmDelete}
      />
    </div>
  );
};

export default Notification;
