import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { AiFillHome } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { editUsersApi } from "../../../services/apis/user";
import InputField from "../../../components/inputFields/InputField";
import Button from "../../../components/buttons/Button";
import { showSuccessToast } from "../../../components/toastify/Toast";
import showAlert from "../../../components/errorBoundries/Errors";
import StyledHeadingText from "../../../components/Fonts/StyledHeadingText";

function EditUser() {
    const navigate = useNavigate();
    const location = useLocation();
    const [rowData, setRowData] = useState({});


    useEffect(() => {
        if (location.state) {
            setRowData(location.state.rowData);
        }
    }, [location.state.rowData]);

    const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
        if (values.password === values.rPassword) {
            try {
                // let data = new FormData();
                // data.append('username', rowData.username);
                // data.append('new_password', values.password);
                const data = {
                    username: rowData.username,
                    new_password: values.password,
                };
                const response = await editUsersApi(data);

                if (response?.status === 200) {
                    navigate("/users");
                    showSuccessToast("Password Updated successfully!");
                } else {
                    let errors = "Opps! Something went wrong, please try again.";
                    showAlert("Error", errors, "error");
                }
            } catch (error) {
                let errors =
                    error?.response?.data?.error ||
                    "Opps! Something went wrong, please try again.";
                showAlert("Error", errors, "error");
            }
        }
        setSubmitting(false);
    };

    const handleDiscardChanges = (resetForm) => {
        resetForm();
        navigate("/users");
    };

    return (
        <div className="relative">
            {/* Top Section Begin */}
            <div className="flex gap-3 items-center font-medium text-gray-500">
                <AiFillHome />
                <p>Home</p>
                <IoIosArrowForward />
                <p>Users</p>
                <IoIosArrowForward />
                <p>Edit Password</p>
            </div>
            {/* Top Section End */}
            {/* Form Begin */}
            <div className="mt-8">
                <StyledHeadingText text={`Change Password for ${rowData.name}`} />
                <Formik
                    enableReinitialize
                    initialValues={{
                        password: '',
                        rPassword: '',
                    }}
                    validationSchema={Yup.object({
                        password: Yup.string().required("Password Required"),
                        rPassword: Yup.string().required("Please Repeat Password"),
                    })}
                    onSubmit={handleFormSubmit}
                >
                    {({ isSubmitting, resetForm }) => (
                        <Form>
                            <div className="flex flex-col items-start w-full border-gray-300 p-4 rounded-lg bg-gray-100 border">
                                <div className="grid gap-6 mb-1 md:grid-cols-2 w-full">
                                    <InputField
                                        label="Password"
                                        name="password"
                                        type="password"
                                        placeholder="**********"
                                        required
                                    />
                                    <InputField
                                        label="Repeat Password"
                                        name="rPassword"
                                        type="password"
                                        placeholder="**********"
                                        required
                                    />
                                </div>
                                <div className="py-5 flex justify-end gap-6 w-full">
                                    <div>
                                        <Button
                                            type="button"
                                            className="text-primary bg-white hover:bg-white-dark font-sm rounded-lg text-lg w-full px-8 py-1.5 text-center border border-primary"
                                            onClick={() => handleDiscardChanges(resetForm)}
                                            disabled={isSubmitting}
                                        >
                                            Discard Changes
                                        </Button>
                                    </div>
                                    <div>
                                        <Button
                                            type="submit"
                                            className="text-white bg-primary hover:bg-primary-dark font-sm rounded-lg text-lg w-full px-8 py-1.5 text-center"
                                            disabled={isSubmitting}
                                        >
                                            {isSubmitting ? "Submitting..." : "Submit"}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            {/* Form End */}
        </div>
    );
}

export default EditUser;