import {
  getNotificationApiUrl,
  addNotificationApiUrl,
  editNotificationApiUrl,
  deleteNotificationApiUrl,
} from "../../constant/urls/apiUrls";
import axios from "axios";
import { get_token } from "../../constant/urls/localStoarege";

export const getNotificationApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.get(`${getNotificationApiUrl.replace("http://", "https://")}${data || ""}`, config);

  return response;
};

export const addNotificationApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.post(addNotificationApiUrl.replace("http://", "https://"), data, config);

  return response;
};

export const editNotificationApi = async (data, id) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.patch(
    `${editNotificationApiUrl}${id}/`,
    data,
    config
  );

  return response;
};

export const deleteNotificationApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.delete(
    `${deleteNotificationApiUrl}${data}/`,
    config
  );

  return response;
};
