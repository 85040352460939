import axios from "axios";
import { get_token } from "../../constant/urls/localStoarege";
import { getEnquiriesApiUrl } from "../../constant/urls/apiUrls";

export const getEnquiriesApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.get(`${getEnquiriesApiUrl.replace("http://", "https://")}${data ?? ""}`, config);

  return response;
};