import {
  getCommunityApiUrl,
  addCommunityApiUrl,
  editCommunityApiUrl,
  deleteCommunityApiUrl,
} from "../../constant/urls/apiUrls";
import axios from "axios";
import { get_token } from "../../constant/urls/localStoarege";

export const getCommunityApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.get(`${getCommunityApiUrl.replace("http://", "https://")}${data || ''}`, config);

  return response;
};

export const addCommunityApi = async (data) => {
  const config = {
    headers: {
      // "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.post(addCommunityApiUrl, data, config);

  return response;
};

export const editCommunityApi = async (data, id) => {
  const config = {
    headers: {
      // "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.patch(
    `${editCommunityApiUrl}${id}/`,
    data,
    config
  );

  return response;
};

export const deleteCommunityApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.delete(
    `${deleteCommunityApiUrl}${data}/`,
    config
  );

  return response;
};
