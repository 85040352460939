import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AiFillHome } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import SearchBar from "../../components/searchBar/SearchBar";
import showAlert from "../../components/errorBoundries/Errors";
import Toast from "../../components/toastify/Toast";
import Card from "../../components/cards/Card";
import Table from "../../components/tables/Table";
import Pagination from "../../components/pagination/Pagination";
import { getEnquiriesApi } from "../../services/apis/enquiry";
function Enquiry() {
    const navigate = useNavigate();
    const headers = [
        "Enquiry Date",
        "User Name",
        "User Type",
        "Enquiry For",
        "Message",
        "Phone",
    ];

    const keys = [
        "created_at",
        "user",
        "user_type",
        "other_device",
        "description",
        "user_phone",
    ];

    const [servicesData, setServicesData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;
    const [cardData, setCardsData] = useState([
        {
            title: "Total Enquiries",
            count: "--",
            icon: <svg width="40" height="40" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="48" height="48" rx="8" fill="#1C64F2" />
                <path d="M11.2002 17.6004C11.2002 16.7517 11.5373 15.9378 12.1375 15.3376C12.7376 14.7375 13.5515 14.4004 14.4002 14.4004H33.6002C34.4489 14.4004 35.2628 14.7375 35.8629 15.3376C36.4631 15.9378 36.8002 16.7517 36.8002 17.6004V20.8004C35.9515 20.8004 35.1376 21.1375 34.5375 21.7376C33.9373 22.3378 33.6002 23.1517 33.6002 24.0004C33.6002 24.8491 33.9373 25.663 34.5375 26.2631C35.1376 26.8632 35.9515 27.2004 36.8002 27.2004V30.4004C36.8002 31.2491 36.4631 32.063 35.8629 32.6631C35.2628 33.2632 34.4489 33.6004 33.6002 33.6004H14.4002C13.5515 33.6004 12.7376 33.2632 12.1375 32.6631C11.5373 32.063 11.2002 31.2491 11.2002 30.4004V27.2004C12.0489 27.2004 12.8628 26.8632 13.4629 26.2631C14.0631 25.663 14.4002 24.8491 14.4002 24.0004C14.4002 23.1517 14.0631 22.3378 13.4629 21.7376C12.8628 21.1375 12.0489 20.8004 11.2002 20.8004V17.6004Z" fill="white" />
            </svg>,
        },
    ]);

    useEffect(() => {
        fetchData("");
    }, []);

    const fetchData = async (query) => {
        try {
            let respones = {};
            respones = await getEnquiriesApi("?search=" + query);
            if (respones?.status === 200) {
                setServicesData(respones?.data);
                setCardsData(
                    [
                        {
                            title: "Total Enquiries",
                            count: respones?.data?.count || 0,
                            icon: <svg width="40" height="40" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="48" height="48" rx="8" fill="#1C64F2" />
                                <path d="M11.2002 17.6004C11.2002 16.7517 11.5373 15.9378 12.1375 15.3376C12.7376 14.7375 13.5515 14.4004 14.4002 14.4004H33.6002C34.4489 14.4004 35.2628 14.7375 35.8629 15.3376C36.4631 15.9378 36.8002 16.7517 36.8002 17.6004V20.8004C35.9515 20.8004 35.1376 21.1375 34.5375 21.7376C33.9373 22.3378 33.6002 23.1517 33.6002 24.0004C33.6002 24.8491 33.9373 25.663 34.5375 26.2631C35.1376 26.8632 35.9515 27.2004 36.8002 27.2004V30.4004C36.8002 31.2491 36.4631 32.063 35.8629 32.6631C35.2628 33.2632 34.4489 33.6004 33.6002 33.6004H14.4002C13.5515 33.6004 12.7376 33.2632 12.1375 32.6631C11.5373 32.063 11.2002 31.2491 11.2002 30.4004V27.2004C12.0489 27.2004 12.8628 26.8632 13.4629 26.2631C14.0631 25.663 14.4002 24.8491 14.4002 24.0004C14.4002 23.1517 14.0631 22.3378 13.4629 21.7376C12.8628 21.1375 12.0489 20.8004 11.2002 20.8004V17.6004Z" fill="white" />
                            </svg>,
                        },
                    ]
                );
            } else {
                let errors = "Opps! Something went wrong, please try again.";
                showAlert("Error", errors, "error");
            }
        } catch (error) {
            let errors =
                error?.response?.data?.detail ||
                "Opps! Something went wrong, please try again.";
            showAlert("Error", errors, "error");
        }
    };

    // const handleViewDetails = (rowData) => {
    //     navigate(`/service-request/${rowData.id}`, { state: { rowData } });
    // };


    // const extraColumnRenderer = (row) => (
    //     <button
    //         className="text-primary bg-[#DEF7EC] px-3 py-1 rounded-lg hover:primary hover:text-primary"
    //         onClick={() => handleViewDetails(row)}
    //     >
    //         View More{">>"}
    //     </button>
    // );
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    const handleSearch = (query) => {
        fetchData(query);
    };

    return (
        <div className="">
            <Toast />
            {/* Top Section Begin */}
            <div className="flex gap-3 items-center font-medium text-gray-500">
                <AiFillHome />
                <p>Home</p>
                <IoIosArrowForward />
                <p>Enquiries</p>
            </div>
            {/* Top Section End */}
            {/* Add Faqs Begin */}
            <div className="w-[30%]">
                <SearchBar placeholder="Search in Enquiries..." onSearch={handleSearch} />
            </div>
            {/* Add Faqs End */}
            {/* Card Section Begin */}
            <div className="py-3">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
                    {cardData.map((card, index) => (
                        <Card title={card.title} count={card.count} icon={card.icon} bgColor={card.BgColor} TextColor={card.TextColor} />
                    ))}
                </div>
            </div>
            {/* Card Section End */}
            {/* Table Begin */}
            <div className="mt-2">
                <Table
                    headers={headers}
                    keys={keys}
                    rows={servicesData?.results}
                // extraColumn="Details"
                // extraColumnRenderer={extraColumnRenderer}
                />
            </div>
            {/* Table End */}
            {/* Pagination Begin */}
            <div className="xl:pl-5 pl-0">
                <Pagination
                    currentPage={currentPage}
                    totalPages={Math.ceil(servicesData?.count / pageSize)}
                    pageSize={pageSize}
                    totalEntries={servicesData?.count}
                    onPageChange={handlePageChange}
                    data={servicesData}
                    setData={setServicesData}
                />
            </div>
            {/* Pagination End */}
        </div>
    );
}

export default Enquiry;