import React from "react";
import { useField } from "formik";
import SelectSearch from "react-select"; // Importing react-select

const Select = ({
  disabled = false,
  label,
  options,
  required,
  rowData,
  faqsId,
  needSearch = false,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);

  // Handler for updating value when using react-select
  const handleChange = (selectedOption) => {
    helpers.setValue(selectedOption ? selectedOption.value : "");
  };

  return (
    <div className="form-control flex flex-col gap-2 p-2 z-10">
      <label htmlFor={props.id || props.name} className={`font-medium ${label.length !== 0 ? "flex" : "hidden"}`}>
        {label}
        {required && <span className="text-red-600 font-bold">*</span>}
      </label>

      {needSearch ? (
        <SelectSearch
          options={options}
          isDisabled={disabled}
          onChange={handleChange}
          value={options.find(option => option.value === field.value) || null}
          classNamePrefix="react-select"
          placeholder={rowData?.faq_type || `Select ${label}`}
          isClearable
        />
      ) : (
        <select
          {...field}
          {...props}
          className={`mt-1 block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${meta.error && meta.touched ? "border-red-500" : ""
            }`}
          disabled={disabled}
        >
          <option value="">
            {rowData?.faq_type || `Select ${label}`}
          </option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      )}

      {required && meta.error && meta.touched && (
        <p
          className="mt-2 text-sm text-red-500"
          id={`${props.id || props.name}-error`}
        >
          {meta.error}
        </p>
      )}
    </div>
  );
};

export default Select;
