import React, { useState } from "react";
import { FaTrash } from "react-icons/fa";
import { BsArrowsFullscreen } from "react-icons/bs";
import ExpandableImageModal from "./ExpandableImageModel";

const ExpandableImageComponent = ({ imageUrl, onDelete, width, height }) => {
    const [isModalOpen, setModalOpen] = useState(false);

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    return (
        <div>
            <div className={`relative ${width || "w-[100px]"} ${height || "h-[100px]"} rounded-lg overflow-hidden shadow-lg aspect-square`}>
                <img
                    src={imageUrl}
                    alt="Display"
                    className="w-full h-full object-cover"
                />
                {onDelete ?
                    <div className="absolute top-0 right-0">
                        <button
                            onClick={onDelete}
                            className="bg-white p-2 rounded-full text-red-500 hover:bg-red-600"
                        >
                            <FaTrash size={12} />
                        </button>
                    </div> : <></>}
                <div
                    className="absolute inset-0 flex justify-center items-center cursor-pointer"
                    onClick={openModal}
                >
                    <div className="bg-gray-200 p-2 rounded-full opacity-80">
                        <BsArrowsFullscreen size={25} />
                    </div>
                </div>
            </div>
            <ExpandableImageModal isOpen={isModalOpen} onClose={closeModal} imageUrl={imageUrl} />
        </div >
    );
};

export default ExpandableImageComponent;
