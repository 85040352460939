import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
  },
};

Modal.setAppElement("#root");

const ConfirmModal = ({
  isOpen,
  onRequestClose,
  contentLabel,
  onClose,
  setIsConfirmDelete,
  title,
  isLogout,
  icon = <svg
    class="text-gray-400 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto"
    aria-hidden="true"
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
      clip-rule="evenodd"
    ></path>
  </svg>
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel={contentLabel}
    >
      <div className="flex flex-col justify-center py-6 gap-3">
        <div className="flex justify-center">
        {icon}
        </div>
        <p className="mb-4 text-black dark:text-black flex justify-center font-medium">
          {title ? title : "Are you sure you want to delete this item?"}
        </p>
        <div className="flex justify-center items-center gap-4">
          <button
            data-modal-toggle="deleteModal"
            type="button"
            className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:outline-none hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
            onClick={() => onClose()}
          >
            No, Cancel
          </button>
          <button
            type="submit"
            className="py-2 px-3 text-sm font-medium text-center text-white bg-red-700 rounded-lg hover:bg-green-800 focus:outline-none"
            onClick={() => setIsConfirmDelete(true)}
          >
            Yes, I'm sure
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
