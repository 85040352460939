import { getCommodityApiUrl, commodityCRUDApiUrl, getDeviceDropDownApiUrl } from "../../constant/urls/apiUrls";
import axios from "axios";
import { get_token } from "../../constant/urls/localStoarege";

export const getCommodityApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.get(`${getCommodityApiUrl.replace("http://", "https://")}${data || ""}`, config);

  return response;
};


export const addCommodityApi = async (data) => {
  const config = {
    headers: {
      // "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.post(commodityCRUDApiUrl, data, config);

  return response;
};

export const editCommodityApi = async (data, id) => {
  const config = {
    headers: {
      // "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.patch(
    `${commodityCRUDApiUrl}${id}/`,
    data,
    config
  );

  return response;
};

export const deleteCommodityApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.delete(
    `${commodityCRUDApiUrl}${data}/`,
    config
  );

  return response;
};


export const getDeviceDropDownApi = async () => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.get(getDeviceDropDownApiUrl, config);

  return response;
};

