import React from "react";

const StarRating = ({ rating, maxStars = 5, starSize = "text-[30px]" }) => {
    const roundedRating = Math.floor(rating);

    const stars = [];
    for (let i = 1; i <= maxStars; i++) {
        if (i <= roundedRating) {
            stars.push(<span key={i} className={`text-yellow-500 ${starSize}`}>★</span>);
        } else if (i === Math.ceil(roundedRating) && roundedRating % 1 !== 0) {
            stars.push(<span key={i} className={`text-yellow-500 ${starSize}`}>☆</span>);
            stars.push(
                <span
                    key={`half-${i}`}
                    className={`absolute -ml-4 text-yellow-500 ${starSize}`}
                    style={{ clipPath: "polygon(0 0, 50% 0, 50% 100%, 0% 100%)", height: "30px" }}
                >
                    ★
                </span>
            );
        } else {
            stars.push(<span key={i} className={`text-gray-300 ${starSize}`}>★</span>);
        }
    }

    return (
        <div className="flex items-center space-x-1 relative">
            {stars}
        </div>
    );
};

export default StarRating;
