// src/components/Toast.js
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Toast = () => {
  return <ToastContainer />;
};

export const showSuccessToast = (message) => {
  toast.success(message, {
    position: toast.POSITION ? toast.POSITION.TOP_RIGHT : "top-right",
  });
};

export const showErrorToast = (message) => {
  toast.error(message, {
    position: toast.POSITION ? toast.POSITION.TOP_RIGHT : "top-right",
  });
};

export default Toast;
