export const get_token = () => {
  return localStorage?.getItem("auth_token");
};

export const get_user_id = () => {
  return localStorage?.getItem("user_id");
};

export const get_role = () => {
  return localStorage?.getItem("role");
};
