import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { deleteDeviceApi, getDevicesApi as getDevicesApi } from "../../services/apis/device";
import Toast, { showSuccessToast } from "../../components/toastify/Toast";
import showAlert from "../../components/errorBoundries/Errors";
import { MdDelete } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { AiFillHome } from "react-icons/ai";
import { IoIosArrowForward, IoMdAdd, IoMdCloudDownload, IoMdEye } from "react-icons/io";
import SearchBar from "../../components/searchBar/SearchBar";
import Card from "../../components/cards/Card";
import Tab from "../marketing/faqs/Tabs";
import Table from "../../components/tables/Table";
import Pagination from "../../components/pagination/Pagination";
import ConfirmModal from "../../components/popups/confirmationModal/ConfirmModal";
import { generateDeviceExcelApiUrl, generateDevicePdfApiUrl } from "../../constant/urls/apiUrls";

const Device = () => {
  const navigate = useNavigate();
  const headers = ["Name", "User", "Serial No.", "Mac ID", "Type", "Plant", "Pincode", "Date"];
  const keys = ["device_name", "user", "serial_no", "mac_id", "device_type", "plant", "pincode", "created_at"];
  const [modalIsOpen, setIsOpen] = useState(false);
  const [membersData, setMembersData] = useState({});
  const [faqsId, setFaqsId] = useState("");
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const availableIcons = [
    <svg width="40" height="40" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="8" fill="#1C64F2" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M19.2 11.2002C18.3513 11.2002 17.5374 11.5373 16.9373 12.1375C16.3371 12.7376 16 13.5515 16 14.4002V33.6002C16 34.4489 16.3371 35.2628 16.9373 35.8629C17.5374 36.4631 18.3513 36.8002 19.2 36.8002H28.8C29.6487 36.8002 30.4626 36.4631 31.0627 35.8629C31.6629 35.2628 32 34.4489 32 33.6002V14.4002C32 13.5515 31.6629 12.7376 31.0627 12.1375C30.4626 11.5373 29.6487 11.2002 28.8 11.2002H19.2ZM24 33.6002C24.4243 33.6002 24.8313 33.4316 25.1314 33.1316C25.4314 32.8315 25.6 32.4245 25.6 32.0002C25.6 31.5758 25.4314 31.1689 25.1314 30.8688C24.8313 30.5688 24.4243 30.4002 24 30.4002C23.5757 30.4002 23.1687 30.5688 22.8686 30.8688C22.5686 31.1689 22.4 31.5758 22.4 32.0002C22.4 32.4245 22.5686 32.8315 22.8686 33.1316C23.1687 33.4316 23.5757 33.6002 24 33.6002V33.6002Z" fill="white" />
    </svg>,
    <svg width="40" height="40" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="8" fill="#1BA005" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M19.2 11.2002C18.3513 11.2002 17.5374 11.5373 16.9373 12.1375C16.3371 12.7376 16 13.5515 16 14.4002V33.6002C16 34.4489 16.3371 35.2628 16.9373 35.8629C17.5374 36.4631 18.3513 36.8002 19.2 36.8002H28.8C29.6487 36.8002 30.4626 36.4631 31.0627 35.8629C31.6629 35.2628 32 34.4489 32 33.6002V14.4002C32 13.5515 31.6629 12.7376 31.0627 12.1375C30.4626 11.5373 29.6487 11.2002 28.8 11.2002H19.2ZM24 33.6002C24.4243 33.6002 24.8313 33.4316 25.1314 33.1316C25.4314 32.8315 25.6 32.4245 25.6 32.0002C25.6 31.5758 25.4314 31.1689 25.1314 30.8688C24.8313 30.5688 24.4243 30.4002 24 30.4002C23.5757 30.4002 23.1687 30.5688 22.8686 30.8688C22.5686 31.1689 22.4 31.5758 22.4 32.0002C22.4 32.4245 22.5686 32.8315 22.8686 33.1316C23.1687 33.4316 23.5757 33.6002 24 33.6002V33.6002Z" fill="white" />
    </svg>,
    <svg width="40" height="40" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="8" fill="#CC0000" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M19.2 11.2002C18.3513 11.2002 17.5374 11.5373 16.9373 12.1375C16.3371 12.7376 16 13.5515 16 14.4002V33.6002C16 34.4489 16.3371 35.2628 16.9373 35.8629C17.5374 36.4631 18.3513 36.8002 19.2 36.8002H28.8C29.6487 36.8002 30.4626 36.4631 31.0627 35.8629C31.6629 35.2628 32 34.4489 32 33.6002V14.4002C32 13.5515 31.6629 12.7376 31.0627 12.1375C30.4626 11.5373 29.6487 11.2002 28.8 11.2002H19.2ZM24 33.6002C24.4243 33.6002 24.8313 33.4316 25.1314 33.1316C25.4314 32.8315 25.6 32.4245 25.6 32.0002C25.6 31.5758 25.4314 31.1689 25.1314 30.8688C24.8313 30.5688 24.4243 30.4002 24 30.4002C23.5757 30.4002 23.1687 30.5688 22.8686 30.8688C22.5686 31.1689 22.4 31.5758 22.4 32.0002C22.4 32.4245 22.5686 32.8315 22.8686 33.1316C23.1687 33.4316 23.5757 33.6002 24 33.6002V33.6002Z" fill="white" />
    </svg>,
    <svg width="40" height="40" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="8" fill="#6B7280" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M19.2 11.2C18.3513 11.2 17.5374 11.5371 16.9373 12.1372C16.3371 12.7373 16 13.5513 16 14.4V33.5999C16 34.4486 16.3371 35.2626 16.9373 35.8627C17.5374 36.4628 18.3513 36.7999 19.2 36.7999H28.8C29.6487 36.7999 30.4626 36.4628 31.0627 35.8627C31.6629 35.2626 32 34.4486 32 33.5999V14.4C32 13.5513 31.6629 12.7373 31.0627 12.1372C30.4626 11.5371 29.6487 11.2 28.8 11.2H19.2ZM24 33.5999C24.4243 33.5999 24.8313 33.4314 25.1314 33.1313C25.4314 32.8313 25.6 32.4243 25.6 31.9999C25.6 31.5756 25.4314 31.1686 25.1314 30.8686C24.8313 30.5685 24.4243 30.4 24 30.4C23.5757 30.4 23.1687 30.5685 22.8686 30.8686C22.5686 31.1686 22.4 31.5756 22.4 31.9999C22.4 32.4243 22.5686 32.8313 22.8686 33.1313C23.1687 33.4314 23.5757 33.5999 24 33.5999V33.5999Z" fill="white" />
    </svg>,
    <svg width="40" height="40" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="8" fill="#111928" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M19.2 11.2C18.3513 11.2 17.5374 11.5371 16.9373 12.1372C16.3371 12.7373 16 13.5513 16 14.4V33.5999C16 34.4486 16.3371 35.2626 16.9373 35.8627C17.5374 36.4628 18.3513 36.7999 19.2 36.7999H28.8C29.6487 36.7999 30.4626 36.4628 31.0627 35.8627C31.6629 35.2626 32 34.4486 32 33.5999V14.4C32 13.5513 31.6629 12.7373 31.0627 12.1372C30.4626 11.5371 29.6487 11.2 28.8 11.2H19.2ZM24 33.5999C24.4243 33.5999 24.8313 33.4314 25.1314 33.1313C25.4314 32.8313 25.6 32.4243 25.6 31.9999C25.6 31.5756 25.4314 31.1686 25.1314 30.8686C24.8313 30.5685 24.4243 30.4 24 30.4C23.5757 30.4 23.1687 30.5685 22.8686 30.8686C22.5686 31.1686 22.4 31.5756 22.4 31.9999C22.4 32.4243 22.5686 32.8313 22.8686 33.1313C23.1687 33.4314 23.5757 33.5999 24 33.5999V33.5999Z" fill="white" />
    </svg>,
  ];
  const [cardData, setCardData] = useState([
    {
      icon: availableIcons[0],
      title: "Total Devices",
      count: "--",
    },
    {
      icon: availableIcons[1],
      title: "Active Devices",
      count: "--",
    },
    {
      icon: availableIcons[2],
      title: "Disabled Devices",
      count: "--",
    },
    {
      icon: availableIcons[3],
      title: "Online Devices",
      count: "--",
    },
    {
      icon: availableIcons[4],
      title: "Offline Devices",
      count: "--",
    },
  ]);
  const pageSize = 10;

  useEffect(() => {
    fetchData("");
  }, []);

  const fetchData = async (query) => {
    try {
      const respones = await getDevicesApi("?search=" + query);
      if (respones?.status === 200) {
        setMembersData(respones?.data);
        setCardData([
          {
            icon: availableIcons[0],
            title: "Total Devices",
            count: respones?.data.extra_data.total_device,
          },
          {
            icon: availableIcons[1],
            title: "Active Devices",
            count: respones?.data.extra_data.active_device,
          },
          {
            icon: availableIcons[2],
            title: "Disabled Devices",
            count: respones?.data.extra_data.disabled_device,
          },
          {
            icon: availableIcons[3],
            title: "Online Devices",
            count: respones?.data.extra_data.online_device || "--",
          },
          {
            icon: availableIcons[4],
            title: "Offline Devices",
            count: respones?.data.extra_data.offline_device || "--",
          },
        ]
        );
      } else {
        let errors = "Opps! Something went wrong, please try again.";
        showAlert("Error", errors, "error");
      }
    } catch (error) {
      let errors =
        error?.response?.data?.detail ||
        "Opps! Something went wrong, please try again.";
      showAlert("Error", errors, "error");
    }
  };

  const openModal = (row) => {
    setIsOpen(true);
    setFaqsId(row?.id);
    setIsConfirmDelete(false);
  };

  const closeModal = () => {
    setIsOpen(false);
    setIsConfirmDelete(false);
  };

  const handleDownloadPdf = () => {
    window.open(generateDevicePdfApiUrl, "_blank");
  };

  const handleDownloadExcel = () => {
    window.open(generateDeviceExcelApiUrl, "_blank");
  };

  useEffect(() => {
    if (isConfirmDelete === true) {
      const fetchData = async () => {
        try {
          const respones = await deleteDeviceApi(faqsId);
          if (respones?.status === 204) {
            try {
              const respones = await getDevicesApi();
              if (respones?.status === 200) {
                setMembersData(respones?.data);
              } else {
                let errors = "Opps! Something went wrong, please try again.";
                showAlert("Error", errors, "error");
              }
            } catch (error) {
              let errors =
                error?.response?.data?.detail ||
                "Opps! Something went wrong, please try again.";
              showAlert("Error", errors, "error");
            }
            setIsOpen(false);
            showSuccessToast("Device deleted successfully!");
          } else {
            let errors = "Opps! Something went wrong, please try again.";
            showAlert("Error", errors, "error");
          }
        } catch (error) {
          let errors =
            error?.response?.data?.detail ||
            "Opps! Something went wrong, please try again.";
          showAlert("Error", errors, "error");
        }
      };
      fetchData();
    }
  }, [isConfirmDelete]);
  const handleEdit = (rowData) => {
    navigate(`/device/edit`, { state: { rowData } });
  };

  const handleViewDetail = (rowData) => {
    navigate(`/device/${rowData.id}`);
  };

  const extraColumnRenderer = (row) => (
    <div className="flex space-x-2">
      <FiEdit
        size={23}
        onClick={() => handleEdit(row)}
        className="cursor-pointer text-primary"
      />
      <MdDelete
        size={25}
        onClick={() => openModal(row)}
        className="cursor-pointer text-red-600"
      />
      <IoMdEye
        size={25}
        onClick={() => handleViewDetail(row)}
        className="cursor-pointer text-[#65558F]"
      />
    </div>
  );
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleSearch = (query) => {
    fetchData(query);
  };

  return (
    <div className="">
      <Toast />
      {/* Top Section Begin */}
      <div className="flex gap-3 items-center font-medium text-gray-500">
        <AiFillHome />
        <p>Home</p>
        <IoIosArrowForward />
        <p>Devices</p>
      </div>
      {/* Top Section End */}
      {/* Add Faqs Begin */}
      <div className="flex justify-between pt-3">
        <div className="w-[30%]">
          <SearchBar placeholder="Search in Devices..." onSearch={handleSearch} />
        </div>
        <div className="flex flex-row">
          <div className="mr-4">
            <button
              type="button"
              className="text-black border border-gray-300 hover:bg-primary-dark font-sm rounded-lg text-md w-full px-3 py-2.5 text-center flex items-center gap-1 font-semibold"
              onClick={handleDownloadPdf}
            >
              <IoMdCloudDownload style={{ TextColor: "black" }} size={23} />
              Pdf
            </button>
          </div>
          <div className="mr-4">
            <button
              type="button"
              className="text-black border border-gray-300 hover:bg-primary-dark font-sm rounded-lg text-md w-full px-3 py-2.5 text-center flex items-center gap-1 font-semibold"
              onClick={handleDownloadExcel}
            >
              <IoMdCloudDownload style={{ TextColor: "black" }} size={23} />
              Excel
            </button>
          </div>
          <div>
            <Link to="/device/add">
              <button
                type="button"
                className="text-white bg-primary hover:bg-primary-dark font-sm rounded-lg text-md w-full px-3 py-2.5 text-center flex items-center gap-1"
              >
                <IoMdAdd style={{ color: "white" }} size={23} />
                Add Device
              </button>
            </Link>
          </div>
        </div>
      </div>
      {/* Add Faqs End */}
      {/* Card Section Begin */}
      <div className="py-3">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
          {cardData.map((card, index) => (
            <Card title={card.title} count={card.count} icon={card.icon} />
          ))}
        </div>
      </div>
      {/* Card Section End */}
      {/* Tabs Section Begin */}
      <div className="lg:pl-2">
        <Tab />
      </div>
      {/* Tabs Section End */}
      {/* Table Begin */}
      <div className="mt-2">
        <Table
          headers={headers}
          keys={keys}
          rows={membersData?.results}
          extraColumn="Actions"
          extraColumnRenderer={extraColumnRenderer}
        />
      </div>
      {/* Table End */}
      {/* Pagination Begin */}
      <div className="xl:pl-5 pl-0">
        <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil(membersData?.count / pageSize)}
          pageSize={pageSize}
          totalEntries={membersData?.count}
          onPageChange={handlePageChange}
          data={membersData}
          setData={setMembersData}
        />
      </div>
      {/* Pagination End */}
      <ConfirmModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Add Device Type Modal"
        onClose={closeModal}
        setIsConfirmDelete={setIsConfirmDelete}
      />
    </div>
  );
};

export default Device;
