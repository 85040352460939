import {
  getTutorialApiUrl,
  addTutorialApiUrl,
  editTutorialApiUrl,
  deleteTutorialApiUrl,
} from "../../constant/urls/apiUrls";
import axios from "axios";
import { get_token } from "../../constant/urls/localStoarege";

export const getTutotialApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.get(`${getTutorialApiUrl.replace("http://", "https://")}${data || ""}`, config);

  return response;
};

export const addTutorialApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.post(addTutorialApiUrl, data, config);

  return response;
};

export const editTutorialApi = async (data, id) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.patch(
    `${editTutorialApiUrl}${id}/`,
    data,
    config
  );

  return response;
};

export const deleteTutorialApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.delete(
    `${deleteTutorialApiUrl}${data}/`,
    config
  );

  return response;
};
