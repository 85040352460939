import React from "react";
import { get_token } from "../../constant/urls/localStoarege";
import showAlert from "../errorBoundries/Errors";

const Pagination = ({
  currentPage,
  pageSize,
  totalEntries,
  data,
  setData,
  onPageChange,
}) => {
  const startEntry = (currentPage - 1) * pageSize + 1;
  const endEntry = Math.min(currentPage * pageSize, totalEntries);

  const handlePrevious = () => {
    if (data?.previous) {
      fetchPage(data.previous.replace("http://", "https://"));
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (data?.next) {
      fetchPage(`${data.next.replace("http://", "https://")}`);
      onPageChange(currentPage + 1);
    }
  };

  const fetchPage = async (link) => {
    try {
      const response = await fetch(link, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${get_token()}`,
        },
      });

      if (response.ok) {
        const newData = await response.json();
        setData(newData);
      } else {
        throw new Error(`Failed to fetch: ${response.status}`);
      }
    } catch (error) {
      let errors =
        error?.response?.data?.error ||
        "Opps! Something went wrong, please try again.";
      showAlert("Error", errors, "error");
    }
  };

  return (
    <div className="flex justify-between items-center py-4">
      {data && data?.results && data?.results?.length > 0 ? (
        <div className="text-md text-black">
          Showing <span className="font-bold">Page: {currentPage}{" "}</span>of{" "}
          {/* Showing <span className="font-bold">{startEntry}</span> to{" "}
          <span className="font-bold">{endEntry}</span> of{" "} */}
          <span className="font-bold">{Math.ceil(totalEntries / pageSize)}</span> Pages
        </div>
      ) : (
        <div></div>
      )}

      <div className="flex space-x-2">
        <button
          onClick={handlePrevious}
          disabled={!data?.previous}
          className="px-3 py-1 bg-primary text-white rounded disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Previous
        </button>
        <button
          onClick={handleNext}
          disabled={!data?.next}
          className="px-3 py-1 bg-primary text-white rounded disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Pagination;
