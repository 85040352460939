import { get_role } from "../constant/urls/localStoarege";

export const allowProduction = () => {
    return get_role() === "production";
}

export const allowService = () => {
    return get_role() === "service";
}

export const allowSales = () => {
    return get_role() === "sales";
}

export const allowMarketing = () => {
    return get_role() === "marketing";
}

export const allowAdmin = () => {
    return get_role() === "admin";
}

export const allowRnd = () => {
    return get_role() === "rnd";
}

export const allowAll = () => {
    return get_role() === "production"
        || get_role() === "service"
        || get_role() === "sales"
        || get_role() === "marketing"
        || get_role() === "admin"
        || get_role() === "rnd";
}