import axios from "axios";
import { get_token } from "../../constant/urls/localStoarege";
import { dashboardGetView } from "../../constant/urls/apiUrls";

export const getDashboardApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.get(dashboardGetView.replace("http://", "https://"), config);

  return response;
};
