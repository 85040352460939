import React from "react";
import Sidebar from "../components/sidebar/Sidebar";
import Header from "../components/header/Header";

const Layout = ({ children, noSidebar }) => {
  return (
    <div className="flex flex-col h-screen bg-[#F4FAF1]">
      {!noSidebar && <Header />}
      <div className="flex flex-1 h-screen overflow-hidden">
        {!noSidebar && <Sidebar />}
        <main className="flex-1 overflow-y-auto p-4 sm:ml-1">{children}</main>
      </div>
    </div>
  );
};

export default Layout;
