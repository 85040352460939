import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { AiFillHome } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import { PiUploadFill } from "react-icons/pi";
import InputField from "../../../components/inputFields/InputField";
import Button from "../../../components/buttons/Button";
import Select from "../../../components/inputFields/SelectField";
import TextareaField from "../../../components/inputFields/TextField";
import { addCommunityApi } from "../../../services/apis/community";
import Toast, { showSuccessToast } from "../../../components/toastify/Toast";
import { useNavigate } from "react-router-dom";
import { get_user_id } from "../../../constant/urls/localStoarege";
import showAlert from "../../../components/errorBoundries/Errors";

const AddCommunity = () => {
  const navigate = useNavigate();
  const userId = get_user_id();
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState("");

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleProfileClick = () => {
    document.getElementById("fileInput").click();
  };

  const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const formData = new FormData();
      formData.append("image", selectedFile);
      formData.append("post_type", values.post_type);
      formData.append("title", values.post_title);
      formData.append("description", values.post_description);
      formData.append("link", values.add_link);
      formData.append("created_by", userId);

      const response = await addCommunityApi(formData);

      if (response.status === 201) {
        showSuccessToast("Community created successfully!");
        navigate("/community");
        resetForm();
      } else {
        showAlert(
          "Error",
          "Opps! Something went wrong, please try again.",
          "error"
        );
      }
    } catch (error) {
      showAlert(
        "Error",
        "Opps! Something went wrong, please try again.",
        "error"
      );
    }
    setSubmitting(false);
  };
  const handleDiscardChanges = (resetForm) => {
    navigate("/community");
  };

  const options = [
    { value: "General", label: "General" },
    { value: "Updates", label: "Updates" },
    { value: "News", label: "News" },
  ];

  return (
    <div className="relative">
      <Toast />
      {/* Top Section Begin */}
      <div className="flex gap-3 items-center font-medium text-gray-500">
        <AiFillHome />
        <p>Home</p>
        <IoIosArrowForward />
        <p>Community</p>
        <IoIosArrowForward />
        <p>Add New Post</p>
      </div>
      {/* Top Section End */}
      {/* Form Begin */}
      <div className="mt-8">
        <Formik
          initialValues={{
            post_type: "",
            post_title: "",
            add_link: "",
            post_description: "",
          }}
          validationSchema={Yup.object({
            post_title: Yup.string().required("Please enter post title"),
            post_type: Yup.string().required("Please select post type"),
            // add_link: Yup.string().required("Please enter link "),
            post_description: Yup.string().required(
              "Please Enter post description"
            ),
          })}
          onSubmit={handleFormSubmit}
        >
          {({ isSubmitting, resetForm }) => (
            <Form>
              <div className="border border-gray-300 mt-5 p-4 rounded-lg bg-gray-100">
                <div className="w-[200px]">
                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                    required
                  />
                  <div
                    onClick={handleProfileClick}
                    style={{ cursor: "pointer" }}
                  >
                    {selectedImage ? (
                      <div className="w-[200px] h-[200px] rounded-lg">
                        <img
                          src={selectedImage}
                          alt="Profile"
                          className="w-full h-full object-cover rounded-lg"
                        />
                      </div>
                    ) : (
                      <div className="border-dashed border-2 border-gray-300 rounded-lg w-[200px] h-[200px] flex flex-col justify-center items-center bg-[#ffffff]">
                        <PiUploadFill size={35} className="text-gray-400" />
                        <p className="text-gray-400 font-medium ">
                          Click to upload Image
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="w-full mb-1 flex gap-3 items-center mt-6">
                  <Select
                    label="Post Type"
                    name="post_type"
                    options={options}
                    required
                  />
                </div>
                <div className="w-full mb-1">
                  <div className="grid gap-6 mb-1 md:grid-cols-2">
                    <InputField
                      label="Post Title"
                      name="post_title"
                      type="text"
                      placeholder="Enter post title"
                      required
                    />
                    <InputField
                      label="Add Link"
                      name="add_link"
                      type="text"
                      placeholder="Enter or Paste link..."
                      false
                    />
                  </div>
                </div>
                <div className="w-full mb-1">
                  <TextareaField
                    label="Post Description"
                    name="post_description"
                    placeholder="Enter post description"
                    required
                  />
                </div>
              </div>
              <div className="py-5 flex justify-end gap-6">
                <div>
                  <Button
                    type="button"
                    className="text-primary bg-white hover:bg-white-dark font-sm rounded-lg text-lg w-full px-8 py-1.5 text-center border border-primary"
                    onClick={() => handleDiscardChanges(resetForm)}
                    disabled={isSubmitting}
                  >
                    Discard Changes
                  </Button>
                </div>
                <div>
                  <Button
                    type="submit"
                    className="text-white bg-primary hover:bg-primary-dark font-sm rounded-lg text-lg w-full px-8 py-1.5 text-center"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {/* Form End */}
    </div>
  );
};

export default AddCommunity;
