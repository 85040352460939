import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiFillHome } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import { MdStars } from "react-icons/md";
import { HiRefresh } from "react-icons/hi";

import SearchBar from "../../../components/searchBar/SearchBar";
import Card from "../../../components/cards/Card";
import Table from "../../../components/tables/Table";
import Pagination from "../../../components/pagination/Pagination";
import { getFeedbackApi } from "../../../services/apis/feedback";
import showAlert from "../../../components/errorBoundries/Errors";
import { handleRefresh } from "../../../utils/handle_refresh";

const Feedbacks = () => {
  const navigate = useNavigate();
  const headers = ["Date", "Experience", "Review Description", "Ratings"];
  const keys = [
    "created_at",
    "experience_number",
    "description",
    "star_rating",
  ];
  const [feedbackData, setFeedbackData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const fetchData = async (data) => {
    try {
      const response = await getFeedbackApi(`?search=${data}`);
      if (response?.status === 200) {
        setFeedbackData(response?.data);
      } else {
        let errors = "Oops! Something went wrong, please try again.";
        showAlert("Error", errors, "error");
      }
    } catch (error) {
      let errors =
        error?.response?.data?.detail ||
        "Oops! Something went wrong, please try again.";
      showAlert("Error", errors, "error");
    }
  };

  useEffect(() => {
    fetchData("");
  }, []);

  const cardData = [
    { title: "Total FAQs", count: 12, icon: <MdStars size={40} /> },
  ];

  const handleViewMore = (rowData) => {
    navigate(`/feedbacks/${rowData.id}`, { state: { rowData } });
  };

  const extraColumnRenderer = (row) => (
    <button
      className="text-primary bg-[#DEF7EC] px-3 py-1 rounded-lg hover:primary hover:text-primary"
      onClick={() => handleViewMore(row)}
    >
      View More{">>"}
    </button>
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = (query) => {
    fetchData(query);
  };
  return (
    <div className="">
      {/* Top Section Begin */}
      <div className="flex gap-3 items-center font-medium text-gray-500">
        <AiFillHome />
        <p>Home</p>
        <IoIosArrowForward />
        <p>Feedbacks & Reviews</p>
      </div>
      {/* Top Section End */}
      {/* Add Tutorial Begin */}
      <div className="flex justify-between pt-3">
        <div className="w-[30%]">
          <SearchBar
            placeholder="Search in Feedbacks..."
            onSearch={handleSearch}
          />
        </div>
        <div>
          <button
            type="button"
            onClick={handleRefresh}
            className="text-[#374151] bg-[#F3F4F6] hover:bg-primary-dark font-sm rounded-lg text-lg w-full px-5 py-2.5 text-center flex items-center gap-1 border border-gray-200 font-semibold"
          >
            <HiRefresh style={{ color: "#374151" }} size={23} />
            Refresh
          </button>
        </div>
      </div>
      {/* Add Tutorial End */}
      {/* Card Section Begin */}
      <div className="w-[280px] py-3">
        <Card
          title="Rating Count"
          count={feedbackData?.count}
          icon={<MdStars size={40} />}
        />
      </div>
      {/* Card Section End */}
      {/* Table Begin */}
      <div className="mt-2">
        <Table
          headers={headers}
          keys={keys}
          rows={feedbackData?.results}
          extraColumn="Detail"
          extraColumnRenderer={extraColumnRenderer}
        />
      </div>
      {/* Table End */}
      {/* Pagination Begin */}
      <div className="xl:pl-5 pl-0">
        <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil(feedbackData?.count / pageSize)}
          pageSize={pageSize}
          totalEntries={feedbackData?.count}
          onPageChange={handlePageChange}
          data={feedbackData}
          setData={setFeedbackData}
        />
      </div>
      {/* Pagination End */}
    </div>
  );
};

export default Feedbacks;
