import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { AiFillHome } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import InputField from "../../../components/inputFields/InputField";
import Button from "../../../components/buttons/Button";
import Toast, { showSuccessToast } from "../../../components/toastify/Toast";
import showAlert from "../../../components/errorBoundries/Errors";
import { get_user_id } from "../../../constant/urls/localStoarege";
import { editOtherdevicesApi } from "../../../services/apis/otherdevices";

const EditOtherDevice = () => {
  const navigate = useNavigate();
  const userId = get_user_id();
  const location = useLocation();
  const { rowData } = location.state;

  const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const payload = {
        name: values?.name,
        created_by: userId,
      };

      const response = await editOtherdevicesApi(payload, rowData?.id);

      if (response?.status === 200) {
        showSuccessToast("Device Edited successfully!");

        navigate("/other-devices");

        if (typeof resetForm === "function") {
          resetForm();
        }
      } else {
        let errors = "Opps! Something went wrong, please try again.";
        showAlert("Error", errors, "error");
      }
    } catch (error) {
      let errors =
        error?.response?.data?.error ||
        "Opps! Something went wrong, please try again.";
      showAlert("Error", errors, "error");
    }
    setSubmitting(false);
  };

  const handleDiscardChanges = (resetForm) => {
    navigate("/other-devices");
  };

  return (
    <div className="pl-5">
      <Toast />
      {/* Top Section Begin */}
      <div className="flex gap-3 items-center font-medium text-gray-500">
        <AiFillHome />
        <p>Home</p>
        <IoIosArrowForward />
        <p>Other Devices</p>
        <IoIosArrowForward />
        <p>Edit Device</p>
      </div>
      {/* Top Section End */}
      {/* Form Begin */}
      <div className="mt-8">
        <Formik
          initialValues={{
            name: rowData?.name || "",
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Please Provide a name"),
          })}
          onSubmit={handleFormSubmit}
        >
          {({ isSubmitting, resetForm }) => (
            <Form>
              <div className="border border-gray-300 mt-5 p-4 rounded-lg bg-gray-100">
                <div className="w-full mb-1">
                  <InputField
                    label="Device Name"
                    name="name"
                    type="text"
                    placeholder="Solar Dryer"
                    required
                  />
                </div>
              </div>
              <div className="py-5 flex justify-end gap-6">
                <div>
                  <Button
                    type="button"
                    className="text-primary bg-white hover:bg-white-dark font-sm rounded-lg text-lg w-full px-8 py-1.5 text-center border border-primary"
                    onClick={() => handleDiscardChanges(resetForm)}
                    disabled={isSubmitting}
                  >
                    Discard Changes
                  </Button>
                </div>
                <div>
                  <Button
                    type="submit"
                    className="text-white bg-primary hover:bg-primary-dark font-sm rounded-lg text-lg w-full px-8 py-1.5 text-center"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {/* Form End */}
    </div>
  );
};

export default EditOtherDevice;
