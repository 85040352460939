import React, { useState } from "react";

const Chatbot = () => {
  const [messages, setMessages] = useState([
    {
      text: "hi",
      sender: "user",
    },
    {
      text: "hi",
      sender: "",
    },
  ]);
  const [messageInput, setMessageInput] = useState("");
  const sendMessage = () => {
    if (messageInput.trim() !== "") {
      setMessageInput("");
    }
  };
  return (
    <div className="flex flex-col h-screen">
      {/* Chat header with contact name */}
      <div className="bg-gray-200 py-2 px-4">
        <h2 className="text-lg font-semibold">Chat with John Doe</h2>
      </div>

      {/* Chat messages area */}
      <div className="flex-1 overflow-y-auto px-4 py-2">
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`my-2 ${
              msg.sender === "user"
                ? "self-end bg-blue-500 text-white"
                : "bg-gray-300"
            } p-2 rounded-lg max-w-xs`}
          >
            <p>{msg.text}</p>
          </div>
        ))}
      </div>

      {/* Message input area */}
      <div className="bg-gray-200 py-2 px-4">
        <div className="flex items-center">
          <input
            type="text"
            value={messageInput}
            onChange={(e) => setMessageInput(e.target.value)}
            className="flex-1 p-2 rounded-full bg-white focus:outline-none"
            placeholder="Type a message..."
          />
          <button
            onClick={sendMessage}
            className="ml-2 p-2 rounded-full bg-blue-500 text-white"
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default Chatbot;
