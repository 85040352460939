import React, { useState, useEffect, useRef, useMemo } from 'react';
import { RemoveScroll } from 'react-remove-scroll';

function DashboardLineChart({ data = [] }) {
    const canvasRef = useRef(null);
    const containerRef = useRef(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const [isDragging, setIsDragging] = useState(false);
    const [dragStartX, setDragStartX] = useState(null);
    const [allowScroll, setAllowScroll] = useState(false);
    const [hoveredPoint, setHoveredPoint] = useState(null);
    const [mousePosition, setMousePosition] = useState(null);

    const handleMouseEnter = () => {
        setAllowScroll(true);
    };

    const handleMouseLeave = () => {
        setAllowScroll(false);
        setHoveredPoint(null);
        setMousePosition(null);
    };

    const processedData = useMemo(() => {
        if (!data) return [];
        let dataArray = [];
        try {
            dataArray = Array.isArray(data) ? data : Object.values(data);
        } catch (e) {
            console.error('Invalid data format:', e);
            return [];
        }
        return dataArray.filter(item =>
            item &&
            typeof item.temperature !== 'undefined' &&
            typeof item.humidity !== 'undefined' &&
            typeof item.formatted_created_at !== 'undefined'
        );
    }, [data]);

    const [zoomDomain, setZoomDomain] = useState([0, Math.max(0, processedData.length - 1)]);

    useEffect(() => {
        setZoomDomain([0, Math.max(0, processedData.length - 1)]);
    }, [processedData]);

    const PADDING = { top: 40, right: 60, bottom: 60, left: 60 };
    const ZOOM_FACTOR = 1.1;
    const TICK_COUNT = 6;

    useEffect(() => {
        const updateDimensions = () => {
            if (containerRef.current) {
                const { width, height } = containerRef.current.getBoundingClientRect();
                setDimensions({ width, height });
            }
        };

        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    useEffect(() => {
        if (!canvasRef.current || processedData.length === 0) return;

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const dpr = window.devicePixelRatio || 1;

        canvas.width = dimensions.width * dpr;
        canvas.height = dimensions.height * dpr;
        canvas.style.width = `${dimensions.width}px`;
        canvas.style.height = `${dimensions.height}px`;
        ctx.scale(dpr, dpr);

        ctx.clearRect(0, 0, dimensions.width, dimensions.height);

        const startIndex = Math.max(0, Math.floor(zoomDomain[0]));
        const endIndex = Math.min(processedData.length - 1, Math.ceil(zoomDomain[1]));
        const zoomedData = processedData.slice(startIndex, endIndex + 1).reverse(); // Reverse the data

        if (zoomedData.length === 0) return;

        const xScale = (dimensions.width - PADDING.left - PADDING.right) / Math.max(1, zoomedData.length - 1);
        const tempMin = Math.min(...zoomedData.map(d => d.temperature));
        const tempMax = Math.max(...zoomedData.map(d => d.temperature));
        const humidityMin = Math.min(...zoomedData.map(d => d.humidity));
        const humidityMax = Math.max(...zoomedData.map(d => d.humidity));

        const yScaleTemp = (dimensions.height - PADDING.top - PADDING.bottom) /
            (Math.max(0.1, tempMax - tempMin));
        const yScaleHumidity = (dimensions.height - PADDING.top - PADDING.bottom) /
            (Math.max(0.1, humidityMax - humidityMin));

        // Drawing the axes
        ctx.beginPath();
        ctx.strokeStyle = '#ccc';
        ctx.moveTo(PADDING.left, PADDING.top);
        ctx.lineTo(PADDING.left, dimensions.height - PADDING.bottom);
        ctx.lineTo(dimensions.width - PADDING.right, dimensions.height - PADDING.bottom);
        ctx.stroke();

        // Drawing temperature line
        if (zoomedData.length > 1) {
            ctx.beginPath();
            ctx.strokeStyle = '#ef4444';
            ctx.lineWidth = 2;
            zoomedData.forEach((point, i) => {
                const x = PADDING.left + i * xScale;
                const y = dimensions.height - PADDING.bottom -
                    (point.temperature - tempMin) * yScaleTemp;
                if (i === 0) ctx.moveTo(x, y);
                else ctx.lineTo(x, y);
            });
            ctx.stroke();
        }

        // Drawing humidity line
        if (zoomedData.length > 1) {
            ctx.beginPath();
            ctx.strokeStyle = '#3b82f6';
            ctx.lineWidth = 2;
            zoomedData.forEach((point, i) => {
                const x = PADDING.left + i * xScale;
                const y = dimensions.height - PADDING.bottom -
                    (point.humidity - humidityMin) * yScaleHumidity;
                if (i === 0) ctx.moveTo(x, y);
                else ctx.lineTo(x, y);
            });
            ctx.stroke();
        }

        // Tooltip rendering (if needed)
        if (hoveredPoint && mousePosition) {
            ctx.save();
            const { temperature, humidity } = hoveredPoint;
            const { x, y } = mousePosition;

            const tooltipWidth = 120;
            const tooltipHeight = 40;
            const tooltipX = Math.max(PADDING.left, Math.min(x - tooltipWidth / 2, dimensions.width - PADDING.right - tooltipWidth));
            const tooltipY = y - tooltipHeight - 10;

            // Draw semi-transparent black tooltip
            ctx.globalAlpha = 0.5;
            ctx.fillStyle = 'black';
            ctx.fillRect(tooltipX, tooltipY, tooltipWidth, tooltipHeight);
            ctx.globalAlpha = 1;

            // Draw text in tooltip
            ctx.fillStyle = 'white';
            ctx.font = '12px Arial';
            ctx.textAlign = 'center';
            ctx.fillText(`Temp: ${temperature}°C`, tooltipX + tooltipWidth / 2, tooltipY + 15);
            ctx.fillText(`Humidity: ${humidity}%`, tooltipX + tooltipWidth / 2, tooltipY + 30);
            ctx.restore();
        }

        // Drawing x-axis labels
        ctx.save();
        ctx.textAlign = 'right';
        ctx.textBaseline = 'top';
        ctx.font = '12px Arial';
        ctx.fillStyle = '#666';
        const labelInterval = Math.max(1, Math.ceil(zoomedData.length / 6));
        zoomedData.forEach((point, i) => {
            if (i % labelInterval === 0) {
                const x = PADDING.left + i * xScale;
                ctx.save();
                ctx.translate(x, dimensions.height - PADDING.bottom + 10);
                ctx.rotate(-Math.PI / 4);
                ctx.fillText(point.formatted_created_at, 0, 0);
                ctx.restore();
            }
        });
        ctx.restore();

        // Y-axis tick marks and labels
        for (let i = 0; i <= TICK_COUNT; i++) {
            const y = PADDING.top + (dimensions.height - PADDING.top - PADDING.bottom) * (1 - i / TICK_COUNT);

            ctx.beginPath();
            ctx.strokeStyle = '#eee';
            ctx.moveTo(PADDING.left, y);
            ctx.lineTo(dimensions.width - PADDING.right, y);
            ctx.stroke();

            const tempValue = Math.round(tempMin + ((tempMax - tempMin) * i / TICK_COUNT));
            ctx.fillStyle = '#ef4444';
            ctx.textAlign = 'right';
            ctx.fillText(tempValue, PADDING.left - 10, y);

            const humidityValue = Math.round(humidityMin + ((humidityMax - humidityMin) * i / TICK_COUNT));
            ctx.fillStyle = '#3b82f6';
            ctx.textAlign = 'left';
            ctx.fillText(humidityValue, dimensions.width - PADDING.right + 10, y);
        }

        // Legend
        ctx.textAlign = 'left';
        ctx.textBaseline = 'middle';
        ctx.beginPath();
        ctx.strokeStyle = '#ef4444';
        ctx.lineWidth = 2;
        ctx.moveTo(PADDING.left, 20);
        ctx.lineTo(PADDING.left + 30, 20);
        ctx.stroke();
        ctx.fillStyle = '#ef4444';
        ctx.fillText('Temperature', PADDING.left + 40, 20);

        ctx.beginPath();
        ctx.strokeStyle = '#3b82f6';
        ctx.lineWidth = 2;
        ctx.moveTo(PADDING.left + 150, 20);
        ctx.lineTo(PADDING.left + 180, 20);
        ctx.stroke();
        ctx.fillStyle = '#3b82f6';
        ctx.fillText('Humidity', PADDING.left + 190, 20);
    }, [processedData, dimensions, zoomDomain, hoveredPoint, mousePosition]);

    const handleZoom = (e) => {
        if (processedData.length === 0) return;
        const rect = canvasRef.current.getBoundingClientRect();
        const mouseX = e.clientX - rect.left;
        const mousePositionRatio = (mouseX - PADDING.left) /
            (dimensions.width - PADDING.left - PADDING.right);
        const range = zoomDomain[1] - zoomDomain[0];
        let newRange = e.deltaY > 0 ? range * ZOOM_FACTOR : range / ZOOM_FACTOR;
        if (newRange > processedData.length) newRange = processedData.length;

        const midPoint = zoomDomain[0] + range * mousePositionRatio;
        const newStart = Math.max(0, Math.floor(midPoint - (newRange * mousePositionRatio)));
        const newEnd = Math.min(processedData.length - 1, Math.ceil(midPoint + (newRange * (1 - mousePositionRatio))));

        setZoomDomain([newStart, newEnd]);
    };

    const handleMouseDown = (event) => {
        const { offsetX } = event.nativeEvent;
        setIsDragging(true);
        setDragStartX(offsetX);
    };

    const handleMouseMove = (event) => {
        if (!isDragging) return;

        const { offsetX } = event.nativeEvent;
        const dx = offsetX - dragStartX; // Calculate how far the mouse has moved

        // Adjust the zoomDomain based on mouse movement
        setZoomDomain((prevDomain) => {
            const newStart = prevDomain[0] + dx / (dimensions.width / (zoomDomain[1] - zoomDomain[0])); // Adjust in forward direction
            const newEnd = prevDomain[1] + dx / (dimensions.width / (zoomDomain[1] - zoomDomain[0]));   // Adjust in forward direction
            return [Math.max(0, newStart), Math.min(processedData.length - 1, newEnd)];
        });

        setDragStartX(offsetX); // Update the starting position
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const formatDateRange = () => {
        if (processedData.length === 0) return '';
        const startIndex = Math.floor(zoomDomain[0]);
        const endIndex = Math.ceil(zoomDomain[1]);
        return `${processedData[startIndex]?.formatted_created_at || ''} - 
              ${processedData[endIndex]?.formatted_created_at || ''}`;
    };

    return (
        <RemoveScroll enabled={allowScroll}>
            <div
                ref={containerRef}
                className="relative h-[300px]"
                onWheel={handleZoom}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onMouseMove={handleMouseMove}
                style={{ userSelect: 'none', touchAction: 'none' }}
            >
                <canvas
                    ref={canvasRef}
                    className="absolute inset-0"
                    style={{ width: '100%', height: '100%' }}
                />
            </div>
        </RemoveScroll>
    );
};

export default DashboardLineChart;
