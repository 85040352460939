import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { IoCloseCircle } from "react-icons/io5";
import { getUserDropDownApi } from "../../../services/apis/device";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
  },
};

Modal.setAppElement("#root");

const ServiceRequestCommentFormModal = ({ isOpen, onRequestClose, contentLabel, onSubmit }) => {
  const [assignedUser, setAssignedUser] = useState("");
  const [time, setTime] = useState("");
  const [status, setStatus] = useState("Pending");
  const [comment, setComment] = useState("");
  const [userOptions, setUserOptions] = useState({});


  useEffect(() => {
    const fetchData = async () => {
      setAssignedUser("");
      setTime("");
      setStatus("Pending");
      setComment("");
      setUserOptions("");
      try {
        const respones = await getUserDropDownApi();
        if (respones?.status === 200) {
          setUserOptions(respones?.data);
        } else {
          let errors = "Opps! Something went wrong, please try again.";
        }
      } catch (error) {
        let errors =
          error?.response?.data?.detail ||
          "Opps! Something went wrong, please try again.";
      }
    };
    fetchData();
  }, []);


  const transformUserData = () => {
    const options = [];
    if (userOptions && userOptions) {
      for (let i = 0; i < userOptions.length; i++) {
        const item = userOptions[i];
        options.push({
          value: item.id,
          label: item.name,
        });
      }
    }
    return options;
  };

  const handleUserChange = (e) => {
    setAssignedUser(e.target.value);
  };

  const handleTimeChange = (e) => {
    setTime(e.target.value);
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(assignedUser, time, status, comment);
    setAssignedUser("");
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel={contentLabel}
    >
      <div className="flex flex-row justify-between">
        <h2 className="text-xl font-semibold mb-4">Add New Comment </h2>
        <IoCloseCircle color="red" size={25} onClick={onRequestClose} cursor="pointer" />
      </div>
      <form onSubmit={handleSubmit}>
        <div className="w-full mb-1">
          <div className="grid gap-6 mb-1 md:grid-cols-2">
            <div className="flex flex-col">
              <label htmlFor="user-assigned" className="font-medium">
                User Assigned
                <span className="text-red-600 font-bold">*</span>
              </label>
              <select name="user_assigned" id="user_assigned" className="border p-2 mb-4 w-full mt-2" onChange={handleUserChange}>
                <option value="">
                  Select
                </option>
                {transformUserData().map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            {/* <div className="flex flex-col">
              <label htmlFor="date-time" className="font-medium">
                Time
                <span className="text-red-600 font-bold">*</span>
              </label>
              <input type="datetime-local" name="date_time" id="date_time" className="border p-2 mb-4 w-full" onChange={handleTimeChange} />
            </div> */}
            <div className="flex flex-col">
              <label htmlFor="status" className="font-medium">
                Status
              </label>
              <select name="status" id="status" className="border p-2 mb-4 w-full mt-2" onChange={handleStatusChange}>
                <option value="Pending">
                  Pending
                </option>
                <option value="In-Progress">
                  In Progress
                </option>
                <option value="Completed">
                  Competed
                </option>
                <option value="Rejected">
                  Rejected
                </option>
              </select>
            </div>
          </div>
          <div className="flex flex-col">
            <label htmlFor="comment" className="font-medium">
              Comment on Service request
            </label>
            <input type="text" name="comment" placeholder="Write a comment..." id="comment" className="border p-2 mb-4 w-full" onChange={handleCommentChange} />
          </div>
        </div>
        <div className="flex justify-end gap-3">
          <button
            type="button"
            onClick={onRequestClose}
            className="text-gray-500 hover:text-gray-700"
          >
            Close
          </button>
          <button
            type="submit"
            className="bg-primary text-white px-4 py-2 rounded"
          >
            Submit
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ServiceRequestCommentFormModal;
