import React from "react";
import { AiFillHome } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import { MdStar, MdStarHalf, MdStarBorder } from "react-icons/md";
import { useLocation } from "react-router-dom";

import naturalFace from "../../../assets/neutral-face.png";
import poutingFace from "../../../assets/pouting-face.png";
import slightlySmilingFace from "../../../assets/slightly-smiling-face.png";
import heartFace from "../../../assets/smiling-face-with-heart-eyes.png";

const FeedBackDetails = () => {
  const location = useLocation();
  const { rowData } = location.state;

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

    return (
      <div className="flex items-center">
        {Array(fullStars)
          .fill(null)
          .map((_, index) => (
            <MdStar key={`full-${index}`} className="w-5 h-5 text-yellow-500" />
          ))}
        {halfStar && <MdStarHalf className="w-5 h-5 text-yellow-500" />}
        {Array(emptyStars)
          .fill(null)
          .map((_, index) => (
            <MdStarBorder
              key={`empty-${index}`}
              className="w-5 h-5 text-yellow-500"
            />
          ))}
      </div>
    );
  };

  const renderExperienceEmoji = (experienceNumber) => {
    switch (experienceNumber) {
      case 1:
        return <img src={poutingFace} alt="Pouting Face" className="w-6 h-6" />;
      case 2:
        return <img src={naturalFace} alt="Natural Face" className="w-6 h-6" />;
      case 3:
        return (
          <img
            src={slightlySmilingFace}
            alt="Slightly Smiling Face"
            className="w-6 h-6"
          />
        );
      case 4:
        return <img src={heartFace} alt="Heart Face" className="w-6 h-6" />;
      default:
        return "-";
    }
  };

  return (
    <div className="pl-5">
      {/* Top Section Begin */}
      <div className="flex gap-3 items-center font-medium text-gray-500">
        <AiFillHome />
        <p>Home</p>
        <IoIosArrowForward />
        <p>Feedbacks & Reviews</p>
        <IoIosArrowForward />
        <p>Feedbacks & Reviews Details</p>
      </div>
      {/* Top Section End */}
      {/* Form Begin */}
      <div className="mt-8">
        <div className="border border-gray-300 mt-5 p-4 rounded-lg bg-gray-100">
          <div className="flex flex-col gap-1">
            <p className="text-black font-medium text-lg">Review Description</p>
            <div className="border border-gray-300 bg-white rounded-md p-3">
              {rowData?.description || "No description available"}
            </div>
          </div>
          <div className="flex flex-col gap-1 mt-8">
            <p className="text-black font-medium text-lg">Experience</p>
            <div className="border border-gray-300 bg-white rounded-md p-3">
              {rowData?.experience_number
                ? renderExperienceEmoji(rowData.experience_number)
                : "No experience available"}
            </div>
          </div>
          <div className="flex flex-col gap-1 mt-8">
            <p className="text-black font-medium text-lg">Ratings</p>
            <div className="border border-gray-300 bg-white rounded-md p-3 flex">
              {rowData?.star_rating
                ? renderStars(rowData.star_rating)
                : "No rating available"}
            </div>
          </div>
        </div>
      </div>
      {/* Form End */}
    </div>
  );
};

export default FeedBackDetails;
