import React from "react";
import { MdStar, MdStarHalf, MdStarBorder } from "react-icons/md";

const StarRating = ({ rating }) => {
  const fullStars = Math.floor(rating);
  const halfStar = rating % 1 !== 0;
  const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

  return (
    <div className="flex items-center">
      {Array(fullStars)
        .fill(null)
        .map((_, index) => (
          <MdStar key={`full-${index}`} className="w-5 h-5 text-yellow-500" />
        ))}
      {halfStar && <MdStarHalf className="w-5 h-5 text-yellow-500" />}
      {Array(emptyStars)
        .fill(null)
        .map((_, index) => (
          <MdStarBorder
            key={`empty-${index}`}
            className="w-5 h-5 text-yellow-500"
          />
        ))}
    </div>
  );
};

export default StarRating;
