import React from "react";
import { useField } from "formik";

const TextareaField = ({ disable, required, label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className="form-control flex flex-col gap-2 p-2">
      <label htmlFor={props.id || props.name} className="font-medium">
        {label}
        {required && <span className="text-red-600 font-bold">*</span>}
      </label>
      <textarea
        className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        {...field}
        {...props}
        autoComplete="off"
        disabled={disable}
        rows={5}
      />
      {required && meta.touched && meta.error ? (
        <div className="text-red-600">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default TextareaField;
