import React from "react";
import { FaUserCircle } from "react-icons/fa";
import naturalFace from "../../assets/neutral-face.png";
import poutingFace from "../../assets/pouting-face.png";
import slightlySmilingFace from "../../assets/slightly-smiling-face.png";
import heartFace from "../../assets/smiling-face-with-heart-eyes.png";
import StarRating from "../ratings/StarRating";

const Table = ({ headers, keys, rows, extraColumn, extraColumnRenderer, zIndex = 1 }) => {
  const displayHeaders = headers.filter((header) => header !== "");
  const displayKeys = keys.filter((header) => header !== "");

  // Function to format dates
  function formatDate(dateTimeStr) {
    const dateTime = new Date(dateTimeStr);
    const year = dateTime.getFullYear();
    const month = String(dateTime.getMonth() + 1).padStart(2, '0');
    const day = String(dateTime.getDate()).padStart(2, '0');
    const hours = String(dateTime.getHours()).padStart(2, '0');
    const minutes = String(dateTime.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }

  return (
    <div
      className="relative overflow-x-auto border border-gray-400 rounded-lg overflow-y-hidden"
      style={{ boxShadow: "0px 0px 10px 0px lightGray", maxWidth: "100%", maxHeight: "400px" }} // Adjust maxHeight for vertical scrolling
    >
      {/* Horizontal and vertical scroll for table */}
      <div style={{ overflowX: "auto", overflowY: "auto", maxHeight: "400px" }}>
        <table className="w-full text-sm text-left rtl:text-right">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr style={{
              position: "sticky",
              left: 0,
              top: 0, // Make header sticky when scrolling vertically
              zIndex: zIndex,
              background: "white",
              whiteSpace: "nowrap",
            }}>
              {/* S.No column - sticky */}
              <th
                scope="col"
                className="px-6 py-3 border-b border-gray-400"
                style={{
                  position: "sticky",
                  left: 0,
                  top: 0, // Make header sticky when scrolling vertically
                  zIndex: 1,
                  background: "white",
                  whiteSpace: "nowrap",
                }}
              >
                S.No.
              </th>
              {displayHeaders.map((header, index) => (
                <th
                  key={index}
                  scope="col"
                  className="px-6 py-3 border-b border-gray-400"
                  style={{
                    whiteSpace: "nowrap", // Prevent text wrapping in header
                    position: header.toLowerCase().includes("date") ? "sticky" : "static", // Sticky if "Date" is in the header
                    left: header.toLowerCase().includes("date") ? `${60 + index * 100}px` : "auto", // Adjust 'left' value dynamically for multiple date columns
                    top: 0, // Sticky for vertical scrolling
                    zIndex: header.toLowerCase().includes("date") ? 0 : 0, // Ensure proper layering
                    background: "white", // Ensure background remains white for sticky headers
                  }}
                >
                  {header}
                </th>
              ))}
              {extraColumn && (
                <th
                  key="extra-column"
                  scope="col"
                  className="px-6 py-3 border-b border-gray-400"
                  style={{
                    whiteSpace: "nowrap", // Prevent text wrapping in extra column header
                    top: 0, // Sticky for vertical scrolling
                    position: "sticky",
                    zIndex: 0, // Ensure proper layering
                    background: "white",
                  }}
                >
                  {extraColumn}
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {rows && rows.length > 0 ? (
              rows.map((row, rowIndex) => (
                <tr
                  key={rowIndex}
                  className={`${rowIndex % 2 === 0 ? "bg-white" : "bg-gray-50"
                    } border-b`}
                >
                  {/* S.No column - sticky */}
                  <td
                    className="px-6 py-4 text-gray-500"
                    style={{
                      whiteSpace: "nowrap", // Prevent text wrapping in first column
                      position: "sticky",
                      left: 0,
                      background: "white",
                      zIndex: 0,
                      top: 0, // Ensure S.No header stays on top during vertical scroll
                    }}
                  >
                    {rowIndex + 1}
                  </td>

                  {displayKeys.map((key, colIndex) => {
                    let cellValue = row[key];
                    if (key === "device") {
                      cellValue = row.device.device_name || row.device;
                    }
                    if (key === "status") {
                      cellValue = row.status ? row.status : "Pending";
                    }
                    return (
                      <td
                        key={colIndex}
                        className="px-6 py-4 text-gray-500"
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis", // Prevent text wrapping and handle overflow
                          position: key === "created_at" ? "sticky" : "static",
                          left: key === "created_at" ? 60 : "auto", // Sticky for created_at column
                          top: 0, // Sticky for vertical scrolling
                          background: key === "created_at" ? "white" : "none",
                          zIndex: key === "created_at" ? 0 : "auto",
                        }}
                      >
                        {key === "experience_number" ? (
                          // Render emoji based on value
                          cellValue === 1 ? (
                            <span role="img" aria-label="positive">
                              <img src={poutingFace} alt="poutingFace" />
                            </span>
                          ) : cellValue === 2 ? (
                            <span role="img" aria-label="neutral">
                              <img src={naturalFace} alt="naturalFace" />
                            </span>
                          ) : cellValue === 3 ? (
                            <span role="img" aria-label="negative">
                              <img
                                src={slightlySmilingFace}
                                alt="slightlySmilingFace"
                              />
                            </span>
                          ) : cellValue === 4 ? (
                            <span role="img" aria-label="negative">
                              <img src={heartFace} alt="heartFace" />
                            </span>
                          ) : (
                            "-"
                          )
                        ) : key === "star_rating" ? (
                          // Render star icons based on value
                          <StarRating rating={cellValue} />
                        ) : key === "image" ? (
                          cellValue ? (
                            <div className="w-10 h-10 rounded-full overflow-hidden">
                              <img
                                src={cellValue}
                                alt="Commodity Image"
                                className="w-full h-full object-cover rounded-full"
                              />
                            </div>
                          ) : (
                            <FaUserCircle className="w-10 h-10 text-gray-300" />
                          )
                        ) : key === "created_at" || key === "updated_at" ? (
                          formatDate(cellValue)
                        ) : (
                          cellValue || "-"
                        )}
                      </td>
                    );
                  })}
                  {extraColumn && (
                    <td
                      key={`extra-column-${rowIndex}`}
                      className="px-6 py-4 text-gray-500 cursor-pointer"
                      style={{
                        whiteSpace: "nowrap", // Prevent text wrapping in extra column
                      }}
                    >
                      {extraColumnRenderer ? extraColumnRenderer(row) : null}
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={displayHeaders.length + (extraColumn ? 2 : 1)}
                  className="text-center py-4 text-lg text-gray-500 font-medium"
                >
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
