import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { AiFillHome } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import { FaPlus } from "react-icons/fa";
import InputField from "../../../components/inputFields/InputField";
import Button from "../../../components/buttons/Button";
import Select from "../../../components/inputFields/SelectField";
import FormModal from "../../../components/popups/fromModal/FormModal";
import { useLocation, useNavigate } from "react-router-dom";
import { get_user_id } from "../../../constant/urls/localStoarege";
import { addFaqsTypeApi, editFaqsApi, getFaqsTypeApi } from "../../../services/apis/faqs";
import Toast, { showSuccessToast } from "../../../components/toastify/Toast";
import showAlert from "../../../components/errorBoundries/Errors";
import TextareaField from "../../../components/inputFields/TextField";
const EditFaqs = () => {
  const navigate = useNavigate();
  const userId = get_user_id();
  const location = useLocation();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [faqsTypeData, setFaqsTypeData] = useState({});
  const [faqsId, setFaqsId] = useState("");

  const { rowData } = location.state;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const respones = await getFaqsTypeApi();
        if (respones?.status === 200) {
          setFaqsTypeData(respones?.data);
        } else {
          let errors = "Opps! Something went wrong, please try again.";
          showAlert("Error", errors, "error");
        }
      } catch (error) {
        let errors =
          error?.response?.data?.detail ||
          "Opps! Something went wrong, please try again.";
        showAlert("Error", errors, "error");
      }
    };
    fetchData();
  }, []);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const payload = {
        question: values.question,
        answer: values.answer,
        faq_type: values?.faq_type_id,
        links: values?.add_link,
        created_by: userId,
      };
      const response = await editFaqsApi(payload, rowData?.id);

      if (response?.status === 200) {
        showSuccessToast("FAQs edited successfully!");

        navigate("/faqs");

        if (typeof resetForm === "function") {
          resetForm();
        }
      } else {
        let errors = "Opps! Something went wrong, please try again.";
        showAlert("Error", errors, "error");
      }
    } catch (error) {
      let errors =
        error?.response?.data?.error ||
        "Opps! Something went wrong, please try again.";
      showAlert("Error", errors, "error");
    }
    setSubmitting(false);
  };

  const handleDiscardChanges = (resetForm) => {
    navigate("/faqs");
  };

  const handlePopupSubmit = async (inputValue) => {
    setIsOpen(false);
    try {
      const payload = {
        title: inputValue,
        created_by: userId,
      };

      const response = await addFaqsTypeApi(payload);

      if (response?.status === 201) {
        showSuccessToast("FAQs Type added successfully!");
        try {
          const respones = await getFaqsTypeApi();
          if (respones?.status === 200) {
            setFaqsTypeData(respones?.data);
          } else {
            let errors = "Opps! Something went wrong, please try again.";
            showAlert("Error", errors, "error");
          }
        } catch (error) {
          let errors =
            error?.response?.data?.detail ||
            "Opps! Something went wrong, please try again.";
          showAlert("Error", errors, "error");
        }
      } else {
        let errors = "Opps! Something went wrong, please try again.";
        showAlert("Error", errors, "error");
      }
    } catch (error) {
      let errors =
        error?.response?.data?.error ||
        "Opps! Something went wrong, please try again.";
      showAlert("Error", errors, "error");
    }
  };

  const transformData = () => {
    const options = [];
    if (faqsTypeData && faqsTypeData.results) {
      for (let i = 0; i < faqsTypeData.results.length; i++) {
        const item = faqsTypeData.results[i];
        options.push({
          value: item.id,
          label: item.title,
        });
      }
    }
    return options;
  };

  const optionsArray = transformData();
  useEffect(() => {
    const selectedOption = optionsArray.find(
      (items) => items.label === rowData?.faq_type
    );
    if (selectedOption) {
      setFaqsId(selectedOption.value);
    }
  }, [optionsArray]);
  return (
    <div className="relative">
      <Toast />
      {/* Top Section Begin */}
      <div className="flex gap-3 items-center font-medium text-gray-500">
        <AiFillHome />
        <p>Home</p>
        <IoIosArrowForward />
        <p>FAQs</p>
        <IoIosArrowForward />
        <p>Edit FAQs</p>
      </div>
      {/* Top Section End */}
      {/* Form Begin */}
      <div className="mt-8">
        <Formik
          initialValues={{
            faq_type: rowData?.faq_type || "",
            question: rowData?.question || "",
            add_link: rowData?.links || "",
            answer: rowData?.answer || "",
          }}
          validationSchema={Yup.object({
            question: Yup.string().required("Please enter question"),
            faq_type: Yup.string().required("Please select FAQs type"),
            // add_link: Yup.string().required("Please Enter link "),
            answer: Yup.string().required("Please Enter answer "),
          })}
          onSubmit={handleFormSubmit}
        >
          {({ isSubmitting, resetForm }) => (
            <Form>
              <div className="border border-gray-300 mt-5 p-4 rounded-lg bg-gray-100">
                <div className="w-full mb-1 flex gap-3 items-center">
                  <Select
                    label="FAQ Type"
                    name="faq_type"
                    options={transformData()}
                    rowData={rowData}
                    faqsId={faqsId}
                    required
                  />
                  <button
                    type="button"
                    onClick={openModal}
                    className="bg-primary text-white cursor-pointer w-[50px] h-[40px] mt-1 flex justify-center items-center rounded-lg"
                  >
                    <FaPlus />
                  </button>
                </div>
                <div className="w-full mb-1">
                  <div className="grid gap-6 mb-1 md:grid-cols-2">
                    <InputField
                      label="Question"
                      name="question"
                      type="text"
                      placeholder="Enter Question"
                      required
                    />
                    <InputField
                      label="Add Link"
                      name="add_link"
                      type="text"
                      placeholder="Enter or Paste link..."
                      false
                    />
                  </div>
                </div>

                <div className="w-full mb-1">
                  <TextareaField
                    label="Answer"
                    name="answer"
                    placeholder="Enter Answer"
                    required
                  />
                </div>
              </div>
              <div className="py-5 flex justify-end gap-6">
                <div>
                  <Button
                    type="button"
                    className="text-primary bg-white hover:bg-white-dark font-sm rounded-lg text-lg w-full px-8 py-1.5 text-center border border-primary"
                    onClick={() => handleDiscardChanges(resetForm)}
                    disabled={isSubmitting}
                  >
                    Discard Changes
                  </Button>
                </div>
                <div>
                  <Button
                    type="submit"
                    className="text-white bg-primary hover:bg-primary-dark font-sm rounded-lg text-lg w-full px-8 py-1.5 text-center"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {/* Form End */}
      {/* Popup Modal */}
      <FormModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Add FAQ Type Modal"
        onSubmit={handlePopupSubmit}
      />
    </div>
  );
};

export default EditFaqs;
