import React from 'react';
import { Timeline } from 'rsuite';
import './TimelineComponent.css'; // Import CSS file for styling

function TimelineComponent({ data = [] }) {
    return (
        <div className="timeline-container">
            <Timeline>
                {data.map((item, index) => (
                    <Timeline.Item key={index}>
                        <div className="timeline-item">
                            <div className="tick-mark">
                                <div className="tick-circle">
                                    <span className="tick-icon">&#10003;</span>
                                </div>
                            </div>
                            <div className="timeline-content">
                                <div className="date-time flex flex-row">
                                    <p className='text-primary font-bold mr-2'>{item.status}</p>
                                    <p className='text-gray-500'>{formatDateTime(item.created_at)}</p>
                                </div>
                                <div className="comment text-gray-500">
                                    {item.comment}
                                </div>
                                <div className="assigned-user flex flex-row">
                                    <p className='font-medium mr-1'>
                                        Assigned User:
                                    </p>
                                    <p>{item.assigned_user.name}</p>
                                </div>
                                <div className="commented-by flex flex-row">
                                    <p className='font-medium mr-1'>
                                        Commented By:
                                    </p>
                                    <p>{item.created_by.name}</p>
                                </div>
                            </div>
                        </div>
                    </Timeline.Item>
                ))}
            </Timeline>
        </div>
    );
}

// Helper function to format date and time
function formatDateTime(dateTimeStr) {
    const dateTime = new Date(dateTimeStr);
    const date = dateTime.toLocaleDateString();
    const time = dateTime.toLocaleTimeString();
    return `${date} | ${time}`;
}

export default TimelineComponent;
