import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoMdAdd } from "react-icons/io";
import { AiFillHome } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import { HiRefresh } from "react-icons/hi";

import SearchBar from "../../../components/searchBar/SearchBar";
import Table from "../../../components/tables/Table";
import Pagination from "../../../components/pagination/Pagination";
import { Link } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import ConfirmModal from "../../../components/popups/confirmationModal/ConfirmModal";
import { handleRefresh } from "../../../utils/handle_refresh";
import { getCommodityApi, deleteCommodityApi, editCommodityApi } from "../../../services/apis/commodity";
import showAlert from "../../../components/errorBoundries/Errors";
import { showSuccessToast } from "../../../components/toastify/Toast";

const Commodity = () => {
  const navigate = useNavigate();
  const headers = [
    "Last Updated",
    "Image",
    "Commodity",
    "Min. Temp.",
    "Max. Temp.",
    "Min. Hum.",
    "Max. Hum.",
    // "Device",
    // "State",
    // "Phone",
  ];
  const keys = [
    "updated_at",
    "image",
    "commodity_name",
    "min_temp",
    "max_temp",
    "min_hum",
    "max_hum",
    // "device",
    // "state",
    // "phone_no",
  ];

  const [commodity, setCommodityData] = useState({});
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);
  const [commodityId, setCommodityId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const fetchData = async (query) => {
    try {
      const response = await getCommodityApi("?search=" + query);
      if (response?.status === 200) {
        setCommodityData(response?.data);
      } else {
        let errors = "Oops! Something went wrong, please try again.";
        showAlert("Error", errors, "error");
      }
    } catch (error) {
      let errors =
        error?.response?.data?.detail ||
        "Oops! Something went wrong, please try again.";
      showAlert("Error", errors, "error");
    }
  };
  useEffect(() => {
    fetchData("");
  }, []);

  const openModal = (row) => {
    setIsOpen(true);
    setCommodityId(row?.id);
    setIsConfirmDelete(false);
  };

  const closeModal = () => {
    setIsOpen(false);
    setIsConfirmDelete(false);
  };

  useEffect(() => {
    if (isConfirmDelete === true) {
      const fetchData = async () => {
        try {
          const respones = await deleteCommodityApi(commodityId);
          if (respones?.status === 204) {
            try {
              const respones = await getCommodityApi();
              if (respones?.status === 200) {
                setCommodityData(respones?.data);
              } else {
                let errors = "Opps! Something went wrong, please try again.";
                showAlert("Error", errors, "error");
              }
            } catch (error) {
              let errors =
                error?.response?.data?.detail ||
                "Opps! Something went wrong, please try again.";
              showAlert("Error", errors, "error");
            }
            setIsOpen(false);
            showSuccessToast("Commodity deleted successfully!");
          } else {
            let errors = "Opps! Something went wrong, please try again.";
            showAlert("Error", errors, "error");
          }
        } catch (error) {
          let errors =
            error?.response?.data?.detail ||
            "Opps! Something went wrong, please try again.";
          showAlert("Error", errors, "error");
        }
      };
      fetchData();
    }
  }, [isConfirmDelete]);

  const handleEdit = (rowData) => {
    navigate(`/commodity/edit-commodity`, { state: { rowData } });
  };

  const extraColumnRenderer = (row) => (
    <div className="flex space-x-2">
      <FiEdit
        size={23}
        onClick={() => handleEdit(row)}
        className="cursor-pointer text-primary"
      />
      <MdDelete
        size={25}
        onClick={() => openModal(row)}
        className="cursor-pointer text-primary"
      />
    </div>
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleSearch = (query) => {
    fetchData(query);
  };

  return (
    <div className="">
      {/* Top Section Begin */}
      <div className="flex gap-3 items-center font-medium text-gray-500 xl:pl-4  pl:0 ">
        <AiFillHome />
        <p>Home</p>
        <IoIosArrowForward />
        <p>Commodity</p>
      </div>
      {/* Top Section End */}
      {/* Add Commodity Begin */}
      <div className="flex justify-between py-3">
        <div className="w-[30%]">
          <SearchBar
            placeholder="Search in Commodity..."
            onSearch={handleSearch}
          />
        </div>
        <div className="flex gap-6">
          <div>
            <button
              type="button"
              className="text-[#374151] bg-[#F3F4F6] hover:gray-[300] font-sm rounded-lg text-lg w-full px-5 py-2.5 text-center flex items-center gap-1 border border-gray-200  font-semibold"
              onClick={handleRefresh}
            >
              <HiRefresh style={{ color: "#374151" }} size={23} />
              Refresh
            </button>
          </div>
          <div>
            <Link to="/commodity/add-commodity">
              <button
                type="button"
                className="text-white bg-primary hover:bg-primary-dark font-sm rounded-lg text-lg w-full px-5 py-2.5 text-center flex items-center gap-1"
              >
                <IoMdAdd style={{ color: "white" }} size={23} />
                Add Commodity
              </button>
            </Link>
          </div>
        </div>
      </div>
      {/* Add Commodity End */}
      {/* Card Section Begin */}
      {/* <div className="py-3 2xl:pl-5 xl:pl-0 sm:pl-0">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
          {cardData.map((card, index) => (
            <Card title={card.title} count={card.count} icon={card.icon} />
          ))}
        </div>
      </div> */}
      {/* Card Section End */}
      {/* Table Begin */}
      <div className="px-0 xl:pl-5 md:pr-0 mt-8">
        <Table
          headers={headers}
          keys={keys}
          rows={commodity?.results}
          extraColumn="Actions"
          extraColumnRenderer={extraColumnRenderer}
        />
      </div>
      {/* Table End */}
      {/* Pagination Begin */}
      <div className="xl:pl-5 pl-0">
        <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil(commodity?.count / pageSize)}
          pageSize={pageSize}
          totalEntries={commodity?.count}
          onPageChange={handlePageChange}
          data={commodity}
          setData={setCommodityData}
        />
      </div>
      {/* Pagination End */}
      <ConfirmModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Add FAQ Type Modal"
        onClose={closeModal}
        setIsConfirmDelete={setIsConfirmDelete}
      />
    </div>
  );
};

export default Commodity;
