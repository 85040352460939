import React, { useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import marker from "../../assets/maker.png";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css'; // Import Leaflet's CSS
import './MapComponent.css'; // Assuming the CSS is in the same folder

const customIcon = new L.Icon({
  iconUrl: marker,
  iconSize: [45, 55],
  iconAnchor: [22, 22],
  popupAnchor: [-3, -26],
});

// Component to update the map's center when location changes
const MapUpdater = ({ newCenter, zoom }) => {
  const map = useMap(); // Access the map instance

  useEffect(() => {
    if (newCenter) {
      map.setView(newCenter, zoom); // Move the map to the new location
    }
  }, [newCenter, zoom, map]);

  return null; // This component does not render anything
};

const MapComponent = ({ positions, mapLocation = [18.5204, 73.8567] }) => {
  return (
    <div className="map-container z-0">
      <MapContainer center={mapLocation} zoom={13} style={{ height: '100%', width: '100%' }} >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          className='z-0'
        />
        {positions.map((pos, idx) => (
          <Marker key={idx} position={[pos.lat, pos.lng]} icon={customIcon}>
            <Popup>{pos.label}</Popup>
          </Marker>
        ))}

        {/* MapUpdater will move the map when newLocation is provided */}
        {mapLocation && <MapUpdater newCenter={mapLocation} zoom={13} />}
      </MapContainer>
    </div>
  );
};

export default MapComponent;
