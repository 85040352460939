import {
  getDevicessApiUrl,
  devicesCRUDUrl,
  getUserDropDownApiUrl,
  plantDropDownApiUrl,
} from "../../constant/urls/apiUrls";
import axios from "axios";
import { get_token } from "../../constant/urls/localStoarege";

export const getDevicesApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.get(`${getDevicessApiUrl.replace("http://", "https://")}${data || ""}`, config);

  return response;
};

export const getDetailDevicesApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.get(`${getDevicessApiUrl.replace("http://", "https://")}${data}`, config);

  return response;
};

export const getUsersTypeApi = async () => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.get(devicesCRUDUrl, config);

  return response;
};

export const addDeviceApi = async (data) => {
  const config = {
    headers: {
      // "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };


  const response = await axios.post(devicesCRUDUrl, data, config);

  return response;
};

export const editUsersApi = async (data, id) => {
  const config = {
    headers: {
      // "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.patch(`${devicesCRUDUrl}${id}/`, data, config);

  return response;
};

export const deleteDeviceApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.delete(`${devicesCRUDUrl}${data}/`, config);

  return response;
};

export const getUserDropDownApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.get(getUserDropDownApiUrl, config);

  return response;
};

export const getPlantDropDownApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.get(plantDropDownApiUrl, config);

  return response;
};
