import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { AiFillHome } from "react-icons/ai";
import { IoIosArrowForward, IoMdAdd } from "react-icons/io";
import InputField from "../../../components/inputFields/InputField";
import Button from "../../../components/buttons/Button";
import { get_user_id } from "../../../constant/urls/localStoarege";
import Toast, { showSuccessToast } from "../../../components/toastify/Toast";
import { useNavigate } from "react-router-dom";
import showAlert from "../../../components/errorBoundries/Errors";
import { addMembersApi } from "../../../services/apis/member";
import TextareaField from "../../../components/inputFields/TextField";
import '../../../constant/css/custom_checkbox.css';

function AddUser() {
    const navigate = useNavigate();
    const userId = get_user_id();
    const [error, setError] = useState();

    const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
        if (values.password === values.rPassword) {
            try {
                const payload = {
                    name: values.name,
                    email: values.email,
                    phone: values.phone,
                    address: values.address,
                    country: values.address,
                    state: values.address,
                    city: values.address,
                    pincode: values.pincode,
                    is_active: values.isActive,
                    username: values.username,
                    password: values.password,
                    is_mobile_user: true,
                    created_by: userId,
                };

                const response = await addMembersApi(payload);

                if (response?.status === 201) {
                    showSuccessToast("User created successfully!");
                    navigate("/users");
                    if (typeof resetForm === "function") {
                        resetForm();
                    }
                } else {
                    let errors = "Opps! Something went wrong, please try again.";
                    showAlert("Error", errors, "error");
                }
            } catch (error) {
                setError(
                    error?.response?.data?.error ||
                    "Opps! Something went wrong, please try again."
                );
                let errors =
                    error?.response?.data?.error ||
                    "Opps! Something went wrong, please try again.";
                showAlert("Error", errors, "error");
            }
        }
        setSubmitting(false);
    };

    const handleDiscardChanges = (resetForm) => {
        navigate("/users");
    };

    return (
        <div className="pl-5 ">
            {/* Top Section Begin */}
            <Toast />
            <div className="flex gap-3 items-center font-medium text-gray-500">
                <AiFillHome />
                <p>Home</p>
                <IoIosArrowForward />
                <p>Users</p>
                <IoIosArrowForward />
                <p>Add User</p>
            </div>
            {/* Top Section End */}
            {/* Form Begin */}
            <div className="mt-8">
                <Formik
                    validationSchema={Yup.object({
                        name: Yup.string().required("Please enter Name"),
                        phone: Yup.string().required("Please enter Phone Number"),
                        country: Yup.string().required("Please enter Country"),
                        username: Yup.string().required("Username is requierd"),
                        password: Yup.string().required("Password Required"),
                        rPassword: Yup.string().required("Please Repeat Password"),
                    })}
                    initialValues={{
                        name: '',
                        username: '',
                        email: '',
                        phone: '',
                        address: '',
                        country: '',
                        state: '',
                        city: '',
                        pincode: '',
                        password: '',
                        rPassword: '',
                        isActive: true,
                    }}
                    onSubmit={handleFormSubmit}
                >
                    {({ isSubmitting, resetForm }) => (
                        <Form>
                            <div className="border border-gray-300 mt-5 p-4 rounded-lg bg-gray-100">
                                <div className="w-full mb-1">
                                    <InputField
                                        label="Name"
                                        name="name"
                                        type="text"
                                        placeholder="Enter Name"
                                        required
                                    />
                                </div>
                                <div className="w-full mb-1">
                                    <TextareaField
                                        label="Address"
                                        name="address"
                                        placeholder="Enter Address"
                                        false
                                    />
                                </div>
                                <div className="w-full mb-1">
                                    <div className="grid gap-6 mb-1 md:grid-cols-3">
                                        <InputField
                                            label="Phone No."
                                            name="phone"
                                            type="number"
                                            placeholder="XXXXXXXXXX"
                                            required
                                        />
                                        <InputField
                                            label="Email"
                                            name="email"
                                            type="email"
                                            placeholder="Enter your Email"
                                            false
                                        />
                                        <InputField
                                            label="Username"
                                            name="username"
                                            placeholder="Username"
                                            required={true}
                                        />
                                    </div>
                                </div>
                                <div className="w-full mb-1">
                                    <div className="grid gap-6 mb-1 md:grid-cols-2">
                                        <InputField
                                            label="Password"
                                            name="password"
                                            type="password"
                                            placeholder="**********"
                                            required
                                        />
                                        <InputField
                                            label="Repeat Password"
                                            name="rPassword"
                                            type="password"
                                            placeholder="**********"
                                            required
                                        />

                                    </div>
                                </div>
                                <div className="w-full mb-1">
                                    <div className="grid gap-6 mb-1 md:grid-cols-2">
                                        <InputField
                                            label="Country"
                                            name="country"
                                            type="name"
                                            placeholder="India"
                                            required
                                        />
                                        <InputField
                                            label="State"
                                            name="state"
                                            type="name"
                                            placeholder="Maharastra"
                                            false
                                        />
                                    </div>
                                </div>
                                <div className="w-full mb-1">
                                    <div className="grid gap-6 mb-1 md:grid-cols-2">
                                        <InputField
                                            label="City"
                                            name="city"
                                            type="name"
                                            placeholder="Pune"
                                            false
                                        />
                                        <InputField
                                            label="Pincode"
                                            name="pincode"
                                            type="number"
                                            placeholder="XXXXXX"
                                            false
                                        />
                                    </div>
                                </div>
                                <div className="w-full mb-6 flex items-center pl-3 custom-checkbox">
                                    <Field type="checkbox" name="isActive" className="mr-2 h-16" />
                                    <div className="flex flex-col">
                                        <label htmlFor="isActive" className="text-base text-black">Enabled User</label>
                                        <p className="text-sm text-gray-700">Select for Enable User</p>
                                    </div>
                                </div>
                            </div>
                            <div className="py-5 flex justify-end gap-6">
                                <div>
                                    <Button
                                        type="button"
                                        className="text-primary bg-white hover:bg-white-dark font-sm rounded-lg text-lg w-full px-8 py-1.5 text-center border border-primary"
                                        onClick={() => handleDiscardChanges(resetForm)}
                                        disabled={isSubmitting}
                                    >
                                        Discard Changes
                                    </Button>
                                </div>
                                <div>
                                    <Button
                                        type="submit"
                                        className="text-white bg-primary hover:bg-primary-dark font-sm rounded-lg text-lg w-full px-8 py-1.5 text-center"
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? "Submitting..." : "Submit"}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            {/* Form End */}
        </div >
    );
}

export default AddUser;