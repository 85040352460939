import { AiFillHome } from "react-icons/ai";
import { Formik, Form } from "formik";
import { IoIosArrowForward, IoMdAdd, IoMdEye } from "react-icons/io";
import { Link, Navigate } from "react-router-dom";
import Tab from "../../marketing/faqs/Tabs";
import Pagination from "../../../components/pagination/Pagination";
import { useEffect, useState } from "react";
import Table from "../../../components/tables/Table";
import { deleteUsersApi, getManualReportsApi } from "../../../services/apis/manual_report";
import showAlert from "../../../components/errorBoundries/Errors";
import { MdDelete } from "react-icons/md";
import ConfirmModal from "../../../components/popups/confirmationModal/ConfirmModal";
import Toast, { showSuccessToast } from "../../../components/toastify/Toast";
import Select from "../../../components/inputFields/SelectField";
import { getDeviceDropDownApi } from "../../../services/apis/commodity";
import Button from "../../../components/buttons/Button";

function ManualReport() {
    const tabsList = [
        {
            id: "All", name: "All",
        },
        {
            id: "Installation Report", name: "Installation Report",
        },
        {
            id: "Survey Report", name: "Survey Report",
        },
        {
            id: "Site Visit Report", name: "Site Visit Report",
        },
        {
            id: "Monitoring Report", name: "Monitoring Report",
        },
    ];

    const headers = [
        "Report Types",
        "Device",
        "Uploaded by",
        "Uploaded on",
    ];
    const keys = [
        "report_type",
        "device_serial",
        "surveyed_by",
        "created_at",
    ]
    const [reportData, setReportData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [appliedFilter, setAppliedFilter] = useState("All");
    const [modalIsOpen, setIsOpen] = useState(false);
    const [faqsId, setFaqsId] = useState("");
    const [isConfirmDelete, setIsConfirmDelete] = useState(false);
    const [deviceOptions, setDeviceOptions] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState("");


    const pageSize = 10;

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleSearch = (query) => {
        console.log("Search query:", query);
    };

    const handleDownload = (url) => {
        window.open(url, "_blank");
    };


    const transformData = () => {
        const options = [];
        if (deviceOptions && deviceOptions) {
            for (let i = 0; i < deviceOptions.length; i++) {
                const item = deviceOptions[i];
                options.push({
                    value: item.id,
                    label: item.device_name,
                });
            }
        }
        return options;
    };

    useEffect(() => {
        fetchData("All");
    }, []);

    useEffect(() => {
        const fData = async () => {
            try {
                const respones = await getDeviceDropDownApi();
                if (respones?.status === 200) {
                    setDeviceOptions(respones?.data);
                } else {
                    let errors = "Opps! Something went wrong, please try again.";
                    showAlert("Error", errors, "error");
                }
            } catch (error) {
                let errors =
                    error?.response?.data?.detail ||
                    "Opps! Something went wrong, please try again.";
                showAlert("Error", errors, "error");
            }
        };
        fData();
    }, []);

    const fetchData = async (tab) => {
        if (tab === "All") {
            setDeviceOptions("");
        }
        try {
            const respones = await getManualReportsApi(tab !== "All" ? tab : "");
            if (respones?.status === 200) {
                setReportData(respones?.data);
            } else {
                let errors = "Opps! Something went wrong, please try again.";
                showAlert("Error", errors, "error");
            }
        } catch (error) {
            let errors =
                error?.response?.data?.detail ||
                "Opps! Something went wrong, please try again.";
            showAlert("Error", errors, "error");
        }
    };


    const handleApplyFilter = (query) => {
        setAppliedFilter(query);
        fetchData(query);
    };

    // const handleViewDetails = (rowData) => {
    //     Navigate(`/manual/${rowData.id}`, { state: { rowData } });
    // };
    const extraColumnRenderer = (row) => (
        <div className="flex space-x-2">
            {/* <IoMdEye
                size={25}
                onClick={() => handleViewDetails(row)}
                className="cursor-pointer text-#E1EFFE"
            /> */}
            <div onClick={() => handleDownload(row?.file)}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 6C0 2.68629 2.68629 0 6 0H14C17.3137 0 20 2.68629 20 6V14C20 17.3137 17.3137 20 14 20H6C2.68629 20 0 17.3137 0 14V6Z" fill="#DEF7EC" />
                    <path d="M9.99967 12.333V5.33301V12.333Z" fill="#046C4E" />
                    <path d="M5.33301 12.333V12.9163C5.33301 13.3805 5.51738 13.8256 5.84557 14.1538C6.17376 14.482 6.61888 14.6663 7.08301 14.6663H12.9163C13.3805 14.6663 13.8256 14.482 14.1538 14.1538C14.482 13.8256 14.6663 13.3805 14.6663 12.9163V12.333M12.333 9.99967L9.99967 12.333M9.99967 12.333L7.66634 9.99967M9.99967 12.333V5.33301" stroke="#1A8044" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </div>
            <MdDelete
                size={20}
                onClick={() => openModal(row)}
                className="cursor-pointer text-red-600"
            />
        </div>
    );


    const closeModal = () => {
        setIsOpen(false);
        setIsConfirmDelete(false);
    };

    const openModal = (row) => {
        setIsOpen(true);
        setFaqsId(row?.id);
    };

    useEffect(() => {

        if (isConfirmDelete === true) {
            const fetchData2 = async () => {
                try {
                    const respones = await deleteUsersApi(faqsId);
                    if (respones?.status === 204) {
                        fetchData("");
                        setIsOpen(false);
                        showSuccessToast("Report deleted successfully!");
                    } else {
                        let errors = "Opps! Something went wrong, please try again.";
                        showAlert("Error", errors, "error");
                    }
                } catch (error) {
                    let errors =
                        error?.response?.data?.detail ||
                        "Opps! Something went wrong, please try again.";
                    showAlert("Error", errors, "error");
                }
            };
            fetchData2();
        }
    }, [isConfirmDelete]);

    const handleFormSubmit = async (values) => {
        setSelectedDevice(values.device);
        fetchData(`&device=${values.device}`);
    };

    return (<>
        <div className="flex gap-3 items-center font-medium text-gray-500">
            <Toast />
            <AiFillHome />
            <p>Home</p>
            <IoIosArrowForward />
            <p>Reports</p>
            <IoIosArrowForward />
            <p>Manual Report</p>
        </div>
        <div className="flex flex-row justify-between">
            <Formik
                enableReinitialize={true}
                initialValues={{
                    device: selectedDevice,
                }}
                onSubmit={handleFormSubmit}
            >
                {({ resetForm }) => (
                    <Form className="flex flex-row items-center">
                        <div className="w-52">
                            <Select
                                label=""
                                name="device"
                                options={transformData()}
                                false
                                needSearch={true}
                            />
                        </div>
                        <div>
                            <Button
                                type="submit"
                                className="text-white bg-primary hover:bg-primary-dark font-sm rounded-lg text-md w-full px-8 text-center"
                            >
                                {"Apply"}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
            <div className="w-[30%] my-4">
                {/* <SearchBar placeholder="Search in Reports..." onSearch={handleSearch} /> */}
            </div>
            <div className="flex">
                <Link to="/manual/add">
                    <button
                        type="button"
                        className="text-white bg-primary hover:bg-primary-dark font-sm rounded-lg text-md w-full px-3 py-2.5 text-center flex items-center gap-1"
                    >
                        <IoMdAdd style={{ color: "white" }} size={23} />
                        Add New Report
                    </button>
                </Link>
            </div>
        </div>
        <div className="lg:pl-2">
            <Tab tabsList={tabsList} onClick={handleApplyFilter} activeTab={appliedFilter} />
        </div>
        {/* Table Begin */}
        <Table
            headers={headers}
            keys={keys}
            rows={reportData?.results}
            extraColumn={"Actions"}
            extraColumnRenderer={extraColumnRenderer}
            zIndex={0}
        />
        {/* Table End */}
        {/* Pagination Begin */}
        <div className="xl:pl-5 pl-0">
            <Pagination
                currentPage={currentPage}
                totalPages={Math.ceil(reportData?.count / pageSize)}
                pageSize={pageSize}
                totalEntries={reportData?.count}
                onPageChange={handlePageChange}
                data={reportData}
                setData={setReportData}
            />
        </div>
        {/* Pagination End */}
        <ConfirmModal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Add Device Type Modal"
            onClose={closeModal}
            setIsConfirmDelete={setIsConfirmDelete}
        />
    </>);
}

export default ManualReport;