import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { AiFillHome } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import { PiUploadFill } from "react-icons/pi";
import InputField from "../../../components/inputFields/InputField";
import Button from "../../../components/buttons/Button";
// import Select from "../../../components/inputFields/SelectField";
import showAlert from "../../../components/errorBoundries/Errors";
import { editCommodityApi, getDeviceDropDownApi } from "../../../services/apis/commodity";
import { useLocation, useNavigate } from "react-router-dom";
import { get_user_id } from "../../../constant/urls/localStoarege";
import { showSuccessToast } from "../../../components/toastify/Toast";

const EditCommodity = () => {
  const navigate = useNavigate();
  const userId = get_user_id();
  const location = useLocation();
  const { rowData } = location.state;
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState("");
  // const [deviceOptions, setDeviceOptions] = useState({});

  useEffect(() => {
    if (rowData?.image) {
      setSelectedImage(`${rowData?.image}`);
      setSelectedFile(rowData?.image);
    }
  }, [rowData]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const respones = await getDeviceDropDownApi();
  //       if (respones?.status === 200) {
  // setDeviceOptions(respones?.data);
  //       } else {
  //         let errors = "Opps! Something went wrong, please try again.";
  //         showAlert("Error", errors, "error");
  //       }
  //     } catch (error) {
  //       let errors =
  //         error?.response?.data?.detail ||
  //         "Opps! Something went wrong, please try again.";
  //       showAlert("Error", errors, "error");
  //     }
  //   };
  //   fetchData();
  // }, []);

  // const transformData = () => {
  //   const options = [];
  //   if (deviceOptions && deviceOptions) {
  //     for (let i = 0; i < deviceOptions.length; i++) {
  //       const item = deviceOptions[i];
  //       options.push({
  //         value: item.id,
  //         label: item.device_name,
  //       });
  //     }
  //   }
  //   return options;
  // };


  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleProfileClick = () => {
    document.getElementById("fileInput").click();
  };

  const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const formData = new FormData();
      if (typeof (selectedFile) !== "string") {
        formData.append("image", selectedFile);
      }
      // formData.append("device", values.device);
      formData.append("commodity_name", values.commodity);
      formData.append("min_temp", values.minTemp);
      formData.append("max_temp", values.maxTemp);
      formData.append("min_hum", values.minHum);
      formData.append("max_hum", values.maxHum);
      formData.append("created_by", userId);

      const response = await editCommodityApi(formData, rowData?.id);

      if (response.status === 200) {
        showSuccessToast("Commodity Edited successfully!");
        navigate("/commodity");

        resetForm();
      } else {
        showAlert(
          "Error",
          "Opps! Something went wrong, please try again.",
          "error"
        );
      }
    } catch (error) {
      showAlert(
        "Error",
        "Opps! Something went wrong, please try again.",
        "error"
      );
    }
    setSubmitting(false);
  };

  const handleDiscardChanges = (resetForm) => {
    resetForm();
  };

  return (
    <div className="relative">
      {/* Top Section Begin */}
      <div className="flex gap-3 items-center font-medium text-gray-500">
        <AiFillHome />
        <p>Home</p>
        <IoIosArrowForward />
        <p>Community</p>
        <IoIosArrowForward />
        <p>Edit Commodity</p>
      </div>
      {/* Top Section End */}
      {/* Form Begin */}
      <div className="mt-8">
        <Formik
          initialValues={{
            commodity: rowData?.commodity_name || "",
            quantity: rowData?.quantity || "",
            duration: rowData?.duration || "",
            // device: rowData?.device.id || "",
            minTemp: rowData?.min_temp || "",
            maxTemp: rowData?.max_temp || "",
            minHum: rowData?.min_hum || "",
            maxHum: rowData?.max_hum || "",
          }}
          validationSchema={Yup.object({
            commodity: Yup.string().required("Please select commodity"),
            // quantity: Yup.string().required("Please enter quantity"),
            // duration: Yup.string().required("Please enter duration "),
            // device: Yup.string().required("Please select device"),
            // image: Yup.mixed().required("Please upload an image file"),
          })}
          onSubmit={handleFormSubmit}
        >
          {({ isSubmitting, resetForm }) => (
            <Form>
              <div className="border border-gray-300 mt-5 p-4 rounded-lg bg-gray-100">
                <div className="w-[200px]">
                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />
                  <div
                    onClick={handleProfileClick}
                    style={{ cursor: "pointer" }}
                  >
                    {selectedImage ? (
                      <div className="w-[200px] h-[200px] rounded-lg">
                        <img
                          src={selectedImage}
                          alt="Profile"
                          className="w-full h-full object-cover rounded-lg"
                        />
                      </div>
                    ) : (
                      <div className="border-dashed border-2 border-gray-300 rounded-lg w-[200px] h-[200px] flex flex-col justify-center items-center bg-[#ffffff]">
                        <PiUploadFill size={35} className="text-gray-400" />
                        <p className="text-gray-400 font-medium ">
                          Click to upload Image
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="w-full mb-1">
                  <InputField
                    label="Commodity"
                    name="commodity"
                    type="text"
                    placeholder="Enter Qunatity"
                    required
                  />
                </div>
                {/* <div className="w-full flex gap-3 items-center">
                  <Select
                    label="Device"
                    name="device"
                    options={transformData()}
                    required
                  />
                </div> */}
                <div className="grid gap-6 mb-6 md:grid-cols-2">
                  <InputField
                    label="Min. Temperature"
                    name="minTemp"
                    type="number"
                    placeholder="Min Temperature (in C)"
                    required
                  />
                  <InputField
                    label="Max. Temperature"
                    name="maxTemp"
                    type="number"
                    placeholder="Min Temperature (in C)"
                    required
                  />
                  <InputField
                    label="Min. Humidity"
                    name="minHum"
                    type="number"
                    placeholder="Min Humidity (in %)"
                    required
                  />
                  <InputField
                    label="Max. Humidity"
                    name="maxHum"
                    type="number"
                    placeholder="Max Humidity (in %)"
                    required
                  />
                </div>
              </div>
              <div className="py-5 flex justify-end gap-6">
                <div>
                  <Button
                    type="button"
                    className="text-primary bg-white hover:bg-white-dark font-sm rounded-lg text-lg w-full px-8 py-1.5 text-center border border-primary"
                    onClick={() => handleDiscardChanges(resetForm)}
                    disabled={isSubmitting}
                  >
                    Discard Changes
                  </Button>
                </div>
                <div>
                  <Button
                    type="submit"
                    className="text-white bg-primary hover:bg-primary-dark font-sm rounded-lg text-lg w-full px-8 py-1.5 text-center"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {/* Form End */}
    </div>
  );
};

export default EditCommodity;
