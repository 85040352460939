import React, { useState } from 'react';

const ToggleSwitch = ({ initialState = false, onToggle }) => {
    const [isToggled, setIsToggled] = useState(initialState);

    const handleToggle = () => {
        const newToggleState = !isToggled;
        setIsToggled(newToggleState);
        if (onToggle) {
            onToggle(newToggleState);
        }
    };

    return (
        <div
            className={`w-14 h-8 flex items-center bg-gray-300 rounded-full p-1 cursor-pointer ${isToggled ? 'bg-green-500' : 'bg-gray-300'
                }`}
            onClick={handleToggle}
        >
            <div
                className={`bg-white w-6 h-6 rounded-full shadow-md transform transition-transform duration-300 ease-in-out ${isToggled ? 'translate-x-6' : 'translate-x-0'
                    }`}
            ></div>
        </div>
    );
};

export default ToggleSwitch;
