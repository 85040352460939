import { loginApiUrl } from "../../constant/urls/apiUrls";
import axios from "axios";

export const loginApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.post(loginApiUrl, data, config);

  return response;
};
