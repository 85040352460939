import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { AiFillHome } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import InputField from "../../../components/inputFields/InputField";
import Button from "../../../components/buttons/Button";
import Toast, { showSuccessToast } from "../../../components/toastify/Toast";
import showAlert from "../../../components/errorBoundries/Errors";
import { get_user_id } from "../../../constant/urls/localStoarege";
import { useNavigate } from "react-router-dom";
import { getDeviceDropDownApi } from "../../../services/apis/commodity";
import Select from "../../../components/inputFields/SelectField";
import { addFirmwareApi } from "../../../services/apis/firmware";


const AddFirmware = () => {
  const navigate = useNavigate();
  const userId = get_user_id();
  const [deviceOptions, setDeviceOptions] = useState({});

  const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const payload = {
        device: values?.device,
        firmware: values?.firmware,
        created_by: userId,
      };

      const response = await addFirmwareApi(payload);

      if (response?.status === 201) {
        showSuccessToast("Firmware created successfully!");

        navigate("/firmware");

        if (typeof resetForm === "function") {
          resetForm();
        }
      } else {
        let errors = "Opps! Something went wrong, please try again.";
        showAlert("Error", errors, "error");
      }
    } catch (error) {
      let errors =
        error?.response?.data?.error ||
        "Opps! Something went wrong, please try again.";
      showAlert("Error", errors, "error");
    }
    setSubmitting(false);
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const respones = await getDeviceDropDownApi();
        if (respones?.status === 200) {
          setDeviceOptions(respones?.data);
        } else {
          let errors = "Opps! Something went wrong, please try again.";
          showAlert("Error", errors, "error");
        }
      } catch (error) {
        let errors =
          error?.response?.data?.detail ||
          "Opps! Something went wrong, please try again.";
        showAlert("Error", errors, "error");
      }
    };
    fetchData();
  }, []);

  const transformData = () => {
    const options = [];
    if (deviceOptions && deviceOptions) {
      for (let i = 0; i < deviceOptions.length; i++) {
        const item = deviceOptions[i];
        options.push({
          value: item.id,
          label: item.device_name,
        });
      }
    }
    return options;
  };

  const handleDiscardChanges = (resetForm) => {
    navigate("/firmware");
  };

  const getCurrentDate = () => {
    const date = new Date();
    return date.toISOString().split("T")[0]; // Formats the date as YYYY-MM-DD
  };

  const getCurrentTime = () => {
    const date = new Date();
    return date.toTimeString().split(" ")[0].slice(0, 5); // Formats the time as HH:MM
  };

  return (
    <div className="pl-5 ">
      <Toast />
      {/* Top Section Begin */}
      <div className="flex gap-3 items-center font-medium text-gray-500">
        <AiFillHome />
        <p>Home</p>
        <IoIosArrowForward />
        <p>Firmware</p>
        <IoIosArrowForward />
        <p>Add Firmware</p>
      </div>
      {/* Top Section End */}
      {/* Form Begin */}
      <div className="mt-8">
        <Formik
          initialValues={{
            device: "",
            firmware: "",
          }}
          validationSchema={Yup.object({
            device: Yup.string().required("Please select a device"),
            firmware: Yup.string().required("Please enter Firmware version"),
          })}
          onSubmit={handleFormSubmit}
        >
          {({ isSubmitting, resetForm }) => (
            <Form>
              <div className="border border-gray-300 mt-5 p-4 rounded-lg bg-gray-100">
                <div className="w-full mb-1">
                  <Select
                    label="Device"
                    name="device"
                    options={transformData()}
                    required
                    needSearch={true}
                  />
                  <InputField
                    label="Firmware Version"
                    name="firmware"
                    type="text"
                    placeholder="12.13.XX"
                    required
                  />
                </div>
              </div>
              <div className="py-5 flex justify-end gap-6">
                <div>
                  <Button
                    type="button"
                    className="text-primary bg-white hover:bg-white-dark font-sm rounded-lg text-lg w-full px-8 py-1.5 text-center border border-primary"
                    onClick={() => handleDiscardChanges(resetForm)}
                    disabled={isSubmitting}
                  >
                    Discard Changes
                  </Button>
                </div>
                <div>
                  <Button
                    type="submit"
                    className="text-white bg-primary hover:bg-primary-dark font-sm rounded-lg text-lg w-full px-8 py-1.5 text-center"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {/* Form End */}
    </div>
  );
};

export default AddFirmware;
