import swal from "sweetalert";

const showAlert = (title, text, icon) => {
  if (typeof title !== "string") {
    title = "Error";
  }
  if (typeof text !== "string") {
    text = "An unexpected error occurred";
  }

  swal({
    title: title,
    text: text,
    icon: icon,
    button: "OK",
  });
};

export default showAlert;
