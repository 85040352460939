import {
  getUserDropDownApiUrl,
  manualCRUDApiUrl,
  manualReportGetApiUrl,
} from "../../constant/urls/apiUrls";
import axios from "axios";
import { get_token } from "../../constant/urls/localStoarege";

export const getManualReportsApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.get(`${manualReportGetApiUrl.replace("http://", "https://")}${data}`, config);

  return response;
};

export const getUsersTypeApi = async () => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.get(manualCRUDApiUrl, config);

  return response;
};

export const addManualReportApi = async (data) => {
  const config = {
    headers: {
      // "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.post(manualCRUDApiUrl, data, config);

  return response;
};

export const editUsersApi = async (data, id) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.patch(`${manualCRUDApiUrl}${id}/`, data, config);

  return response;
};

export const deleteUsersApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.delete(`${manualCRUDApiUrl}${data}/`, config);

  return response;
};


export const getUserDropDownApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.get(getUserDropDownApiUrl.replace("http://", "https://"), config);

  return response;
};
