import React, { useState } from 'react';
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

// CustomBar component to render bars with rounded corners
const CustomBar = ({ x, y, width, height, fill }) => {
    const radius = 6;

    return (
        <g>
            <rect
                x={x}
                y={y}
                width={width}
                height={height}
                fill={fill}
                rx={radius}
                ry={radius}
            />
        </g>
    );
};

// Main component to render the bar chart
function DashboardBarGraph({ data }) {
    const [hoverIndex, setHoverIndex] = useState(null);

    const handleMouseEnter = (index) => {
        setHoverIndex(index);
    };

    const handleMouseLeave = () => {
        setHoverIndex(null);
    };

    return (
        <ResponsiveContainer width="100%" height={300}>
            <BarChart
                data={data}
                margin={{
                    top: 20, right: 30, left: 20, bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="1 1" />
                <XAxis dataKey="state" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar
                    dataKey="count"
                    fill="#1A8044"
                    shape={(props) => (
                        <CustomBar
                            {...props}
                            fill={'#1A8044'}
                        />
                    )}
                />
            </BarChart>
        </ResponsiveContainer>
    );
}

export default DashboardBarGraph;
