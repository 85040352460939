import React, { useState } from "react";
import { FaSearch } from "react-icons/fa"; // Import the search icon

const SearchBar = ({ placeholder, onSearch }) => {
  const [query, setQuery] = useState("");

  const handleInputChange = (e) => {
    onSearch(e.target.value);
    setQuery(e.target.value);
  };

  return (
    <div className="w-full max-w-md">
      <div className="relative">
      <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
      <input
        type="text"
        value={query}
        onChange={handleInputChange}
        placeholder={placeholder}
        className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
      />
      </div>
    </div>
  );
};

export default SearchBar;
