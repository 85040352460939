import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import InputField from "../../components/inputFields/InputField";
import logo from "../../assets/logo.png";
import Button from "../../components/buttons/Button";
import loginImage from "../../assets/loginImg.png";
import { loginApi } from "../../services/apis/auth";
import showAlert from "../../components/errorBoundries/Errors";

const Signin = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [hidePassword, setHidePassword] = useState("");
  const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const payload = {
        email: values.username,
        password: values.password,
      };
      const response = await loginApi(payload);

      const responseData = response?.data;
      if (response?.status === 200) {
        localStorage?.setItem("auth_token", responseData?.token);
        localStorage?.setItem("name", responseData?.name);
        localStorage?.setItem("user_name", responseData?.username);
        localStorage?.setItem("user_id", responseData?.user_id);
        localStorage?.setItem("role", responseData?.role.toLowerCase());
        if (responseData?.is_dasboard_user === true) {
          navigate("/dashboard");
        } else {
          showAlert("Error", "Unauthorized User.", "error");
        }
      } else {
        let errors = "Opps! Something went wrong, please try again.";
        showAlert("Error", errors, "error");
      }
      resetForm();
    } catch (error) {
      setError(
        error?.response?.data?.error ||
        "Opps! Something went wrong, please try again."
      );
      let errors =
        error?.response?.data?.error ||
        "Opps! Something went wrong, please try again.";
      showAlert("Error", errors, "error");
    }
    setSubmitting(false);
  };

  const togglePassword = () => {
    setHidePassword(!hidePassword);
  };

  useEffect(() => {
    const token = localStorage?.getItem("auth_token") || "";
    if (token.length > 1) {
      window.location = "/dashboard";
    }
  }, []);

  return (
    <div className="min-h-screen flex bg-white">
      <div className="w-full md:w-1/2 flex flex-col items-center justify-center p-8 relative">
        <div className="w-full max-w-lg">
          <img src={logo} alt="Logo" className="mb-8 w-32 mx-auto" />
          <div className="">
            <h2 className="font-medium text-4xl pl-2 pb-4">Welcome back!</h2>
          </div>
          <Formik
            initialValues={{
              username: "",
              password: "",
              rememberMe: false,
            }}
            validationSchema={Yup.object({
              username: Yup.string().required("Please enter your Email"),
              password: Yup.string().required("Please enter your password"),
            })}
            onSubmit={handleFormSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="w-full mb-6">
                  <InputField
                    label="Email"
                    name="username"
                    type="text"
                    placeholder="Enter your username"
                    required
                  />
                </div>
                <div className="w-full mb-6">
                  <InputField
                    label="Password"
                    name="password"
                    type={hidePassword ? "password" : "text"}
                    placeholder="Enter your password"
                    required
                    isPassword={true}
                    showPassword={!hidePassword}
                    onPasswordToggle={togglePassword}
                  />
                </div>
                <div className="w-full mb-6 flex items-center pl-3">
                  <Field type="checkbox" name="rememberMe" className="mr-2" />
                  <label htmlFor="rememberMe" className="text-sm text-gray-700">
                    Remember me
                  </label>
                </div>
                <div className="">
                  <Button
                    type="submit"
                    className="text-white bg-primary hover:bg-primary-dark font-sm rounded-lg text-lg w-full px-5 py-2.5 text-center"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting..." : "Sign in"}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div
        className="hidden md:block md:w-1/2 relative"
        style={{ height: "100vh" }}
      >
        <img
          src={loginImage}
          alt="Side Image"
          className="object-cover w-full h-full"
        />
        <div className="absolute inset-0 flex flex-col items-center justify-center text-center px-6 md:px-12 bg-black bg-opacity-50">
          <h2 className="text-3xl md:text-4xl font-bold text-white mb-4">
            Best solar powered cold storage in India
          </h2>
          <p className="text-lg text-white">
            Eco Saras is a proud partner to India's industrial growth and
            technology development since 2018. With our local partners and
            customers, we have pioneered industrial solutions of the future that
            make a difference to millions of lives every day.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Signin;
