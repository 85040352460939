import axios from "axios";
import { get_token } from "../../constant/urls/localStoarege";
import { performanceCRUDApiUrl, performanceReportApiUrl } from "../../constant/urls/apiUrls";

export const getPerformanceReportApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.get(performanceReportApiUrl.replace("http://", "https://"), config);

  return response;
};


export const deleteUsersApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.delete(`${performanceCRUDApiUrl}${data}/`, config);

  return response;
};
