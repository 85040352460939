import {
  getMembersApiUrl,
  memberCRUDUrl,
} from "../../constant/urls/apiUrls";
import axios from "axios";
import { get_token } from "../../constant/urls/localStoarege";

export const getMembersApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.get(`${getMembersApiUrl.replace("http://", "https://")}${data ?? ""}`, config);

  return response;
};

export const getMembersTypeApi = async () => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.get(memberCRUDUrl, config);

  return response;
};

export const addMembersApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.post(memberCRUDUrl, data, config);

  return response;
};

export const editMembersApi = async (data, id) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.patch(`${memberCRUDUrl}${id}/`, data, config);

  return response;
};

export const deleteMembersApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.delete(`${memberCRUDUrl}${data}/`, config);

  return response;
};
