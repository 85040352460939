import {
  changeDeviceStatusUrl,
  getPlantsApiUrl,
  getUserDropDownApiUrl,
  plantsCRUDUrl,
} from "../../constant/urls/apiUrls";
import axios from "axios";
import { get_token } from "../../constant/urls/localStoarege";

export const getPlantsApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.get(`${getPlantsApiUrl.replace("http://", "https://")}${data || ""}`, config);

  return response;
};

export const getPlantsDetailApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.get(`${getPlantsApiUrl.replace("http://", "https://")}${data}`, config);

  return response;
};

export const getUsersTypeApi = async () => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.get(plantsCRUDUrl, config);

  return response;
};

export const addPlantApi = async (data) => {
  const config = {
    headers: {
      // "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.post(plantsCRUDUrl, data, config);

  return response;
};

export const toggleDeviceStatusApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.post(changeDeviceStatusUrl, data, config);

  return response;
};

export const editUsersApi = async (data, id) => {
  const config = {
    headers: {
      // "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.patch(`${plantsCRUDUrl}${id}/`, data, config);

  return response;
};

export const deletePlantApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.delete(`${plantsCRUDUrl}${data}/`, config);

  return response;
};

export const getUserDropDownApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${get_token()}`,
    },
  };

  const response = await axios.get(getUserDropDownApiUrl, config);

  return response;
};
